

export const ERROR_CODES = {
  VALIDATION_ERROR: "RB100",
  EMPTY_TEXT: "RB494",
  MESSAGE_SEQ_EXCEED_AVAILABLE_RANGE: "RB009",
  MESSAGE_SEQ_EXCEED_ALLOWED_RANGE: "RB049",
  DOCUMENT_TOO_BIG: "RB027",
  DOCUMENT_EMPTY: "RB026",
  NOT_IMAGE: "RB059",
  CORRUPTED_IMAGE: "RB181",
  MIMETYPE_MISMATCH: "RB071",
  REJECTED_IMAGE_MIMETYPE: "RB176",
  REJECTED_MIMETYPE: "RB034",
  REFUSED_BY_ANTIVIRUS: "RB094",
  REJECTED_EXTENSION: "RB164",
  PASSWORD_PROTECTED: "RB574",
  CHAT_RESTRICTED: "RB076",
  MESSAGE_HAS_NO_DOCUMENT: "RB079",
  PARENT_API_KEY_INACTIVE: "RB089",
  IMAGE_TOO_BIG: "RB177",
  IMAGE_DIMENSIONS_TOO_BIG: "RB172",
  MESSAGE_DELETED: "RB778",
  MEETING_EXPIRED: "RB984",
  NOT_MEETING_MESSAGE: "RB990",

  INACTIVE_USER: "RF024",
  CHAT_MEMBER_NOT_ACTIVE: "RF032",
  MESSAGE_SEQ_NOT_ACCESSIBLE_TO_USER: "RF033",
  CHAT_MEMBER_NOT_VISIBLE: "RF050",
  CHAT_INACTIVE: "RF323",
  CHAT_MISSING: "RF436",
  MEETINGS_DISABLED_FOR_ORG: "RF475",
  NO_COMMON_CHAT: "RF555",
  SDK_DISABLED_FOR_ORG: "RF888",
  INACTIVE_ENTITY: "RF900",
  FROZEN_ENTITY: "RF902",
  CHAT_NOT_VISIBLE: "RF998",
  USER_IS_NOT_CONTACT: "RF007",
  EUXU_IS_SELF: "RB090",
  EUXU_NOT_FOUND: "RN006",
  DUPLICATE_EUXU: "RB050",
  TOO_MANY_PARTICIPANTS: "RB392",
  TOO_MANY_BOTS: "RB391",

  USER_HAS_NO_AVATAR: "RN013",

  INVALID_SDK_TOKEN: "RU008",
};


export const ERROR_CODES_IF_USER_CANNOT_ACCESS = [
  ERROR_CODES.INACTIVE_USER,
  ERROR_CODES.INVALID_SDK_TOKEN,
]

export const ERROR_CODES_ORG_CANNOT_USE_SDK = [
  ERROR_CODES.INACTIVE_ENTITY,
  ERROR_CODES.FROZEN_ENTITY,
  ERROR_CODES.PARENT_API_KEY_INACTIVE,
  ERROR_CODES.SDK_DISABLED_FOR_ORG,
]


export const ERROR_CODES_IF_NO_AUTH = [
  // These error codes will result in user being redirected back to login page
  ...ERROR_CODES_IF_USER_CANNOT_ACCESS, // if token expires or user deactivated
  ...ERROR_CODES_ORG_CANNOT_USE_SDK, // org loses ability to use SDK, or deactivated
];
