export const outEvents = {
  // internal
  APP_LOADED: '__loaded',

  // public
  PATH_CHANGE: 'path-change',
  AUTH_EXPIRED: 'auth-expired',
  APP_ERROR_MESSAGE: 'app-error',
  APP_CLICKED_ON_CONTACT: 'click-on-contact',
  DOWNLOAD_REQUEST: 'download-request',
  MEETING_JOIN: 'meeting-join',
  BOOK_MEETING: 'book-meeting',
};

export const requestEvents = {
  LOGIN: '__login',
}

export const inEvents = {
  COMMAND: '__command'
};

export const commands = {
  NAVIGATE: 'navigate',
};


