import { Reducer } from "react";

export type UploadPayloadType = 'file' | 'image' | 'animation';

export const uploaderDialogDefaultState = {
  caption: '',
  captionTrimmed: '',

  sendAs: 'file' as UploadPayloadType,
  isImage: false,
  isGif: false,
  compressed: undefined as (Blob | File | undefined),
  compressedFilename: '',

  originalDimensions: '',
  newDimensions: '',

  loading: false,
  processing: false,
  processingFailed: false,
  error: '',
}


export type UploaderDialogState = typeof uploaderDialogDefaultState;
export type UploaderDialogReducer = Reducer<UploaderDialogState, UploaderAction>;

enum UploaderActionType {
  RESET = "RESET",
  UPDATE = "UPDATE"
}

type UploaderAction = {
  type: UploaderActionType,
  data?: Partial<UploaderDialogState>
}

export function uploaderDialogReducer(prevState: UploaderDialogState, action: UploaderAction): UploaderDialogState {
  switch (action.type) {
    case UploaderActionType.UPDATE:
      return {...prevState, ...action.data};
    case UploaderActionType.RESET:
      return {...uploaderDialogDefaultState, ...(action.data ? action.data : {})};
    default:
      console.error('Unknown chat state action', action);
      return prevState;  // no change
  }
}

type ResetAction = {
  type: UploaderActionType.RESET,
  data?: Partial<UploaderDialogState>
}

type UpdateAction = {
  type: UploaderActionType.UPDATE,
  data: Partial<UploaderDialogState>
}


export function createUploaderUpdateAction(data: Partial<UploaderDialogState>): UpdateAction {
  return { type: UploaderActionType.UPDATE, data };
}

export function createUploaderResetAction(data?: Partial<UploaderDialogState>): ResetAction {
  return { type: UploaderActionType.RESET, data};
}
