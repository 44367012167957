import { API } from "./api";
import { getAuthProps } from "./api/auth";
import { MessageDTO } from "./api/dto";
import { APIGetChatDocumentDownloadLinkResponse, ErrorResponse } from "./api/types";
import { emitAppErrorMessage, emitDownloadRequest } from "./controller";
import { SDK_OPTION_DEFAULTS, SdkOptions } from "./sdkOptions";

export function isEmbedded() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function getScope(url = window.location) {
  // treat hash like search params and attempt to extract scope param
  const hashParams = new URLSearchParams(url.hash.substring(1));
  return hashParams.get('scope');
}

export function getSdkOptions(): SdkOptions {
  return getAuthProps()?.options || SDK_OPTION_DEFAULTS;
}

export function getFileExtension(filename: string) {
  return filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
}

export function replaceFileExtension(filename: string, newExtension: string) {
  return filename.substring(0, filename.lastIndexOf(".")) + `.${newExtension}`;
}

export function formatFileSize(bytes: number): string {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  let n = bytes;
  let l = 0;

  while (n >= 1000) {
    n /= 1000;
    l += 1;
  }
  return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)} ${units[l]}`;
}

export function downloadBlob(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);
  const fakeLink = document.createElement("a");
  fakeLink.style.display = "none";
  fakeLink.href = url;
  fakeLink.download = filename;
  document.body.appendChild(fakeLink);

  fakeLink.click();
  window.URL.revokeObjectURL(url);
  fakeLink.remove();
}

export function emitPreauthorisedDownloadLink(message: MessageDTO) {
  const document = message.content.document;
  if (!document) {
    throw new Error('Called with non-document message');
  }

  const handleError = (message: string) => {
    console.error(message);
    emitAppErrorMessage(message);
  }

  const filename = document.filename;
  API.getChatDocumentDownloadLink(message.chat_uuid, message.seq)
    .then(response => {
      if ((response as ErrorResponse).isError) {
        const scenario = (response as ErrorResponse).errorScenario;
        switch (scenario) {
          case 'NoData':
            handleError('This message is no longer available for download');
            break;
          default:
            handleError(`Failed to download "${filename}"`);
        }
      } else {
        const {url} = (response as APIGetChatDocumentDownloadLinkResponse);
        emitDownloadRequest(filename, url);
      }
    })
    .catch(() => {
      handleError(`Error while attempting to download "${filename}"`);
    });
}

export function throttle(f: Function, delay: number) {
  let timeout: number;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(f, delay);
  }
}

export function bisectLeft(arr: number[], value: number) {
  let low = 0;
  let high = arr.length;

  while (low < high) {
    const mid = (low + high) >> 1;
    if (arr[mid] < value) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return low;
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function reverseRecord<
  T extends PropertyKey,
  U extends PropertyKey,
>(input: Record<T, U>) {
  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => [
      value,
      key,
    ]),
  ) as Record<U, T>
}
