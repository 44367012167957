import { Avatar, Box, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useCallback } from "react";
import { ChatDTO } from "../../api/dto";
import { ContentKindThatShouldNotStartWithSenderName } from "../../enums";
import typography from "../../typography";
import UserAvatar from "../UserAvatar";
import { formatMessageText, friendlyDate, getMessageIcon } from "./renderer";

interface ChatListItemProps {
  chat: ChatDTO;
  onChatSelected: (chat: ChatDTO) => void;
}

function createChatAvatar(chat: ChatDTO) {
  let user;
  // if last message was sent by someone else, always use sender avatar.
  if (!chat.last_message.author.me) {
    user = chat.last_message.author;
  } else {
    // if I am sender, select first active user that is not me
    const firstActive = chat.participants.find(p => p.active && !p.user.me);
    user = firstActive ? firstActive.user : null;
  }

  if (user) {
    return <UserAvatar
      firstName={user.first_name}
      lastName={user.last_name}
      entityUserXrefUuid={user.entity_user_xref_uuid}
    />
  } else {
    // if no other active users and I was the last active user, just show blank avatar for now
    return <Avatar/>
  }

}

function ChatListItem({chat, onChatSelected}: ChatListItemProps) {
  const message = chat.last_message;
  const user = message.author;
  const senderName = message.author.me ? 'You' : `${user.first_name} ${user.last_name}`;

  const avatar = createChatAvatar(chat);

  const messageText = formatMessageText(message);
  const MessageIcon = getMessageIcon(message);
  const timeOrDate = friendlyDate(new Date(message.created_at));

  const shouldShowSenderName = !ContentKindThatShouldNotStartWithSenderName.has(message.content.kind);

  const itemClicked = useCallback(() => {
    onChatSelected(chat);
  }, [chat, onChatSelected]);

  return (
    <ListItem disableGutters>

      <ListItemButton onClick={itemClicked}>
        <ListItemAvatar>
          {avatar}
        </ListItemAvatar>
        <ListItemText>
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <Box display={'flex'}>
              <Box component='div'
                   sx={{textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold', flexGrow: 1}}>
                <Typography noWrap fontWeight={'bold'}>
                  {chat.title}
                </Typography>
              </Box>
              <Box sx={{flexShrink: 1}}>
                <Typography
                  component="span"
                  fontSize={typography.xs}
                  color={grey[500]}
                  marginX={1}
                  sx={{textWrap: "nowrap"}}
                >
                  {timeOrDate}
                </Typography>
              </Box>
            </Box>
            <Box component='div'
                 sx={{
                   textOverflow: 'ellipsis', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical',
                   WebkitLineClamp: 2,
                 }}>
              <Typography sx={{textOverflow: 'ellipsis'}}>
                {shouldShowSenderName ? (
                  <Typography component="span" fontWeight={400} fontSize={typography.sm}>{senderName}: </Typography>
                ) : null}
                {MessageIcon ? <MessageIcon sx={{fontSize: '1.2rem', color: grey[600], verticalAlign: 'text-top', marginRight: '0.2rem'}} /> : null}
                <Typography component="span" fontWeight={200} fontSize={typography.sm}>

                  {messageText}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default ChatListItem;
