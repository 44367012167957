import DoNotDisturbOutlinedIcon from "@mui/icons-material/DoNotDisturbOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { Box, Button, CircularProgress } from "@mui/material";
import React, { useCallback, useState } from "react";
import { API } from "../../../api";
import { MeetingDTO, MessageDTO } from "../../../api/dto";
import { APIMeetingJoinResponse, ErrorResponse } from "../../../api/types";
import { emitAppErrorMessage, emitMeetingJoinUrl } from "../../../controller";
import { getSdkOptions } from "../../../utils";

type Props = {
  message: MessageDTO;
  meeting: MeetingDTO;
};

function MeetingJoinButton({ message, meeting }: Props) {
  const [loading, setLoading] = useState(false);
  const startNotAfter = new Date(meeting.start_not_after);
  const expired = new Date() > startNotAfter;

  const onJoin = useCallback(() => {
    setLoading(true);
    API.joinMeeting(message.chat_uuid, message.seq).then(async response => {
      setLoading(false);
      if ((response as ErrorResponse).isError) {
        const scenario = (response as ErrorResponse).errorScenario;
        switch (scenario) {
          case 'InvalidAuth':
            // API handler should have already triggered nav, so we do nothing
            return;
          case 'ChatNotVisible':
            console.error('This chat is no longer visible')
            emitAppErrorMessage('This chat is no longer visible');
            return;
          case 'MeetingNotJoinable':
            console.error('Meeting is no longer joinable')
            emitAppErrorMessage('Meeting is no longer joinable');
            return;
          default:
            console.error('Failed to join meeting')
            emitAppErrorMessage('Failed to join meeting');
            return;
        }
      } else {
        const { url } = response as APIMeetingJoinResponse;
        const { emitMeetingJoin } = getSdkOptions();
        if (emitMeetingJoin) {
          return emitMeetingJoinUrl(url, meeting.meeting_uuid);
        } else {
          window.open(url,`meeting-${message.chat_uuid}-${message.seq}`);
        }
      }
    });

  }, [message, meeting]);

  if (expired) {
    return (
      <Button variant="outlined" disabled>
        <DoNotDisturbOutlinedIcon/>
        <Box marginLeft={0.6}>
          Meeting ended
        </Box>
      </Button>
    );
  } else {
    return (
      <Button variant="outlined" onClick={onJoin} disabled={loading}>
        {loading ? <CircularProgress color={'inherit'} size={'1.5rem'}/> : <VideocamOutlinedIcon/>}
        <Box marginLeft={0.6}>
          Join Meeting
        </Box>
      </Button>
    );
  }
}

export default MeetingJoinButton;

