import { Box, BoxProps, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import React from "react";
import { DocumentDTO, EntityUserDTO } from "../../../api/dto";
import { formatFileSize, getFileExtension } from "../../../utils";
import ExtensionBasedIcon from "../../ExtensionBasedIcon";
import UserAvatar from "../../UserAvatar";
import BaseChatEntry from "./BaseChatEntry";
import { TextBox } from "./TextChatEntry";

type Props = {
  document: DocumentDTO;
  date: Date;
  author: EntityUserDTO;
  caption?: string;
  onDocumentClick: () => void;
}

function FileChatEntry({document, date, author, caption, onDocumentClick}: Props) {
  const avatar = (
    <UserAvatar
      firstName={author.first_name}
      lastName={author.last_name}
      entityUserXrefUuid={author.entity_user_xref_uuid}
    />
  );

  const filename = document.filename;
  const extension = getFileExtension(filename);

  const positionProps: BoxProps = author.me ? {
    flexDirection: 'row-reverse',
    marginLeft: 'auto',
  } : {
    marginRight: 'auto',
  }

  return (
    <BaseChatEntry
      avatar={avatar}
      date={date}
      position={author.me ? 'right' : 'left'}
      name={author.me ? 'You' : `${author.first_name} ${author.last_name}`}
    >
      <Box marginBottom={1} display={'flex'} {...positionProps}>
        <Box
          border={1}
          padding={1}
          width={'fit-content'}
          borderColor={grey[300]}
          borderRadius={'0.25em'}
          display={'flex'}
          flexDirection={'row'}
          sx={{cursor: 'pointer', overflow: 'hidden'}}
          onClick={onDocumentClick}
        >
          <Box
            display={'flex'}
            flexShrink={0}
            justifyContent={'center'}
            alignItems={'center'}
            marginRight={1}
          >
            <ExtensionBasedIcon extension={extension}/>
          </Box>
          <Box
            flexGrow={1}
            display={'flex'}
            flexDirection={'column'}
            sx={{overflow: 'hidden', wordBreak: 'break-word',}}
          >
            <Typography
              fontWeight={500}
              fontSize={'0.9rem'}
              sx={{
                // textWrap: "nowrap",
                // textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {filename}

            </Typography>
            <Typography
              fontWeight={400}
              fontSize={'0.75rem'}
              color={grey[500]}
              sx={{
                textWrap: "nowrap",
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {formatFileSize(document.file_size)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {caption ? (
        <TextBox text={caption} author={author}/>
      ) : null}
    </BaseChatEntry>
  );
}



export default FileChatEntry;
