import { SdkOptions } from "../sdkOptions";
import { createErrorMap, fetchAndParse } from "./common";
import { EntityUserDTO } from "./dto";
import { ERROR_CODES_IF_USER_CANNOT_ACCESS, ERROR_CODES_ORG_CANNOT_USE_SDK } from "./errors";
import { ApiAuthProps, APIPingResponse, ErrorResponse, ErrorScenario } from "./types";

// Auth props cached in-memory only
let _authProps: ApiAuthProps | null = null;

export function getAuthProps() {
  return _authProps;
}

export function clearAuthProps() {
  _authProps = null;
}

function setAuthProps(authProps: ApiAuthProps) {
  _authProps = authProps;
}

const ALLOWED_DOMAINS = [
  'qwil.io',
  'qwil.org',
  'qwil.network',
]

function isAllowedEndpointUrl(endpoint: string) {
  const { protocol, host} = new URL(endpoint);
  if (protocol !== 'https:') {
    return false;
  }

  for (let i = 0; i < ALLOWED_DOMAINS.length; i++) {
    if (host.endsWith(`.${ALLOWED_DOMAINS[i]}`)) {
      return true;
    }
  }

  return false;
}

/**
 * Try to make an API call with the given keys, then cache it for future use if it works.
 */
export async function authenticate(token: string, endpoint: string, options: SdkOptions)
  : Promise<{ errorScenario?: ErrorScenario, user?: EntityUserDTO}>
{
  if (!token) {
    console.error('Auth failed. Token not provided.');
    return { errorScenario: 'NoAuth' };
  }
  // sanitize "endpoint" so Sneaky Sue does not trick us into making API calls to arbitrary URLs.
  if (!isAllowedEndpointUrl(endpoint)) {
    console.error('Auth failed. Endpoint invalid.');
    return { errorScenario: 'InvalidEndpoint' };
  }

  // remove trailing slash if included
  const cleanedEndpoint = endpoint.replace(/\/$/, "");

  const authHeader = {
    'Authorization': `Bearer ${token}`,
  };

  return fetchAndParse({
    url: `${cleanedEndpoint}/chat-service/sdk/chats/ping`,
    method: "GET",
    headers: authHeader,
    errorMap: createErrorMap({
      InvalidAuth: ERROR_CODES_IF_USER_CANNOT_ACCESS,
      OrgCannotUseSDK: ERROR_CODES_ORG_CANNOT_USE_SDK,
    }),
    redirectIfNotAuthorised: false,
  }).then((response) => {
    if ((response as ErrorResponse).isError) {
      // if auth no longer valid, remove from storage.
      const scenario = (response as ErrorResponse).errorScenario;
      if (scenario === "InvalidAuth" || scenario === "OrgCannotUseSDK") {
        clearAuthProps();
      }
      return { errorScenario: scenario }

    } else {
      const user = (response as APIPingResponse).user;
      console.log(`Successfully logged in as ${user.first_name} ${user.last_name} (${user.entity_user_xref_uuid})`);
      setAuthProps({options, endpoint: cleanedEndpoint, authHeader});
      return { user };
    }
  });

}
