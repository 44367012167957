import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from "@mui/icons-material/Send"
import { Box, Button, Fab, IconButton, TextField, Typography } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChatDTO, EntityUserDTO, MessageDTO } from "../../api/dto";
import { useOnElementScroll, useOnElementSizeChange } from "../../hooks";
import { ThemeContext } from "../../theme";
import typography from "../../typography";
import { emitPreauthorisedDownloadLink, getSdkOptions } from "../../utils";
import ChatDetails from "./ChatDetails";
import LoadingPlaceholderChatEntry from "./ChatEntry/LoadingPlaceholderChatEntry";
import SkeletonChatEntry from "./ChatEntry/SkeletonChatEntry";
import { isPreviewableDocument } from "./FilePreview";
import { useFilePreview, useMessageDownloader } from "./hooks";
import { renderMessage } from "./renderer";
import UploadButton from "./UploadButton";
import { UploadPayload, UploadResult } from "./UploadDialog";


interface ChatProps {
  chat: ChatDTO;
  messages: MessageDTO[];
  loading: boolean;
  onLoadMoreOlder: () => void,
  onBack: () => void;
  onSend: (value: string) => Promise<boolean>;
  onUploadSend: (payload: UploadPayload) => Promise<UploadResult>;
}

function Chat({
                chat,
                messages,
                onBack,
                loading,
                onLoadMoreOlder,
                onSend,
                onUploadSend,
              }: ChatProps) {

  const {hideChatBack, theme, emitDownloads} = useMemo(() => getSdkOptions(), []);

  const hasMessages = messages && messages.length > 0;
  const hasOlder = !hasMessages || messages[0].seq > 0;
  const hasNewer = hasMessages && messages.at(-1)!.seq < chat.last_seq;

  const containerRef = useRef<HTMLElement>(null);
  const bottomBarRef = useRef<HTMLElement>(null);

  // Track last seen latest seq so we can scroll down if something new comes in
  const [prevLatestSeq, setPrevLatestSeq] = useState<number>();

  const [bottomBarOffset, setBottomBarOffset] = useState(0);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const handleDetailsOpen = useCallback(() => {
    setDetailsOpen(true);
  }, []);
  const handleDetailsClose = useCallback(() => {
    setDetailsOpen(false);
  }, []);

  const {filePreviewElement, setMessageBeingPreviewed, setDocumentPreviewOpen} = useFilePreview();
  const {messageDownloaderElement, setMessageToDownload} = useMessageDownloader();

  const doDownload = useCallback((message: MessageDTO) => {
    if (emitDownloads) {
      // will request for pre-authorised download link and emit it as event
      emitPreauthorisedDownloadLink(message);
    } else {
      // triggers download modal
      setMessageToDownload(message);
    }
  }, [emitDownloads, setMessageToDownload]);


  const onDocumentClick = useCallback((message: MessageDTO) => {
    if (!message.content.document) {
      throw new Error('This message should not have been clickable');
    }

    if (isPreviewableDocument(message.content.document)) {
      setMessageBeingPreviewed(message);
      setDocumentPreviewOpen(true);
    } else {
      doDownload(message);
    }
  }, [doDownload, setDocumentPreviewOpen, setMessageBeingPreviewed]);

  const activeParticipants: EntityUserDTO[] = []
  const inactiveParticipants: EntityUserDTO[] = []
  chat.participants.forEach(participant => {
    if (participant.active) {
      activeParticipants.push(participant.user);
    } else {
      inactiveParticipants.push(participant.user);
    }
  });

  const numActiveParticipants = activeParticipants.length;
  let subHeading = `${numActiveParticipants} participant${numActiveParticipants > 1 ? "s" : ""}`;
  if (numActiveParticipants === 0) {
    // everyone shows up as inactive if I am seeing a chat snapshot
    subHeading = "You are no longer in this chat";
    // }
  } else if (numActiveParticipants === 2) {
    // only one other participant
    const otherParticipant = activeParticipants.find(p => !p.me);
    if (otherParticipant) {
      subHeading = `With: ${otherParticipant.first_name} ${otherParticipant.last_name}`;
    }
  }

  // We render messages backwards and display with column-reverse so users start at bottom of chat
  // and it loads upwards
  const items = [];
  let last_seq;
  for (let i = messages.length - 1; i >= 0; i--) {
    let m = messages[i];

    // maybe insert placeholders for gaps
    if (last_seq !== undefined) {
      for (let i = last_seq - 1; i > m.seq; i--) {
        items.push(<SkeletonChatEntry key={`s:${chat.chat_uuid}:${i}`}/>);
      }
    }
    last_seq = m.seq;

    items.push(renderMessage(m, onDocumentClick));
  }

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
      setShowScrollButton(false);
    }
  };

  // trigger scroll if new messages come in
  useEffect(() => {
    if (!messages.length || !containerRef.current) {
      return;
    }

    const latestSeq = messages.at(-1)!.seq;
    if (!prevLatestSeq || latestSeq > prevLatestSeq) {
      scrollToBottom();
      setPrevLatestSeq(latestSeq);
    }
  }, [messages, prevLatestSeq]);


  useOnElementScroll(containerRef, () => {
    if (containerRef.current) {
      // control visibility of scroll button based on position of scroll
      setShowScrollButton(Math.abs(containerRef.current.scrollTop) > 16)

      // trigger load more if close to end of content and there is more to load
      if (hasOlder) {
        const horizonBottom = Math.abs(containerRef.current.scrollTop);
        const viewableHeight = containerRef.current.clientHeight;
        const horizonTop = horizonBottom + viewableHeight;
        const unseenAbove = containerRef.current.scrollHeight - horizonTop;
        const threshold = viewableHeight * 1.5;
        if (unseenAbove < threshold) {
          onLoadMoreOlder();
        }
      }
    }
  }, 100);

  // control offset of scroll button based on size of bottom bar. It could change based on chat
  // state and on multi-line input.
  useOnElementSizeChange(bottomBarRef, () => {
    if (bottomBarRef.current) {
      setBottomBarOffset(bottomBarRef.current.getBoundingClientRect().height);
    }
  }, {
    triggerOnInit: true,
    debounce: 50,
  });

  return (
    <ThemeContext.Provider value={theme}>
      <Box sx={{height: "100%", minWidth: '250px'}} display={'flex'} flexDirection={'column'}>
        <ChatDetails
          chat={chat}
          activeParticipants={activeParticipants}
          inactiveParticipants={inactiveParticipants}
          open={detailsOpen}
          handleClose={handleDetailsClose}
        />
        {filePreviewElement}
        {messageDownloaderElement}

        <Box display={'flex'} paddingY={1}
             sx={{borderBottom: 1, borderColor: grey[300], flexShrink: 1}}>
          <Box sx={{flexShrink: 1}} display={'flex'} alignItems={'center'} justifyContent={'center'} marginLeft={1}>
            {hideChatBack ? null : (
              <IconButton aria-label="Back to chat" size={'small'} onClick={onBack}>
                <ArrowBackIosIcon/>
              </IconButton>
            )}
          </Box>
          <Box sx={{flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden'}} display={'flex'}
               flexDirection={'column'}>
            <Box component='div' sx={{textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold'}}>
              <Typography noWrap fontWeight={'bold'}>
                {chat.title}
              </Typography>
            </Box>
            <Box lineHeight={1}>
              <Typography
                component="span"
                fontSize={typography.s}
                color={blueGrey[600]}
                margin={0}
                onClick={handleDetailsOpen}
                sx={{cursor: 'pointer'}}
              >
                {subHeading}
              </Typography>
            </Box>
          </Box>

          <Box sx={{flexShrink: 1}} display={'flex'} alignItems={'center'} justifyContent={'center'} marginRight={1}>
            <IconButton aria-label="Chat Details" size={'small'} onClick={handleDetailsOpen}>
              <MoreVertIcon/>
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{flexGrow: 1, overflowY: "auto"}}
          display='flex'
          flexDirection={'column-reverse'}
          ref={containerRef}
          id={'chat-box'}
        >
          {loading && hasNewer ? <LoadingPlaceholderChatEntry/> : null}
          {items}
          {loading && hasOlder ? <LoadingPlaceholderChatEntry/> : null}
          {!loading && hasOlder ? (
            <Box textAlign={'center'}>
              <Button onClick={onLoadMoreOlder} size={'small'}>Load more</Button>
            </Box>
          ) : null}
        </Box>
        <Box component={'div'} marginTop={1} ref={bottomBarRef}>
          <ScrollDownButton
            visible={showScrollButton}
            onClick={scrollToBottom}
            bottomOffset={bottomBarOffset}
          />
          {chat.requester_can_send ? <ChatInput loading={loading} onSend={onSend} onUploadSend={onUploadSend}/> :
            <Box height={10}/>}
          {chat.is_butler ? <ButlerNotice/> : null}
        </Box>
      </Box>
    </ThemeContext.Provider>
  );
}


interface ScrollDownButtonProps {
  visible: boolean;
  onClick: () => void;
  bottomOffset?: number;
}

function ScrollDownButton({visible, onClick, bottomOffset = 0}: ScrollDownButtonProps) {
  if (!visible) {
    return <></>;
  }

  return (
    <Fab
      color={'inherit'}
      sx={{
        position: 'absolute',
        bottom: 16 + bottomOffset,
        right: 16,
      }}
    >
      <KeyboardArrowDownIcon fontSize={'large'} onClick={onClick}/>
    </Fab>
  )
}

function ButlerNotice() {
  return (
    <Box sx={{borderTop: 1, borderColor: grey[300], flexShrink: 1, maxHeight: '30%'}}>
      <Box margin={1} alignContent={'center'}>
        <Typography fontSize={'0.85em'} color={grey[600]} textAlign={'center'}>
          <InfoOutlinedIcon sx={{
            fontSize: '0.95rem',
            color: grey[600],
            verticalAlign: 'text-top',
            marginRight: '0.2rem'
          }}/>
          The Chat Butler is a read-only channel for notifications and alerts.
        </Typography>
      </Box>
    </Box>
  );
}

interface ChatInputProps {
  loading: boolean;
  onSend: (value: string) => Promise<boolean>;
  onUploadSend: (payload: UploadPayload) => Promise<UploadResult>
}

function ChatInput({loading, onSend, onUploadSend}: ChatInputProps) {
  const [value, setValue] = useState('');
  const [trimmed, setTrimmed] = useState('');
  const [sending, setSending] = useState(false);

  const onTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setTrimmed(event.target.value.trim());
  }, []);

  const canSubmit = !loading && !sending && trimmed;
  const canUpload = !loading && !sending;
  const getCurrentText = () => value;

  const onSubmit = useCallback(async () => {
    if (!trimmed) {
      return;
    }

    setSending(true);
    const success = await onSend(trimmed);
    if (success) {
      setValue('');
      setTrimmed('');
    }
    setSending(false);
  }, [onSend, trimmed]);

  const handleUploadSend = useCallback(async (payload: UploadPayload) => {
    const response = await onUploadSend(payload);
    if (response.success) {
      setValue('');
      setTrimmed('');
    }
    return response;
  }, [onUploadSend])

  return (
    <Box sx={{borderTop: 1, borderColor: grey[300], flexShrink: 1, maxHeight: '30%'}}>
      <Box display={'flex'} marginY={1}>
        <Box sx={{flexShrink: 1}} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <UploadButton
            disabled={!canUpload}
            handleSend={handleUploadSend}
            getInitialCaption={getCurrentText}/>
        </Box>
        <Box sx={{flexGrow: 1}}>
          <TextField
            placeholder="Message"
            maxRows={4}
            size={'small'}
            value={value}
            multiline
            fullWidth
            disabled={loading}
            onChange={onTextChange}
            inputProps={{maxLength: 5000}}
          />
        </Box>
        <Box sx={{flexShrink: 1}} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <IconButton aria-label="Send Message" disabled={!canSubmit} onClick={onSubmit}>
            <SendIcon/>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Chat;
