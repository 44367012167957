// QWIL-2155: import from the dist folder otherwise it won't use web workers
// @ts-ignore
import Pica from "pica/dist/pica";
import { getFileExtension, replaceFileExtension } from "./utils";

const imageExtensions = new Set(["jpg", "jpeg", "png", "bmp", "gif"]);
const imageMimetypes = new Set([
  'image/apng', // animated PNG
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/bmp',
])

export function isImageFilename(filename: string) {
  const extension = getFileExtension(filename);
  return imageExtensions.has(extension)
}

export function isGifFilename(filename: string) {
  return getFileExtension(filename) === 'gif';
}

export function isImageMimetype(mimetype: string) {
  return imageMimetypes.has(mimetype.toLowerCase());
}

const loadImage = (src: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = src;
  });
};

const pica = Pica();

type DownscaledImage = {
  blob: Blob,
  originalWidth: number;
  originalHeight: number;
  newWidth: number;
  newHeight: number;
  newFilename: string;
}


export async function getImageDimensions(imageData: File | Blob) {
  const src = URL.createObjectURL(imageData);
  const image = await loadImage(src);
  return {
    width: image.naturalWidth,
    height: image.naturalHeight,
  }
}

export async function downscaleImage(
  file: File,
  size: number = 1280
): Promise<DownscaledImage> {
  const src = URL.createObjectURL(file);
  const image = await loadImage(src);
  const originalWidth = image.naturalWidth;
  const originalHeight = image.naturalHeight;

  const canvas = document.createElement("canvas");
  const ratio = size / Math.max(originalWidth, originalHeight, size);
  const newWidth = originalWidth * ratio;
  const newHeight = originalHeight * ratio;
  canvas.width = newWidth;
  canvas.height = newHeight;

  const picaCanvas = document.createElement("canvas");
  picaCanvas.width = canvas.width;
  picaCanvas.height = canvas.height;
  await pica.resize(image, picaCanvas, { alpha: true });

  // so that transparent will become white
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  ctx.fillStyle = "#FFF";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(picaCanvas, 0, 0, canvas.width, canvas.height);

  const res = await pica.toBlob(canvas, `image/jpeg`);
  URL.revokeObjectURL(src);

  return {
    blob: res,
    originalWidth: Math.floor(originalWidth),
    originalHeight: Math.floor(originalHeight),
    newWidth: Math.floor(newWidth),
    newHeight: Math.floor(newHeight),
    newFilename: replaceFileExtension(file.name, 'jpg'),
  };
}
