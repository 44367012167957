import { Box, BoxProps, Typography } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import React from "react";
import { EntityUserDTO } from "../../../api/dto";
import typography from "../../../typography";
import UserAvatar from "../../UserAvatar";
import BaseChatEntry from "./BaseChatEntry";

type Props = {
  text: string;
  date: Date;
  author: EntityUserDTO;
  requestId?: string;
}

function ActionChatEntry({text, author, date, requestId}: Props) {
  const avatar = (
    <UserAvatar
      firstName={author.first_name}
      lastName={author.last_name}
      entityUserXrefUuid={author.entity_user_xref_uuid}
    />
  );

  const textPositionProps: BoxProps = author.me ? {
    textAlign: 'right'
  } : {};

  const requestIdBox = requestId ? (
    <Box {...textPositionProps} marginBottom={1}>
      <Typography
        component="span"
        fontSize={typography.xs}
        color={grey[400]}
        margin={0}
      >
        Request ID: {requestId}
      </Typography>
    </Box>
  ) : null;

  return (
    <BaseChatEntry
      avatar={avatar}
      date={date}
      position={author.me ? 'right' : 'left'}
      name={author.me ? 'You' : `${author.first_name} ${author.last_name}`}
    >
      <Box paddingTop={1}>
        <Typography
          component="span"
          fontSize={typography.sm}
          color={blueGrey[700]}
          margin={0}
        >
          {text}
        </Typography>
      </Box>
      {requestIdBox}
    </BaseChatEntry>
  );
}

export default ActionChatEntry;
