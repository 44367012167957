import { Alert, Box } from "@mui/material";
import * as PDFjs from "pdfjs-dist";
import { PDFDocumentProxy } from "pdfjs-dist";
import React, { useEffect, useState } from "react";
import PdfRenderer from "../../PdfRenderer";

PDFjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`

interface Props {
  pdfBlob: Blob;
  filename: string;
}

function PdfViewer({pdfBlob, filename}: Props) {
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();
  const [loadError, setLoadError] = useState('');

  // Handle deallocation on unmount
  useEffect(() => {
    return () => {
      if (pdfDocument) {
        pdfDocument.destroy().then(() => console.debug('deallocated pdf data'));
      }
    }
  }, [pdfDocument]);

  useEffect(() => {
    if (!pdfBlob) {
      return;
    }

    (async () => {
      const data = await pdfBlob.arrayBuffer();
      PDFjs.getDocument(data).promise.then(
        (doc) => {
          setPdfDocument(doc);
        },
        () => setLoadError('Failed to load document')
      );
    })();
  }, [pdfBlob, filename]);

  return (
    <Box sx={{height: '100%'}}>
      {loadError ? <ErrorPlaceholder errorText={loadError}/> : null}
      {pdfDocument ? <PdfRenderer pdf={pdfDocument}/> : null}
    </Box>
  );
}

interface ErrorPlaceholderProps {
  errorText: string;
}

function ErrorPlaceholder({errorText}: ErrorPlaceholderProps) {
  return (
    <Box sx={{margin: 1}}>
      <Alert severity="error">{errorText}</Alert>
    </Box>
  );
}

export default PdfViewer;
