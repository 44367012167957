import { Box, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import QwilLogo from "../../components/QwilLogo";
import { getController } from "../../controller";
import { getScope, isEmbedded } from "../../utils";

function Landing() {

  const scope = getScope();
  const canAuth = scope && isEmbedded();

  useEffect(() => {
    if (!canAuth) {
      return;
    }

    // TODO: if already authenticated, redirect to default screen. If not, either redirect auth err page or trigger init as below

    // on initial app load, IFrame API would wait for this signal before a login request is sent
    const controller = getController(scope);
    controller.signalAppLoaded();

  }, [canAuth, scope]);

  return (
    <Box
      sx={{
        marginTop: 12,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <QwilLogo title='Qwil Messenger Lite'/>
      {canAuth ? (
      <Box marginTop={5}>
        <CircularProgress/>
      </Box>
      ) : (
      <Box margin={8} textAlign={'center'}>
        <Typography sx={{ color: grey[500]}}>
          For details on how to integrate Qwil within your application,
          please contact <a href='https://support.qwil.io/hc/en-us/requests/new'>Qwil Support</a>.
        </Typography>
      </Box>
      )}

    </Box>
  )
}

export default Landing;
