import { Box, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { EntityUserDTO } from "../../../api/dto";
import { emitClickedOnContact } from "../../../controller";
import { getSdkOptions } from "../../../utils";
import UserAvatar from "../../UserAvatar";

interface Props {
  user: EntityUserDTO;
}

export function ParticipantEntry({user}: Props) {
  const { contactsTappable } = useMemo(() => getSdkOptions(), []);
  const avatar = (
    <UserAvatar
      firstName={user.first_name}
      lastName={user.last_name}
      entityUserXrefUuid={user.entity_user_xref_uuid}
    />
  );

  const onContactClicked = useCallback(() => {
    emitClickedOnContact(user);
  }, [user]);

  const extraProps = contactsTappable ? {
    onClick: onContactClicked,
    sx: { cursor: 'pointer'},
  } : {};

  return (
    <Box margin={1} marginTop={2}>
      <Box
        margin={0}
        padding={0}
        display='flex'
        flexShrink={0}
        {...extraProps}
      >
        <Box width={40} flexShrink={1} paddingTop={0.3}>
          {avatar}
        </Box>
        <Box flexShrink={1} marginX={1} width={'90%'}>
          <Box
            display={'flex'}
            margin={0}
            padding={0}
            flexDirection={'column'}
            sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}
          >
            <Box>
              <Typography
                fontSize={'0.9em'}
                noWrap
              >
                {user.first_name} {user.last_name}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'}>
              {/*<RoleBadge isStaff={user.is_staff}/>*/}
              <Typography
                noWrap
                // marginLeft={0.5}
                fontWeight={300}
                fontSize={'0.8rem'}
              >
                {user.job_title}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
