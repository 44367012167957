import React from "react";
import { DocumentDTO, MeetingDTO, MessageDTO } from "../../api/dto";
import { MessageContentKind } from "../../enums";
import ActionChatEntry from "./ChatEntry/ActionChatEntry";
import DeletedChatEntry from "./ChatEntry/DeletedChatEntry";
import FileChatEntry from "./ChatEntry/FileChatEntry";
import ImageChatEntry from "./ChatEntry/ImageChatEntry";
import MeetingChatEntry from "./ChatEntry/MeetingChatEntry";
import SignatureSignedEntry from "./ChatEntry/SignatureSignedEntry";
import SystemMessageChatEntry from "./ChatEntry/SystemMessageChatEntry";
import TextChatEntry from "./ChatEntry/TextChatEntry";
import UnknownChatEntry from "./ChatEntry/UnknownChatEntry";

export function renderMessage(
  message: MessageDTO,
  onDocumentClick: (message: MessageDTO) => void,
) {
  const content = message.content;
  const date = new Date(message.created_at);
  const key = `m:${message.chat_uuid}:${message.seq}`;

  switch (content.kind) {
    case MessageContentKind.TEXT:
      return <TextChatEntry text={content.text} author={message.author} date={date} key={key}/>;
    case MessageContentKind.ACTION:
      return <ActionChatEntry text={content.text} author={message.author} date={date} key={key}/>;
    case MessageContentKind.ACTION_WITH_REQUEST_ID:
      return <ActionChatEntry text={content.text} author={message.author} date={date} requestId={content.request_id!}
                              key={key}/>;
    case MessageContentKind.SYSTEM:
      return <SystemMessageChatEntry text={content.text} key={key}/>;
    case MessageContentKind.IMAGE:
      return <ImageChatEntry
        document={content.document as DocumentDTO}
        onDocumentClick={() => onDocumentClick(message)}
        author={message.author}
        caption={message.content.caption}
        date={date}
        key={key}
      />;
    case MessageContentKind.FILE:
      return <FileChatEntry
        document={content.document as DocumentDTO}
        onDocumentClick={() => onDocumentClick(message)}
        author={message.author}
        caption={message.content.caption}
        date={date}
        key={key}
      />;
    case MessageContentKind.MEETING:
    case MessageContentKind.EXTERNAL_MEETING:
      return <MeetingChatEntry
        date={date}
        text={content.text}
        meeting={content.meeting as MeetingDTO}
        message={message}
        author={message.author}
        key={key}
      />;
    case MessageContentKind.SIGNATURE_SIGNED:
    case MessageContentKind.GUEST_SIGNATURE_SIGNED:
      return <SignatureSignedEntry
        date={date}
        author={message.author}
        document={content.document as DocumentDTO}
        onDocumentClick={() => onDocumentClick(message)}
        text={content.text}
        requestId={content.request_id!}
        key={key}
      />
    case MessageContentKind.DELETED:
      return <DeletedChatEntry author={message.author} date={date} key={key}/>;
    default:
      return <UnknownChatEntry author={message.author} date={date} key={key}/>;

  }
}
