import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Dialog, IconButton, Slide, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { TransitionProps } from "@mui/material/transitions";
import React, { forwardRef } from "react";
import { ChatDTO, EntityUserDTO } from "../../../api/dto";
import { ParticipantEntry } from "./participant";

interface Props {
  chat: ChatDTO;
  open: boolean;
  handleClose: () => void;
  activeParticipants: EntityUserDTO[],
  inactiveParticipants: EntityUserDTO[],
}

function ChatDetails({chat, open, handleClose, activeParticipants, inactiveParticipants}: Props) {

  const activeList = activeParticipants.map(user => (
    <ParticipantEntry
      user={user}
      key={`ap:${chat.chat_uuid}:${user.entity_user_xref_uuid}`}
    />
  ));

  const inactiveList = inactiveParticipants.map(user => (
    <ParticipantEntry
      user={user}
      key={`ap:${chat.chat_uuid}:${user.entity_user_xref_uuid}`}
    />
  ));

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box sx={{height: "100%", minWidth: '250px'}} display={'flex'} flexDirection={'column'}>
        {/* Header */}
        <Box display={'flex'} paddingY={1.0}
             sx={{borderBottom: 1, borderColor: grey[300], flexShrink: 1}}>
          <Box sx={{flexShrink: 1}} display={'flex'} alignItems={'center'} justifyContent={'center'} marginLeft={1}>
            <IconButton aria-label="Back to chat" size={'small'} onClick={handleClose}>
              <ArrowBackIosIcon/>
            </IconButton>
          </Box>
          <Box sx={{flexGrow: 1, textOverflow: 'ellipsis', overflow: 'hidden'}} display={'flex'}
               justifyContent={'center'} flexDirection={'column'}>
            <Box component='div' sx={{textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 'bold'}}
                 textAlign={'center'}>
              <Typography noWrap fontWeight={500}>
                Chat Details
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Content */}
        <Box>
          <Box margin={2}>
            <Typography fontWeight={'500'} fontSize={'0.9rem'}>
              Title
            </Typography>
            <Typography fontWeight={'300'}>
              {chat.title}
            </Typography>
          </Box>

          { activeList.length > 0 ? (
            <Box margin={2} marginTop={4}>
              <Typography fontWeight={'500'} fontSize={'0.9rem'}>
                Participants
              </Typography>
              {activeList}
            </Box>
          ) : null}

          { inactiveList.length > 0 ? (
            <Box margin={2} marginTop={4}>
              <Typography fontWeight={'500'} fontSize={'0.9rem'}>
                Previous Participants
              </Typography>
              {inactiveList}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Dialog>
  );
}


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  // eslint-disable-next-line react/jsx-no-undef
  return <Slide direction="left" ref={ref} {...props}/>;
});

export default ChatDetails;
