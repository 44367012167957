import { SDK_THEME_DEFAULTS } from "./theme";


// Options that can be passed in via iframe api
export const SDK_OPTION_DEFAULTS = {
  theme: SDK_THEME_DEFAULTS,

  // Makes contacts (in chat details and contacts list) tappable. Taps trigger 'click-on-contact' events.
  contactsTappable: false,

  // If true, emails in Contact list are clickable and will open mail client
  contactEmailClickable: true,

  // If true, phone numbers in Contact list are clickable and will open phone client
  contactPhoneClickable: true,

  // If true, org name is shown on Contacts page
  showOrgNameOnContactsPage: false,

  // Download requests trigger 'download-request' event instead of offering download
  emitDownloads: false,

  // Meeting join trigger 'meeting-join' event instead of opening meeting url in a new window
  emitMeetingJoin: false,

  // "Book a meeting" trigger 'book-meeting' event instead of opening calendar url in a new window
  emitBookMeeting: false,

  // Enables in-app image preview
  imagePreview: true,

  // Enables in-app PDF preview
  pdfPreview: true,

  // How often we check for updates, in seconds
  pollingFrequency: 5,

  // If true, back button on Chat screen is hidden
  hideChatBack: false,

  // If true, top nav (contacts/chats) is hidden
  hideTopNav: false,

  // Text used in top nav for "Contacts"
  topNavLabelContacts: 'Contacts',

  // Text used in top nav for "Chats"
  topNavLabelChats: 'Chats',

  // Adds an extra heading above top nav
  topNavHeading: '',

  // If true, user can start chat
  canStartChat: true,

  // If true, user can book meeting with staff that has enabled Qwil Scheduler
  canBookMeeting: true,

  // Title shown in Start Chat Dialog header
  startChatTitle: 'Start a new chat',

  // Text shown in submit button on Start Chat dialog
  startChatSubmitButtonText: 'Create Chat',

  // Form label for chat title on Start Chat dialog
  startChatTitleLabel: 'Chat Title',

  // Form label for chat title on Start Chat dialog
  startChatMessageLabel: 'Your First Message',

  // Back button label on search results
  searchBackButtonLabel: 'Back to chat list',

  // Message displayed when search returns no results.
  searchNoResultsMessage: 'No messages found using that search term.',
}

export type SdkOptions = typeof SDK_OPTION_DEFAULTS;

export const MIN_POLLING_FREQUENCY = 2;

function extractBooleanSdkOption(input: object, key: keyof SdkOptions) {
  if (typeof SDK_OPTION_DEFAULTS[key] !== 'boolean') {
    throw new Error(`"${key}" is not a boolean option`);
  }

  if (!input || !(key in input)) {
    return SDK_OPTION_DEFAULTS[key] as boolean;
  } else {
    return Boolean((input as any)[key]);
  }
}

function extractNumberSdkOption(input: object, key: keyof SdkOptions) {
  if (typeof SDK_OPTION_DEFAULTS[key] !== 'number') {
    throw new Error(`"${key}" is not a numeric option`);
  }

  if (!input || !(key in input)) {
    return SDK_OPTION_DEFAULTS[key] as number;
  }

  const value = (input as any)[key];
  if (typeof value !== 'number') {
    console.error(`Expecting number for "${key}", got ${typeof value}. Ignoring this option.`)
    return SDK_OPTION_DEFAULTS[key] as number;
  }

  return value;
}

function extractTextSdkOption(input: object, key: keyof SdkOptions) {
  if (typeof SDK_OPTION_DEFAULTS[key] !== 'string') {
    throw new Error(`"${key}" is not a string option`);
  }

  if (!input || !(key in input)) {
    return SDK_OPTION_DEFAULTS[key] as string;
  }

  const value = (input as any)[key];
  if (typeof value !== 'string') {
    console.error(`Expecting string for "${key}", got ${typeof value}. Ignoring this option.`)
    return SDK_OPTION_DEFAULTS[key] as string;
  }

  return value;
}

function extractThemeSdkOption(input: object) {
  if (!input) {
    return {...SDK_THEME_DEFAULTS};
  }

  const value = (input as any)['theme'];
  if (typeof value !== 'object') {
    if (value) {
      console.error(`Expecting object for "theme", got ${typeof value}. Ignoring this option.`)
    }
    return {...SDK_THEME_DEFAULTS};
  }

  return {...SDK_THEME_DEFAULTS, ...value};
}



export function extractSdkOptions(input: object) {
  const options: SdkOptions = {
    contactsTappable: extractBooleanSdkOption(input, 'contactsTappable'),
    emitDownloads: extractBooleanSdkOption(input, 'emitDownloads'),
    emitMeetingJoin: extractBooleanSdkOption(input, 'emitMeetingJoin'),
    emitBookMeeting: extractBooleanSdkOption(input, 'emitBookMeeting'),
    imagePreview: extractBooleanSdkOption(input, 'imagePreview'),
    pdfPreview: extractBooleanSdkOption(input, 'pdfPreview'),
    pollingFrequency: extractNumberSdkOption(input, 'pollingFrequency'),
    hideChatBack: extractBooleanSdkOption(input, 'hideChatBack'),
    hideTopNav: extractBooleanSdkOption(input, 'hideTopNav'),
    topNavLabelContacts: extractTextSdkOption(input, 'topNavLabelContacts'),
    topNavLabelChats: extractTextSdkOption(input, 'topNavLabelChats'),
    topNavHeading: extractTextSdkOption(input, 'topNavHeading'),
    contactEmailClickable: extractBooleanSdkOption(input, 'contactEmailClickable'),
    contactPhoneClickable: extractBooleanSdkOption(input, 'contactPhoneClickable'),
    showOrgNameOnContactsPage: extractBooleanSdkOption(input, 'showOrgNameOnContactsPage'),
    canStartChat: extractBooleanSdkOption(input, 'canStartChat'),
    canBookMeeting: extractBooleanSdkOption(input, 'canBookMeeting'),
    startChatTitle: extractTextSdkOption(input, 'startChatTitle'),
    startChatSubmitButtonText: extractTextSdkOption(input, 'startChatSubmitButtonText'),
    startChatTitleLabel: extractTextSdkOption(input, 'startChatTitleLabel'),
    startChatMessageLabel: extractTextSdkOption(input, 'startChatMessageLabel'),
    searchBackButtonLabel: extractTextSdkOption(input, 'searchBackButtonLabel'),
    searchNoResultsMessage: extractTextSdkOption(input, 'searchNoResultsMessage'),
    theme: extractThemeSdkOption(input),
  };

  if (options.pollingFrequency < MIN_POLLING_FREQUENCY) {
    console.error(`"pollingFrequency" cannot be less than ${MIN_POLLING_FREQUENCY}s. Ignoring this option.`);
    options.pollingFrequency = SDK_OPTION_DEFAULTS.pollingFrequency;
  }

  if (options.pollingFrequency > 500) {
    console.warn(`"pollingFrequency" of ${options.pollingFrequency} seconds seems rather high. Is this correct?`)
  }

  return options;
}

