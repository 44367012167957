import { API } from "../../api";
import { APIBlobResponse, ErrorResponse } from "../../api/types";

// We use a cache for loading avatar images because relying on browser cache alone is insufficient,
// in particular, when it comes to 404 responses. By maintaining our own cache, we ensure that
// we don't keep retrying the fetch for users that have no avatar.
const _cache: { [key: string]: Promise<Blob | null> } = {};

export function getAvatarImage(entityUserXrefUuid: string, source: 'chat' | 'contact') {
  if (!(entityUserXrefUuid in _cache)) {
    _cache[entityUserXrefUuid] = new Promise((resolve) => {
      const avatarGetter = source === 'contact' ? API.getContactAvatar : API.getChatParticipantAvatar;
      avatarGetter(entityUserXrefUuid)
        .then(response => {
          if ((response as ErrorResponse).isError) {
            resolve(null);  // no need to handle specific errors. Just assume no avatar.
          } else {
            resolve((response as APIBlobResponse).blob);
          }
        })
    });
  }
  return _cache[entityUserXrefUuid];
}

export function resetAvatarCache() {
  for (let key in _cache) {
    delete _cache[key];
  }
}

