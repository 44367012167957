import { Box, BoxProps } from "@mui/material";
import React from "react";
import { DocumentDTO, EntityUserDTO } from "../../../api/dto";
import UserAvatar from "../../UserAvatar";
import BaseChatEntry from "./BaseChatEntry";
import { TextBox } from "./TextChatEntry";

type Props = {
  document: DocumentDTO;
  date: Date;
  author: EntityUserDTO;
  caption?: string;
  onDocumentClick: () => void;
}

function ImageChatEntry({document, date, author, caption, onDocumentClick}: Props) {
  const avatar = (
    <UserAvatar
      firstName={author.first_name}
      lastName={author.last_name}
      entityUserXrefUuid={author.entity_user_xref_uuid}
    />
  );

  const positionProps: BoxProps = author.me ? {
    flexDirection: 'row-reverse',
    marginLeft: 'auto',
  } : {
    marginRight: 'auto',
  }

  return (
    <BaseChatEntry
      avatar={avatar}
      date={date}
      position={author.me ? 'right' : 'left'}
      name={author.me ? 'You' : `${author.first_name} ${author.last_name}`}
    >
      <Box marginBottom={1} display={'flex'} {...positionProps}>
        <img
          src={document.thumbnail}
          alt={document.filename}
          style={{
            maxHeight: 300,
            imageOrientation: 'from-image',
            borderRadius: '0.25rem',
            cursor: 'pointer',
          }}
          onClick={onDocumentClick}
        ></img>
      </Box>
      {caption ? (
        <TextBox text={caption} author={author}/>
      ) : null}
    </BaseChatEntry>
  );
}

export default ImageChatEntry;
