import {
  RequestErrorCallback,
  Transport,
  TransportEventHandlerProps,
  TransportRequestHandlerProps
} from 'qwil-api-transport';
import { EntityUserDTO } from "../api/dto";
import { handleCommand, handleLoginRequest } from "./actions";
import { inEvents, outEvents, requestEvents } from "./constants";
import { CommandRequest } from "./types";

class Controller {
  scope: string;
  transport: Transport;

  constructor(scope: string) {
    this.scope = scope;
    this.transport = new Transport({
      scope,
      eventHandler: this._handleEvent,
      requestHandler: this._handleRequest,
      window: window.parent
    });
  }

  _handleEvent(props: TransportEventHandlerProps) {
    // this handles incoming events
    const {event, payload} = props;
    switch (event) {
      case inEvents.COMMAND:
        const commandPayload = payload as CommandRequest;
        if (!commandPayload.command) {
          console.error(`Invalid payload for command event`, commandPayload);
        } else {
          handleCommand(commandPayload);
        }
        break
      default:
        console.error(`Unsupported incoming event "${event}"`);
    }
  }

  _handleRequest(props: TransportRequestHandlerProps) {
    const {request, payload, onComplete} = props;
    switch (request) {
      case requestEvents.LOGIN:
        handleLoginRequest(payload || {}, onComplete);
        break
      default:
        console.error(`Unsupported request "${request}"`);
        (onComplete as RequestErrorCallback)({
          error: {
            name: 'UnsupportedRequest',
            message: `"${request}" is not a supported request`,
          }
        })
    }
  }

  signalAppLoaded() {
    this.transport.sendEvent(outEvents.APP_LOADED);
  }

  emitEvent(event:string, payload?: object) {
    this.transport.sendEvent(event, payload);
  }
}

let _controller: Controller;

export function getController(scope: string) {
  if (!_controller) {
    _controller = new Controller(scope);
  }
  if (_controller.scope !== scope) {
    // this should not happen, but we want to know if it does.
    console.warn('Ignoring #scope change');
  }
  return _controller;
}


export function signalAuthExpired() {
  _controller?.emitEvent(outEvents.AUTH_EXPIRED);
}

export function emitAppErrorMessage(message: string) {
  _controller?.emitEvent(outEvents.APP_ERROR_MESSAGE, { message });
}

export function emitClickedOnContact(user: EntityUserDTO) {
  _controller?.emitEvent(outEvents.APP_CLICKED_ON_CONTACT, {
    entityUserXrefUuid: user.entity_user_xref_uuid,
    identifier: user.identifier,
    firstName: user.first_name,
    lastName: user.last_name,
    isSelf: user.me,
  })
}

export function emitDownloadRequest(filename: string, url: string) {
  _controller?.emitEvent(outEvents.DOWNLOAD_REQUEST, { filename, url });
}

export function emitMeetingJoinUrl(url: string, meetingUuid: string) {
  _controller?.emitEvent(outEvents.MEETING_JOIN, { url, meetingUuid });
}

export function emitBookMeetingUrl(url: string, entityUserXrefUuid: string) {
  _controller?.emitEvent(outEvents.BOOK_MEETING, { url, entityUserXrefUuid });
}

export function emitPathChange(path: string) {
  _controller?.emitEvent(outEvents.PATH_CHANGE, { path });
}
