import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Box, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import { EntityUserDTO } from "../../../api/dto";
import typography from "../../../typography";
import UserAvatar from "../../UserAvatar";
import BaseChatEntry from "./BaseChatEntry";

type Props = {
  date: Date;
  author: EntityUserDTO;
}

function UnknownChatEntry({author, date}: Props) {
  const avatar = (
    <UserAvatar
      firstName={author.first_name}
      lastName={author.last_name}
      entityUserXrefUuid={author.entity_user_xref_uuid}
    />
  );
  return (
    <BaseChatEntry
      avatar={avatar}
      date={date}
      position={author.me ? 'right' : 'left'}
      name={author.me ? 'You' : `${author.first_name} ${author.last_name}`}
    >
      <Box paddingY={1} color={red[200]}>
        <Stack alignItems="center" direction="row" gap={0.5}>
          <AnnouncementIcon fontSize={'small'}/>
          <Typography
            component="span"
            fontSize={typography.m}
            margin={0}
          >
            Unsupported message type
          </Typography>
        </Stack>

      </Box>

    </BaseChatEntry>
  );
}

export default UnknownChatEntry;
