import { Box, BoxProps, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { format } from "date-fns";
import React, { ReactNode } from "react";
import typography from "../../../typography";

type Props = {
  children: ReactNode,
  avatar: ReactNode,
  date: Date,
  name: string,
  position: 'left' | 'right',
}

function BaseChatEntry({children, avatar, date, position, name}: Props) {

  const formattedTime = format(date, 'p'); // e.g "12:00 AM"

  const positionProps: BoxProps = position === 'right' ? {
    flexDirection: 'row-reverse',
    marginLeft: 'auto',
  } : {
    marginRight: 'auto',
  }

  return (
    <Box margin={1}>
      <Box
        margin={0}
        padding={0}
        display='flex'
        flexShrink={0}
        {...positionProps}
      >
        <Box width={40} flexShrink={1}>&nbsp;</Box>

        <Box>
          <Typography
            component="span"
            fontSize={typography.xs}
            color={grey[500]}
            marginX={1}
            sx={{textWrap: "nowrap"}}
          >
            {name} - {formattedTime}
          </Typography>
        </Box>
      </Box>
      <Box
        margin={0}
        display='flex'
        flexShrink={0}
        {...positionProps}
      >
        <Box width={40}>
          {avatar}
        </Box>
        <Box flexShrink={1} marginX={1}>
          <Box {...positionProps}>{children}</Box>
        </Box>

      </Box>
    </Box>
  );
}

export default BaseChatEntry;
