import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Divider, FormControl, ListItemText, MenuItem, Select, Typography } from "@mui/material";

import { grey } from "@mui/material/colors";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { PDFDocumentProxy } from "pdfjs-dist";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useOnElementScroll, useOnElementSizeChange, usePrevious } from "../../hooks";
import { bisectLeft } from "../../utils";
import RenderScheduler from "./RenderScheduler";
import { PageCache } from "./types";

const CONTAINER_PADDING = 20;
const PAGE_MARGIN = 10;
const SPACE_LEFT_OF_PAGE = CONTAINER_PADDING + PAGE_MARGIN;
const SPACE_TOP_OF_PAGE = CONTAINER_PADDING + PAGE_MARGIN;
const SPACE_BETWEEN_PAGES = PAGE_MARGIN * 2;
const CANVAS_ID_PREFIX = 'canvas-';
const MAX_ZOOM = 2;
const ZOOM_OPTIONS = [50, 75, 100, 125, 150, 200];

interface Props {
  pdf: PDFDocumentProxy;
}

type PageOffsetMap = {[key: number]: number[]};

function PdfRenderer({pdf}: Props) {
  const pageRefs = useRef<Array<HTMLElement | null>>([])
  const pageOffsets = useRef<PageOffsetMap>([]);
  const parentContainerRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLElement>(null);

  // const parentContainerRef = useRef<HTMLElement>(null);
  const [maxPageWidth, setMaxPageWidth] = useState(0);
  const [pages, setPages] = useState<PageCache[]>();
  const [zoom, _setZoom] = useState(0);
  // variant of zoom, because select box expect string
  const [selectedZoom, setSelectedZoom] = useState('fit');
  const zoomThrottleTimeout = useRef<ReturnType<typeof setTimeout>>();
  const previousZoom = usePrevious<number>(zoom);
  // the following is displayed as info for users (calculated based on scroll position)
  const [currentPageNum, setCurrentPageNum] = useState('1');

  const setZoom = useCallback((zoom: number, isInit = false) => {
    if (!zoom) {
      throw new Error('Invalid value for zoom');
    }

    // Because setting "zoom" triggers rerender, we throttle it so it doesn't get called multiple times
    if (zoomThrottleTimeout.current) {
      clearTimeout(zoomThrottleTimeout.current);
      zoomThrottleTimeout.current = undefined;
    }

    zoomThrottleTimeout.current = setTimeout(() => {
      if (zoom < 0) {
        const container = parentContainerRef.current as HTMLElement;
        zoom = calcZoomToFitWidth(container, maxPageWidth);
      }

      _setZoom(Math.min(MAX_ZOOM, zoom));
      zoomThrottleTimeout.current = undefined;
    }, isInit ? 0 : 500);

    return () => {
      if (zoomThrottleTimeout.current) {
        clearTimeout(zoomThrottleTimeout.current);
      }
      zoomThrottleTimeout.current = undefined;
    }
  }, [maxPageWidth]);

  const handleZoomSelected = useCallback((event: SelectChangeEvent) => {
    const selected = event.target.value as string;
    setSelectedZoom(selected);
    setZoom(selected === "fit" ? -1 : parseInt(selected, 10) / 100);
  }, [setZoom])

  const getCanvas = useCallback((pageNum: number) => {
    if (!containerRef.current) {
      return null;
    } else {
      const container = containerRef.current;
      return container.querySelector(`#${CANVAS_ID_PREFIX}${pageNum}`) as HTMLCanvasElement;
    }
  }, []);

  const scheduler = useMemo(() => {
    if (!pages) {
      return undefined;
    }
    return new RenderScheduler(pages, getCanvas);
  }, [getCanvas, pages]);

  // initial render on load
  useEffect(() => {
    (async () => {
      // cache pdf page proxies and original page dims
      const pages: PageCache[] = [];
      let maxWidth = 0;

      // we "could" do this in parallel to optimise, but really not worth it.
      for (let i = 1; i <= pdf.numPages; i++) {
        let page = await pdf.getPage(i);
        let unscaledViewport = page.getViewport({scale: 1});
        // let ref = useRef<HTMLElement>(null)
        pages.push({
          page,
          num: i,
          actualWidth: unscaledViewport.width,
          actualHeight: unscaledViewport.height,
        });
        maxWidth = Math.max(maxWidth, unscaledViewport.width);
      }

      setMaxPageWidth(maxWidth);
      setPages(pages);
      // now we're ready to render PDF pages.
      // setting (or changing) zoom triggers page render logic
      setZoom(-1, true);  // fit width
    })();
  }, [pdf, setZoom]);


  useOnElementSizeChange(parentContainerRef, () => {
    if (selectedZoom === 'fit') {
      setZoom(-1);
    } else {
      // manually trigger page num eval since window height could have changed
      evalCurrentPageNum();
    }
  });

  const evalCurrentPageNum = () => {
    if (!pages || !zoom || !containerRef.current) {
      return;
    }

    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const scrollBottom = scrollTop + container.getBoundingClientRect().height;
    const offsets = getOffsets(pages, pageOffsets.current, zoom);

    // getVerticalPageOffset = (pages: PageCache[], scrollTop: number, zoom: number, offsets: number[]): VerticalPageLocation => {
    const location1 = getVerticalPageOffset(pages, scrollTop, zoom, offsets);
    const location2 = getVerticalPageOffset(pages, scrollBottom, zoom, offsets);
    const p1 = Math.max(1, Math.min(location1.pageNum, pages.length))
    const p2 = Math.max(1, Math.min(location2.pageNum, pages.length))
    if (p1 === p2) {
      setCurrentPageNum(`${p1}`);
    } else {
      setCurrentPageNum(`${p1}-${p2}`);
    }

  }

  useOnElementScroll(containerRef, evalCurrentPageNum);

  const getOffsets = (pages: PageCache[], offsetMap:PageOffsetMap, zoom: number) => {
    let offsets = offsetMap[zoom];
    if (!offsets) {
      offsets = calculatePageOffsets(pages, zoom);
      offsetMap[zoom] = offsets;
    }
    return offsets;
  }

  const getScrollLocation = (pages: PageCache[], container: HTMLElement, offsetMap:PageOffsetMap, zoom: number) => {
      const offsets = getOffsets(pages, offsetMap, zoom);
      return getCurrentPageLocation(pages, container.scrollTop, container.scrollLeft, zoom, offsets);
  }

  useEffect(() => {
    if (!pages || !zoom || !scheduler) {
      return;
    }

    const scrollContainer = containerRef.current as HTMLElement;
    let startRenderingOnPage = 1;
    let prevLocation  = undefined;
    if (previousZoom) {
      prevLocation = getScrollLocation(pages, scrollContainer, pageOffsets.current, previousZoom);
      startRenderingOnPage = Math.max(prevLocation.pageNum, 1);
    }

    // give canvases actual sizes based on known page sizes and initial scale
    pages.forEach(page => {
      let canvas = getCanvas(page.num);
      if (!canvas) {
        console.error(`canvas for page ${page.num} not ready`)
        return;
      }

      // N.B. canvas.style.width !== canvas.width on hi-res display
      // We will set canvas.width (and height) when rendering to match screen dpi
      // Renderer will use canvas style dimensions to infer viewport size
      canvas.style.width = (page.actualWidth * zoom) + 'px';
      canvas.style.height = (page.actualHeight * zoom) + 'px';
    });

    // scroll to equivalent page content after pages are all resized
    if (prevLocation) {
      const newOffsets = getOffsets(pages, pageOffsets.current, zoom);
      scrollToLocation(prevLocation, pages, newOffsets, scrollContainer, zoom);
    }

    // update page counter in case user scrolled while things were changing
    evalCurrentPageNum();


    // re-render pages to match viewing resolution
    scheduler.renderPages(startRenderingOnPage);

    return () => {
      scheduler.abort();
    }

    // N.B. We definitely only want to trigger this when zoom changes, even if it accesses other states.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom]);


  return (
    <Box
      display={'flex'}
      flex={1}
      flexDirection={'column'}
      ref={parentContainerRef}
      sx={{height: '100%', width: '100%', overflow: 'hidden'}}
    >
      <Box
        flexGrow={1}
        display={'block'}

        ref={containerRef}
        sx={{
          bgcolor: grey[300],
          overflow: 'auto',
          width: '100%',
          scrollbarWidth: 'none',
        }}
      >
        <Box
          component={'div'}
          display={'inline-flex'}
          flexGrow={1}
          flexDirection={'column'}
          alignItems={'center'}
          sx={{
            padding: CONTAINER_PADDING + 'px',
          }}
        >
          {pages ? pages.map(p => (
            <Box
              component={'div'}
              key={`page-${p.num}`}
              ref={(el: HTMLElement) => pageRefs.current[p.num] = el}
              boxShadow={3}
              display={'flex'}
              alignItems={'center'}
              sx={{
                margin: PAGE_MARGIN + 'px',
                bgcolor: '#ffffff',
              }}
            >
              <canvas id={CANVAS_ID_PREFIX + p.num}/>
            </Box>
          )) : null}

        </Box>
      </Box>
      <Box
        flexShrink={1}
        sx={{
          bgcolor: '#ffffff',
          borderTop: `1px solid ${grey[200]}`,

        }}
        textAlign={'center'}
      >
        <Box
          padding={1}
          display={'flex'}
          justifyContent={'center'}
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            marginRight={'10px'}
          >
            <Typography
              flexShrink={1}
              noWrap
              fontWeight={200}
            >
              Page {currentPageNum || '?'} of {pages ? pages.length : '?'}
            </Typography>
          </Box>

          <Divider orientation="vertical" flexItem/>

          <Box
            marginRight={'10px'}
          >
            <FormControl sx={{minWidth: 120, marginLeft: 1}} size="small">
              <Select
                disabled={!zoom} // disabled until default zoom initialised
                IconComponent={ZoomInIcon}
                variant={'standard'}
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedZoom}
                label="Zoom"
                onChange={handleZoomSelected}
              >
                {ZOOM_OPTIONS.map((z, i) => (
                  <MenuItem value={z} key={`zoom-${z}`} divider={i === ZOOM_OPTIONS.length - 1}>
                    <ListItemText sx={{textAlign: 'center', m: 0}}>
                      {z}%
                    </ListItemText>
                  </MenuItem>
                ))}
                <MenuItem value={'fit'}>
                  Fit to width
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

      </Box>
    </Box>
  );
}

type VerticalPageLocation = {
  pageNum: number; // if -1, vertical scroll offset is top of page. -2 if after last page
  pageOffsetPercent: number; // if -1, vertical scroll offset is top of page
}

type PageLocation = VerticalPageLocation & {
  horizontalOffsetUnscaledPx: number; // if -1, horizontal scroll offset is left of page
}

const getCurrentPageLocation = (pages: PageCache[], scrollTop: number, scrollLeft: number, zoom: number, offsets: number[]): PageLocation => {
  return {
    ...getVerticalPageOffset(pages, scrollTop, zoom, offsets),
    horizontalOffsetUnscaledPx: getHorizontalUnscaledScrollOffset(scrollLeft, zoom),
  }
}

const calculatePageOffsets = (pages: PageCache[], zoom: number) => {
  let total = SPACE_TOP_OF_PAGE;
  const offsets: number[] = [total];
  pages.forEach(page => {
    total += (page.actualHeight * zoom) + SPACE_BETWEEN_PAGES;
    offsets.push(total);
  })
  return offsets;
}

const getVerticalPageOffset = (pages: PageCache[], scrollTop: number, zoom: number, offsets: number[]): VerticalPageLocation => {
  const position = bisectLeft(offsets, scrollTop);
  if (position === 0) {
    // scroll is above first page.
    return { pageNum: -1, pageOffsetPercent: -1};
  } else if (position > pages.length) {
    // scroll is after last page
    return { pageNum: -2, pageOffsetPercent: -1};
  }

  const pageNum = position;
  const pageHeight = pages[pageNum - 1].actualHeight * zoom;
  const scrollPositionInOffset = scrollTop - offsets[position - 1];
  const pageOffsetPercent = scrollPositionInOffset / pageHeight;
  if (pageOffsetPercent < 1.0) {
    return { pageNum, pageOffsetPercent};
  } else {
    // we are in the margins between pages. return the next page instead
    return { pageNum: pageNum + 1, pageOffsetPercent: 0};
  }
}

const getHorizontalUnscaledScrollOffset = (scrollLeft: number, zoom: number) => {
  if (scrollLeft < SPACE_LEFT_OF_PAGE) {
    return -1;
  } else {
    return (scrollLeft - SPACE_LEFT_OF_PAGE) / zoom
  }
}

const scrollToLocation = (location: PageLocation, pages: PageCache[], offsets: number[], container: HTMLElement, zoom: number) => {
  _horizontalScrollToLocation(location, container, zoom);
  _verticalScrollToLocation(location, pages, container, offsets, zoom);
}

const _horizontalScrollToLocation = (location: PageLocation, container: HTMLElement, zoom: number) => {
  if (location.horizontalOffsetUnscaledPx === -1) {
    return; // nothing to do
  }
  container.scrollLeft = SPACE_LEFT_OF_PAGE + (location.horizontalOffsetUnscaledPx * zoom);
}

const _verticalScrollToLocation = (location: PageLocation, pages: PageCache[], container: HTMLElement, offsets: number[], zoom: number) => {
  if (location.pageNum < 0) {
    return; // nothing to do
  }

  // if we are at the end of a page, we simply start at the beginning of the next page
  let scrollTop = 0;
  if (location.pageOffsetPercent >= 1) {
    if (location.pageNum > offsets.length) {
      // already end of document. nothing to do.
      return;
    }
    scrollTop = offsets[location.pageNum + 1];
  } else {
    // first, start cursor at the end of previous page
    if (location.pageNum !== 0) {
      scrollTop = offsets[location.pageNum - 1];
    }
    // now apply the offset to % of scrolled page
    let page = pages[location.pageNum - 1];
    let scaledHeight = (page.actualHeight * zoom);
    if (location.pageOffsetPercent < 1) {
      scrollTop += scaledHeight * location.pageOffsetPercent;
    } else {
      // if already at the end of page, we move to beginning of next page
      scrollTop += scaledHeight + SPACE_BETWEEN_PAGES;
    }
  }
  container.scrollTop = scrollTop;
}

const calcZoomToFitWidth = (container: HTMLElement, maxPageWidth: number) => {
  const containerWidth = container.clientWidth;
  return (containerWidth - 2 * SPACE_LEFT_OF_PAGE) / maxPageWidth;
}

export default PdfRenderer;
