import { Alert, Box, Button, CircularProgress, Divider, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { API } from "../../api";
import { ChatDTO, CompactChatDTO, MessageDTO } from "../../api/dto";
import { APIGetChatMessagesResponse, ErrorResponse } from "../../api/types";
import { createUpdateAction } from "../../screens/ChatScreen/state/actions";
import { chatStateReducer } from "../../screens/ChatScreen/state/reducer";
import { ChatStateReducer } from "../../screens/ChatScreen/state/types";
import { ThemeContext } from "../../theme";

import { emitPreauthorisedDownloadLink, getSdkOptions } from "../../utils";
import LoadingPlaceholderChatEntry from "../Chat/ChatEntry/LoadingPlaceholderChatEntry";
import { isPreviewableDocument } from "../Chat/FilePreview";
import { useFilePreview, useMessageDownloader } from "../Chat/hooks";
import { renderMessage } from "../Chat/renderer";

const NUM_MESSAGES_BEFORE = 25;
const NUM_MESSAGES_AFTER = 25;
const LOAD_MORE_BATCH_SIZE = 40;


interface Props {
  message: MessageDTO;
  chat: CompactChatDTO | ChatDTO;
  onBack: () => void;
}

function DetachedChat({message, chat, onBack}: Props) {
  const showAsFullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const [state, dispatch] = useReducer<ChatStateReducer>(chatStateReducer, {
    chat: undefined,
    messages: [],
  });

  const {theme} = useMemo(() => getSdkOptions(), []);
  const [loading, setLoading] = useState(true);
  const [loadingAbove, setLoadingAbove] = useState(false);
  const [loadingBelow, setLoadingBelow] = useState(false);
  const [error, setError] = useState('');

  let hasMoreAbove = state.messages.length ? state.messages[0].seq > 0 : false;
  let hasMoreBelow = state.messages.length ? state.messages.at(-1)!.seq < chat.last_seq : false;

  const loadMessages = useCallback(async (fromSeq: number, toSeq: number) => {
    return API.getChatMessages(chat.chat_uuid, fromSeq, toSeq)
      .then(response => {
        if ((response as ErrorResponse).isError) {
          const scenario = (response as ErrorResponse).errorScenario;
          switch (scenario) {
            case 'InvalidAuth':
              // API handler should have already triggered nav, so we do nothing
              break;
            case 'ChatNotVisible':
              setError('This chat is no longer visible');
              break;
            case 'MessageNotVisible':
              setError('Requested messages exceeds visible range');
              break;
            default:
              setError('Failed to load messages');
          }
        } else {
          dispatch(createUpdateAction({messages: (response as APIGetChatMessagesResponse).messages}))
        }
      })
      .catch((error) => {
        setError('Failed to load messages');
      });
  }, [chat]);

  useEffect(() => {
    const loadFromSeq = Math.max(message.seq - NUM_MESSAGES_BEFORE, 0);
    const loadToSeq = Math.min(message.seq + NUM_MESSAGES_AFTER, chat.last_seq);

    (async () => {
      await loadMessages(loadFromSeq, loadToSeq);
      setLoading(false);
    })()

  }, [chat, loadMessages, message]);

  const doLoadMoreAbove = useCallback(async () => {
    if (hasMoreAbove) {
      const loadToSeq = state.messages[0].seq - 1;
      const loadFromSeq = Math.max(loadToSeq - LOAD_MORE_BATCH_SIZE, 0);
      setLoadingAbove(true);
      await loadMessages(loadFromSeq, loadToSeq);
      setLoadingAbove(false);
    }
  }, [hasMoreAbove, loadMessages, state.messages]);

  const doLoadMoreBelow = useCallback(async () => {
    if (hasMoreBelow) {
      const loadFromSeq = state.messages.at(-1)!.seq + 1;
      const loadToSeq = Math.min(loadFromSeq + LOAD_MORE_BATCH_SIZE, chat.last_seq);
      setLoadingBelow(true);
      await loadMessages(loadFromSeq, loadToSeq);
      setLoadingBelow(false);
    }
  }, [chat, hasMoreBelow, loadMessages, state.messages]);

  const moreStyles = showAsFullScreen ? {
    minWidth: '250px',
  } : {
    width: '550px',
  };

  return (
    <ThemeContext.Provider value={theme}>
      <Box sx={{height: "100%", ...moreStyles}} display={'flex'} flexDirection={'column'}>
        {loading ? <Box margin={2}><LoadingPlaceholder/></Box> : null}
        {state.messages.length ? (
          <MessageList
            isFullScreen={showAsFullScreen}
            messages={state.messages}
            targetSeq={message.seq}
            loadingAbove={loadingAbove}
            loadingBelow={loadingBelow}
            hasMoreAbove={hasMoreAbove}
            hasMoreBelow={hasMoreBelow}
            onLoadMoreAbove={doLoadMoreAbove}
            onLoadMoreBelow={doLoadMoreBelow}
          />
        ) : null}
        {error ? <Box margin={2}><Alert severity="error">{error}</Alert></Box> : null}
      </Box>
    </ThemeContext.Provider>
  );
}

function LoadingPlaceholder() {
  return (
    <Box textAlign={'center'}>
      <CircularProgress/>
      <Typography marginTop={2} textAlign={'center'} color={grey[400]}>
        Loading preview ...
      </Typography>
    </Box>
  );
}

interface MessageListProps {
  isFullScreen: boolean;
  messages: MessageDTO[];
  targetSeq: number;
  loadingAbove: boolean;
  loadingBelow: boolean;
  hasMoreAbove: boolean;
  hasMoreBelow: boolean;
  onLoadMoreAbove: () => void;
  onLoadMoreBelow: () => void;
}

function MessageList(
  {
    messages,
    targetSeq,
    loadingAbove,
    loadingBelow,
    hasMoreBelow,
    hasMoreAbove,
    onLoadMoreAbove,
    onLoadMoreBelow,
    isFullScreen,
  }: MessageListProps) {
  const {emitDownloads} = useMemo(() => getSdkOptions(), []);

  const {filePreviewElement, setMessageBeingPreviewed, setDocumentPreviewOpen} = useFilePreview();
  const {messageDownloaderElement, setMessageToDownload} = useMessageDownloader();
  const containerRef = useRef<HTMLElement>(null);

  // We render messages backwards and display with column-reverse so users start at bottom of chat
  // and it loads upwards
  const items = [];

  const doDownload = useCallback((message: MessageDTO) => {
    if (emitDownloads) {
      // will request for pre-authorised download link and emit it as event
      emitPreauthorisedDownloadLink(message);
    } else {
      // triggers download modal
      setMessageToDownload(message);
    }
  }, [emitDownloads, setMessageToDownload]);

  const onDocumentClick = useCallback((message: MessageDTO) => {
    if (!message.content.document) {
      throw new Error('This message should not have been clickable');
    }

    if (isPreviewableDocument(message.content.document)) {
      setMessageBeingPreviewed(message);
      setDocumentPreviewOpen(true);
    } else {
      doDownload(message);
    }
  }, [doDownload, setDocumentPreviewOpen, setMessageBeingPreviewed]);

  // Ugly workaround of bug where scroll anchoring not working when scroll is right at the top and we load more
  const scrollContainer = containerRef.current;
  if (scrollContainer && scrollContainer.scrollTop === 0) {
    scrollContainer.scrollTop = 1;
  }
  const scrollContainer2 = containerRef.current?.parentElement?.parentElement;
  if (scrollContainer2 && scrollContainer2.scrollTop === 0) {
    scrollContainer2.scrollTop = 1;
  }


  // We don't need to render in reverse because we don't need to start user at bottom
  for (let i = 0; i < messages.length; i++) {
    let m = messages[i];
    let element = renderMessage(m, onDocumentClick);
    if (m.seq === targetSeq) {
      element = <div className='flash' id={'previewScrollTarget'} key={'target'}>{element}</div>;
    }
    items.push(element);
  }

  useEffect(() => {
    document.getElementById('previewScrollTarget')?.scrollIntoView();
  }, []);

  return (
    <>
      {filePreviewElement}
      {messageDownloaderElement}

      <Box
        sx={{flexGrow: 1, overflowY: "auto"}}
        display='flex'
        flexDirection={'column'}
        ref={containerRef}
      >
        {
          loadingAbove ? <LoadingPlaceholderChatEntry/>
            : hasMoreAbove ? <Button onClick={onLoadMoreAbove} key={`above-${Date.now()}`}>Load more</Button>
              : <Divider sx={{color: grey[500]}}>Start of preview</Divider>
        }

        {items}

        {
          loadingBelow ? <LoadingPlaceholderChatEntry/>
            : hasMoreBelow ? <Button onClick={onLoadMoreBelow} key={`below-${Date.now()}`}>Load more</Button>
              : <Divider sx={{color: grey[500]}}>End of preview</Divider>
        }
      </Box>
    </>
  );
}

export default DetachedChat;
