import { RefObject, useEffect, useRef } from "react";
import { throttle } from "./utils";

export function useOnMount(callback: () => void) {
  // Use this when we want to run a callback only once on component mount and
  // not when state changes.

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, []);  // empty deps array is intentional
}

export function useOnUnmount(callback: () => void) {
  // Use this when we want to run a callback only once on component unmount and
  // not when state changes.

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => callback, []);  // empty deps array is intentional
}

export function useInterval(callback: () => void, delay: number | null) {
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (!delay || !callback) {
      return
    }

    const id = setInterval(callback, delay);

    return () => {
      clearInterval(id)
    }
  }, [callback, delay])
}

export function usePrevious<T>(state: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = state;
  });

  return ref.current;
}

export function useOnElementScroll(elementRef: RefObject<HTMLElement>, callback: () => void, delay: number=300) {
  useEffect(() => {
    let element: HTMLElement;
    if (!elementRef.current) {
      return;
    }

    element = elementRef.current;

    const debounced = throttle(callback, 300);
    element.addEventListener("scroll", debounced, true);

    return () => {
      if (element) {
        element.removeEventListener("scroll", debounced, true);
      }
    };
  }, [callback, elementRef]);
}

interface useOnElementSizeChangeOptions {
  triggerOnInit?: boolean,
  debounce?: number;
}
export function useOnElementSizeChange(elementRef: RefObject<HTMLElement>, callback: () => void, options?: useOnElementSizeChangeOptions) {
  const {
    triggerOnInit=false,
    debounce=300,
  } = options || {};

  useEffect(() => {
    let element: HTMLElement;
    if (!elementRef.current) {
      return;
    }

    element = elementRef.current;

    // We wrap the callback to potentially suppress triggering in several situations
    // 1. callback triggered when observation begins, which we may not want.
    // 2. resize triggered multiple times if user drags screen, so we need to throttle it
    let wrapped = throttle(callback, debounce);
    if (!triggerOnInit) {
      wrapped = skipFirstCall(wrapped);
    }

    const resizeObserver = new ResizeObserver(wrapped);
    resizeObserver.observe(element);

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
      resizeObserver.disconnect();
    };
  }, [callback, debounce, elementRef, triggerOnInit]);
}

function skipFirstCall(f: Function) {
  let seen = false;
  return () => {
    if (seen) {
      return f();
    } else {
      seen = true;
    }
  }
}
