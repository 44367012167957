import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import { differenceInCalendarDays, format } from "date-fns";
import { DocumentDTO, MessageDTO } from "../../api/dto";
import { MessageType } from "../../enums";

export function formatMessageText(message: MessageDTO) {
  switch (message.enum_message_type) {
    case MessageType.FILE:
    case MessageType.IMAGE:
    case MessageType.ANIMATION:
      return message.content.caption || (message.content.document as DocumentDTO).filename;
    default:
      return message.content.text;
  }
}

export function getMessageIcon(message: MessageDTO) {
  switch (message.enum_message_type) {
    case MessageType.IMAGE:
    case MessageType.ANIMATION:
      return InsertPhotoOutlinedIcon;
    case MessageType.FILE:
      return AttachFileOutlinedIcon;
    case MessageType.DELETED_MESSAGE:
    case MessageType.CANCEL_MEETING:
    case MessageType.EXTERNAL_MEETING_CANCEL:
    case MessageType.CREATE_BIOMETRICS_REQUEST_DELETED:
    case MessageType.CREATE_SIGNATURE_REQUEST_DELETED:
    case MessageType.CREATE_SIGNATURE_GUEST_REQUEST_DELETED:
      return NotInterestedIcon;
    case MessageType.MEETING_CREATE:
    case MessageType.EXTERNAL_MEETING_CREATE:
      return VideocamOutlinedIcon;
    case MessageType.CREATE_SIGNATURE_REQUEST:
    case MessageType.CREATE_SIGNATURE_GUEST_REQUEST:
    case MessageType.SIGN_SIGNATURE_REQUEST:
    case MessageType.SIGN_SIGNATURE_GUEST_REQUEST:
      return BorderColorOutlinedIcon;
    case MessageType.CREATE_BIOMETRICS_REQUEST:
    case MessageType.SIGN_BIOMETRICS_REQUEST:
      return FingerprintOutlinedIcon;
    default:
      return null;
  }

}

export function friendlyDate(date: Date, showTime: boolean = true) {
  // https://date-fns.org/v2.11.0/docs/format
  let days = differenceInCalendarDays(new Date(), date);
  if (days === 0) {
    return format(date, 'p');
  } else if (days === 1) {
    return "Yesterday";
  } else if (days === 2 || days === 3 || days === 4 || days === 5 || days === 6) {
    return format(date, "EEEE");  // e.g. "Monday"
  } else {
    return format(date, "P");  // localised date e.g. "05/29/2001" or "29/05/2001"
  }
}
