import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Alert, Box, Button, Card, CircularProgress, List } from "@mui/material";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography/Typography";
import React, { useCallback, useMemo, useState } from "react";
import { ChatSearchResult } from "../../api/types";
import { getSdkOptions } from "../../utils";
import ChatSearchResultItem from "./ChatSearchResultItem";
import PreviewDialog from "./PreviewDialog";

interface Props {
  hidden: boolean;
  loading: boolean;
  error?: string;
  results?: ChatSearchResult[];
  hasMore: boolean;
  onBack: () => void;
}

function ChatSearchResultContainer({hidden, results, error, loading, hasMore, onBack}: Props) {
  const {searchBackButtonLabel} = useMemo(() => getSdkOptions(), []);
  const [resultToPreview, setResultToPreview] = useState<ChatSearchResult>();

  const onSearchResultSelected = useCallback((result: ChatSearchResult) => {
    setResultToPreview(result);
  }, []);

  const handlePreviewClose = useCallback(() => {
    setResultToPreview(undefined)
  }, []);

  const content = loading ? (
    <Box textAlign={'center'}>
      <Typography color={grey[500]} marginY={2} fontSize={'0.8rem'} letterSpacing={1.1}>
        <CircularProgress color={'inherit'} size={'0.7rem'} sx={{marginRight: 0.7}}/>
        Searching ...
      </Typography>
    </Box>
  ) : error ? (
    <Box margin={3}>
      <Alert severity="error">{error}</Alert>
    </Box>
  ) : (
    <>
      <List dense disablePadding>
        {!results || results.length === 0 ? <NoResults/> : results.map(r => (
          <ChatSearchResultItem
            result={r}
            onSearchResultSelected={onSearchResultSelected}
            key={`s:${r.chat.chat_uuid}:${r.message.seq}`}
          />
        ))}
      </List>
      {hasMore ? (
        <Box
          margin={2}
          paddingX={1}
          paddingTop={2}
          borderTop={`1px dashed ${grey[400]}`}
          color={grey[500]}
        >
          <Typography fontSize={"0.9rem"}>
            Not all results are show here as you have reached the size limit.
          </Typography>
        </Box>
      ) : null}
    </>
  );

  return (
    <>
      <PreviewDialog
        open={!!resultToPreview}
        result={resultToPreview}
        onClose={handlePreviewClose}
      />
      <Box sx={{margin: 2, display: hidden ? 'none' : 'block'}}>
        <Card variant='outlined'>
          {!loading ? (
            <Box marginLeft={1}>
              <Button
                startIcon={<KeyboardBackspaceOutlinedIcon/>}
                onClick={onBack}
              >{searchBackButtonLabel}</Button>
            </Box>
          ) : null}
          {content}
        </Card>
      </Box>
    </>
  );
}

function NoResults() {
  const text = getSdkOptions().searchNoResultsMessage;
  return (
    <Box margin={3}>
      <Typography textAlign={'center'} color={grey[500]} fontSize={'0.9em'}>
        {text}
      </Typography>

    </Box>
  );
}

export default ChatSearchResultContainer;
