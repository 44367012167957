import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Alert, Box, CircularProgress, Dialog, IconButton, Slide, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { TransitionProps } from "@mui/material/transitions";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { API } from "../../../api";
import { DocumentDTO, MessageDTO } from "../../../api/dto";
import { APIBlobResponse, ErrorResponse } from "../../../api/types";
import { downloadBlob, emitPreauthorisedDownloadLink, formatFileSize, getSdkOptions } from "../../../utils";
import ImageViewer from "./ImageViewer";
import PdfViewer from "./PdfViewer";

interface Props {
  message?: MessageDTO;
  open: boolean;
  handleClose: () => void;
  onClosed: () => void;
}


export function isPreviewableDocument(document?: DocumentDTO) {
  const {imagePreview, pdfPreview} = getSdkOptions();

  return document && (
    (document.is_image && imagePreview) ||
    (document.file_type === 'application/pdf' && pdfPreview)
  );
}

function FilePreview({message, open, handleClose, onClosed}: Props) {
  const { emitDownloads } = useMemo(() => getSdkOptions(), []);

  const [cache, setCache] = useState<Blob>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const canPreview = isPreviewableDocument(message?.content.document);

  useEffect(() => {
    setCache(undefined);
    if (!message) {
      return;
    }

    if (!canPreview) {
      setError('This message cannot be previewed');
      return;
    }

    setLoading(true);
    API.getChatDocument(message.chat_uuid, message.seq)
      .then(response => {
        if ((response as ErrorResponse).isError) {
          const scenario = (response as ErrorResponse).errorScenario;
          switch (scenario) {
            case 'NoData':
              setError('This message is no longer available');
              break;
            default:
              setError('Download failed');
          }
        } else {
          setCache((response as APIBlobResponse).blob);
        }
        setLoading(false);
      })
      .catch(() => {
        setError('Download failed');
        setLoading(false);
      });

  }, [canPreview, message])

  if (!message?.content.document || !canPreview) {
    return <></>; // renders nothing until document provided
  }

  const document = message.content.document;

  const downloadClicked = () => {
    if (emitDownloads) {
      // will request for pre-authorised download link and emit it as event
      emitPreauthorisedDownloadLink(message);
    } else {
      // No need to use <MessageDownloader> since we already downloaded the blob to render preview
      if (cache) {
        downloadBlob(cache, document.filename)
      }
    }
  }


  const content = (
    loading ? <LoadingPlaceholder/>
      : error ? <ErrorPlaceholder errorText={error}/>
        : document.is_image ? <ImageViewer imageBlob={cache!} filename={document.filename} />
          : <PdfViewer pdfBlob={cache!} filename={document.filename} />
  );

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={handleClose}
      TransitionComponent={Transition}
      TransitionProps={{onExited: onClosed}}
    >
      <Box sx={{height: "100%", minWidth: '250px'}} display={'flex'} flexDirection={'column'}>
        {/* Header */}
        <Box display={'flex'} paddingTop={1} paddingBottom={1}
             sx={{borderBottom: 1, borderColor: grey[300], flexShrink: 1}}>
          <Box sx={{flexShrink: 1}} display={'flex'} alignItems={'center'} justifyContent={'center'} marginLeft={1}>
            <IconButton aria-label="Back to chat" size={'small'} onClick={handleClose}>
              <ArrowBackIosIcon/>
            </IconButton>
          </Box>
          <Box sx={{flexGrow: 1}} display={'flex'}
               justifyContent={'center'} flexDirection={'column'}>
            <Box component='div' sx={{textWrap: 'wrap', fontWeight: 'bold', wordBreak: 'break-all'}}>
              <Typography fontWeight={600} fontSize={'0.9rem'}>
                {document.filename}
              </Typography>
              <Typography fontWeight={400} fontSize={'0.8rem'}>
                {formatFileSize(document.file_size)}
                {document.width && document.height ? (` (${document.width} x ${document.height})`) : ''}
              </Typography>
            </Box>
          </Box>

          <Box sx={{flexShrink: 1}} display={'flex'} alignItems={'center'} justifyContent={'center'} marginRight={1}>
            <IconButton aria-label="Download" size={'medium'} onClick={downloadClicked} disabled={!cache}>
              <FileDownloadOutlinedIcon/>
            </IconButton>
          </Box>

        </Box>

        <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
          {content}
        </Box>
      </Box>
    </Dialog>
  )
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  // eslint-disable-next-line react/jsx-no-undef
  return <Slide direction="left" ref={ref} {...props} />;
});


function LoadingPlaceholder() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box textAlign={'center'}>
        <CircularProgress/>
        <Typography marginTop={2} textAlign={'center'} color={grey[400]}>
          Downloading ...
        </Typography>
      </Box>
    </Box>
  );
}

interface ErrorPlaceholderProps {
  errorText: string;
}

function ErrorPlaceholder({errorText}: ErrorPlaceholderProps) {
  return (
    <Box>
      <Alert severity="error">{ errorText }</Alert>
    </Box>
  );
}

export default FilePreview;
