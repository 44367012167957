import { Alert, Box, Button, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useCallback, useMemo, useReducer, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API } from "../../api";
import { ChatDTO } from "../../api/dto";
import {
  APIGetChatMessagesResponse,
  APIGetChatResponse, APISendFileMessageResponse,
  APISendTextMessageResponse,
  ErrorResponse
} from "../../api/types";
import Chat from "../../components/Chat";
import { UploadPayload } from "../../components/Chat/UploadDialog";
import MiddleOfPage from "../../components/layout/MiddleOfPage";
import { emitAppErrorMessage } from "../../controller";
import { useInterval, useOnMount } from "../../hooks";
import { getSdkOptions } from "../../utils";
import { createReplaceAction, createUpdateAction } from "./state/actions";
import { chatStateReducer } from "./state/reducer";
import { ChatStateReducer } from "./state/types";

// Max number of historical messages to load on initial load.
const MAX_MESSAGES_PER_LOAD = 50;

function LoadingChat() {
  return (
    <MiddleOfPage>
      <Box textAlign={'center'}>
        <CircularProgress/>
        <Typography color={grey[500]} marginTop={2} fontSize={'0.9rem'} letterSpacing={1.1}>
          Loading chat ...
        </Typography>
      </Box>
    </MiddleOfPage>
  )
}

function LoadError({error, onBack}: { error: string, onBack: () => void }) {
  const backButtonHidden = getSdkOptions().hideChatBack;

  return (
    <>
      <Box margin={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
      { backButtonHidden ? null : (
        <Box textAlign={'center'}>
          <Button onClick={onBack}>Back to chat list</Button>
        </Box>
      )}
    </>
  )
}

class MessageLoadError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MessageLoadError';
  }
}

async function loadMessages(chatUuid: string, fromSeq: number, toSeq: number) {
  return API.getChatMessages(chatUuid, fromSeq, toSeq).then(response => {
    if ((response as ErrorResponse).isError) {
      const scenario = (response as ErrorResponse).errorScenario;
      switch (scenario) {
        case 'InvalidAuth':
          // API handler should have already triggered nav, so we do nothing
          return [];
        case 'ChatNotVisible':
          // basic handling for now.
          emitAppErrorMessage('This chat is no longer visible');
          throw new MessageLoadError('This chat is no longer visible');
        case 'MessageNotVisible':
          // basic handling for now.
          emitAppErrorMessage('Requested messages exceeds visible range');
          throw new MessageLoadError('Requested messages exceeds visible range');
        default:
          throw new MessageLoadError('Failed to load messages');
      }
    }
    return (response as APIGetChatMessagesResponse).messages;
  })
}


async function loadMessagesUpToSeq(chatUuid: string, toSeq: number) {
  const fromSeq = Math.max(0, toSeq - MAX_MESSAGES_PER_LOAD + 1);
  return loadMessages(chatUuid, fromSeq, toSeq);
}

function ChatScreen() {
  const navigate = useNavigate();

  const {chatUuid} = useParams();
  if (!chatUuid) {
    throw new Error('ChatScreen somehow loaded with no chatUuid');
  }

  const { state: locationState } = useLocation();

  const [state, dispatch] = useReducer<ChatStateReducer>(chatStateReducer, {
    chat: locationState?.chat,
    messages: locationState?.chat ? [locationState.chat.last_message] : [],
  });

  // Even if chat data passed in, we still load latest chat data since we're not RT yet and
  // chatlist might be showing outdated data when it was clicked.
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [lastAckMsgSeq, setLastAckMsgSeq] = useState<number>();

  const ackLatestIfActive = useCallback(() => {
    if (!document.hasFocus() || state.messages.length === 0) {
      return;
    }

    // find the newest msg_seq. Not all messages have msg_seq e.g. system messages.
    let latestMsgSeq: number | undefined;
    for (let i = state.messages.length - 1; i > 0; i--) {
      if (state.messages[i].msg_seq !== undefined) {
        latestMsgSeq = state.messages[i].msg_seq as number;
        break;
      }
    }
    if (latestMsgSeq && (!lastAckMsgSeq || lastAckMsgSeq < latestMsgSeq)) {
      API.ackMessage(chatUuid, latestMsgSeq).then(() => {
        setLastAckMsgSeq(latestMsgSeq);
      });
    }

  }, [chatUuid, lastAckMsgSeq, state.messages]);

  const onBack = useCallback(() => {
    navigate('/chats');
  }, [navigate]);

  const onLoadMoreOlder = useCallback(() => {
    const oldestSeq = state.messages[0].seq;
    if (oldestSeq === 0) {
      return;
    }

    setLoading(true);
    loadMessagesUpToSeq(chatUuid, oldestSeq - 1).then(messages => {
      dispatch(createUpdateAction({messages}));
      setLoading(false);
    }).catch((err: Error) => {
      if (err.name === 'MessageLoadError') {
        setLoading(false);
        setError(err.message);
      } else {
        throw err;
      }
    });
  }, [chatUuid, state.messages]);

  const catchupAfterSend = useCallback((prevSeq: number, chat: ChatDTO) => {
    const messages = [chat.last_message];
    dispatch(createUpdateAction({chat, messages}));

    // check if there were new messages since last load and when we send
    const latestSeq = chat.last_seq;
    const delta = latestSeq - prevSeq - 1;
    if (delta < 1) {
      setLoading(false);
    } else if (delta > MAX_MESSAGES_PER_LOAD) {
      console.debug('Too far behind. reloading chat.');
      // We've missed to many messages to catch up. Just reload from latest and replace
      loadMessagesUpToSeq(chat.chat_uuid, chat.last_seq).then(messages => {
        dispatch(createReplaceAction({messages}));
        setLoading(false);
      }).catch((err: Error) => {
        if (err.name === 'MessageLoadError') {
          setLoading(false);
          setError(err.message);
        } else {
          throw err;
        }
      });
    } else {
      let toSeq = latestSeq - 1;
      let fromSeq = Math.max(prevSeq, toSeq - MAX_MESSAGES_PER_LOAD + 1);
      console.debug(`Catching up missed messages ${fromSeq} -> ${toSeq}`);
      loadMessages(chat.chat_uuid, fromSeq, toSeq).then(messages => {
        dispatch(createUpdateAction({chat, messages}));
        setLoading(false);
      }).catch((err: Error) => {
        if (err.name === 'MessageLoadError') {
          setLoading(false);
          setError(err.message);
        } else {
          throw err;
        }
      });
    }
  }, []);

  const onUploadSend = useCallback(async (payload: UploadPayload) => {
    const {sendAs, filename, data, caption} = payload;

    if (!state.chat) {
      throw new Error('Send triggered while no chat data'); // should not happen
    }

    const prevSeq = state.chat.last_seq;
    setLoading(true);
    let sendFunc = {
      file: API.sendFileMessage,
      image: API.sendImageMessage,
      animation: API.sendAnimationMessage,
    }[sendAs];

    if (!sendFunc) {
      throw new Error(`invalid "sendAs" -- ${sendAs}`);
    }

    return sendFunc(chatUuid, caption, data, filename).then(async response => {
      if ((response as ErrorResponse).isError) {
        const scenario = (response as ErrorResponse).errorScenario;
        switch (scenario) {
          case 'InvalidAuth':
            // API handler should have already triggered nav, so we do nothing
            return {success: false};
          case 'ChatNotVisible':
            setError('This chat is no longer visible');
            emitAppErrorMessage('This chat is no longer visible');
            return {success: false, errorMessage: 'This chat is no longer visible'};
          case 'CannotSendToThisChat':
            emitAppErrorMessage('You can no longer send messages to this chat');
            return {success: false, errorMessage: 'You can no longer send messages to this chat'};
          case 'TooBig':
            return {success: false, errorMessage: 'File rejected: size or dimensions too big'};
          case 'NoData':
            return {success: false, errorMessage: 'File rejected: it appears to be empty'};
          case 'BadData':
            return {success: false, errorMessage: 'File rejected: it appears to be corrupted'};
          case 'InvalidFileType':
            return {success: false, errorMessage: 'File rejected: file type is not allowed by you organisation'};
          case 'PasswordProtected':
            return {success: false, errorMessage: 'File rejected: password protected file rejected by you organisation'};
          case 'Antivirus':
            return {success: false, errorMessage: 'File rejected: flagged by antivirus service'};
          default:
            return {success: false};
        }
      }

      catchupAfterSend(prevSeq, (response as APISendFileMessageResponse).chat)

      return {success: true}; // indicate send successful, so input box can be cleared
    });
  }, [catchupAfterSend, chatUuid, state.chat]);

  const onSend = useCallback(async (value: string) => {
    if (!state.chat) {
      throw new Error('Send triggered while no chat data'); // should not happen
    }

    const prevSeq = state.chat.last_seq;
    setLoading(true);
    return API.sendTextMessage(chatUuid, value).then(async response => {
      if ((response as ErrorResponse).isError) {
        const scenario = (response as ErrorResponse).errorScenario;
        switch (scenario) {
          case 'InvalidAuth':
            // API handler should have already triggered nav, so we do nothing
            return false;
          case 'ChatNotVisible':
            emitAppErrorMessage('This chat is no longer visible');
            setError('This chat is no longer visible');
            return false;
          case 'CannotSendToThisChat':
            emitAppErrorMessage('Cannot send message to this chat');
            return false;
          default:
            setError('Message send failed');
            return false;
        }
      }

      catchupAfterSend(prevSeq, (response as APISendTextMessageResponse).chat)

      return true; // indicate send successful, so input box can be cleared
    })
  }, [catchupAfterSend, chatUuid, state.chat]);

  const loadChat = async (uuid: string) => {
    return API.getChat(uuid)
      .then(async response => {
        if ((response as ErrorResponse).isError) {
          const scenario = (response as ErrorResponse).errorScenario;
          switch (scenario) {
            case 'InvalidAuth':
              // API handler should have already triggered nav, so we do nothing
              return;
            case 'ChatNotVisible':
              // basic handling for now.
              emitAppErrorMessage('This chat does not exist or is no longer visible');
              setError('This chat does not exist or is no longer visible');
              return;
            default:
              setError('Failed to load chat.');
              return;
          }
        }
        return (response as APIGetChatResponse).chat;
      });
  }

  useOnMount(() => {
    // On first render, we load latest chat data and load messages backwards from latest_seq
    loadChat(chatUuid).then((chat) => {
      if (!chat) {
        setLoading(false);
        return; // Load error. This would have been handled in loadChat()
      }

      loadMessagesUpToSeq(chatUuid, chat.last_seq).then(messages => {
        dispatch(createUpdateAction({chat, messages}));
        setLoading(false);
      }).catch((err: Error) => {
        if (err.name === 'MessageLoadError') {
          setLoading(false);
          setError(err.message);
        } else {
          throw err;
        }
      });
    })
  })

  // detect updates
  const pollingFrequency = useMemo(() => getSdkOptions().pollingFrequency, [])
  useInterval(() => {
    if (loading || !state.chat) {
      return;
    }

    // If document is hidden (not the same as !focused), then we pause polling.
    if (document.hidden) {
      return;
    }

    ackLatestIfActive();

    const prevSeq = state.chat.last_seq;
    const prevUpdatedSeq = state.chat.updated_seq;
    loadChat(chatUuid!).then(chat => {
      if (!chat) {
        return; // error
      }

      const latestSeq = chat.last_seq;
      if (latestSeq > prevSeq || chat.updated_seq > prevUpdatedSeq) {
        // update chat
        dispatch(createUpdateAction({chat}));

        // load more
        if (latestSeq > prevSeq) {
          loadMessages(chat.chat_uuid, prevSeq + 1, latestSeq)
            .then(messages => {
              dispatch(createUpdateAction({messages}));
            }).catch((err: Error) => {
            if (err.name === 'MessageLoadError') {
              setError(err.message);
            } else {
              throw err;
            }
          });
        }
      }
    });

  }, error ? null : pollingFrequency * 1000);

  if (error) {
    return <LoadError error={error} onBack={onBack}/>;
  } else if (!state.chat) {
    return <LoadingChat/>;
  } else {
    return <Chat
      chat={state.chat}
      messages={state.messages}
      onBack={onBack}
      loading={loading}
      onLoadMoreOlder={onLoadMoreOlder}
      onSend={onSend}
      onUploadSend={onUploadSend}
    />;
  }


}

export default ChatScreen;
