import styled from "@emotion/styled";
import { colors } from "../../colors";
import spacing from "../../spacing";
import LogoImg from "./logo.jpg";
import LogoImg2x from "./logo_2x.jpg";
import LogoImg3x from "./logo_3x.jpg";

type Props = {
  title?: string;
};

const QwilLogoContainer = styled.div`
  text-align: center;
`;

const LogoImage = styled.img`
  border-radius: 12%;
  margin: 0 auto;
`;

const LogoLabel = styled.p`
  text-align: center;
  font-weight: 500 !important;
  font-size: 1rem;
  letter-spacing: 2px;
  color: ${colors.logoTextColor};
  margin: ${spacing.xs};
  white-space: nowrap;
`;

function QwilLogo({ title='QWIL MESSENGER'} : Props) {
  return (
    <QwilLogoContainer>
      <LogoImage
        src={LogoImg}
        srcSet={`${LogoImg2x} 2x, ${LogoImg3x} 3x`}
        alt="Qwil Messenger"
        style={{ minHeight: 90 }}
      />
      <LogoLabel>{ title }</LogoLabel>
    </QwilLogoContainer>
  );
}

export default QwilLogo;
