import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import MiddleOfPage from "../../components/layout/MiddleOfPage";

function Error404() {
  return (
    <MiddleOfPage>
      <Typography variant={"h1"} color={grey[400]}>
        404
      </Typography>
    </MiddleOfPage>
  )
}

export default Error404;
