import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../api";
import { ChatDTO } from "../../api/dto";
import { APIGetContactListResponse, ErrorResponse } from "../../api/types";
import ContactList from "../../components/ContactList";
import MiddleOfPage from "../../components/layout/MiddleOfPage";
import { emitAppErrorMessage } from "../../controller";

function LoadingContacts() {
  return (
    <MiddleOfPage>
      <Box textAlign={'center'}>
        <CircularProgress/>
        <Typography color={grey[500]} marginTop={2} fontSize={'0.9rem'} letterSpacing={1.1}>
          Loading contacts ...
        </Typography>
      </Box>
    </MiddleOfPage>
  )
}

function LoadError({error}: { error: string }) {
  return (
    <>
      <Box margin={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    </>
  )
}

function ContactListScreen() {
  const navigate = useNavigate();

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<APIGetContactListResponse>();

  const doLoadContacts = useCallback(async () => {
    const response = await API.getContactList();

    if ((response as ErrorResponse).isError) {
      emitAppErrorMessage('Failed to load list of contacts');
      setError("Failed to load list of contacts");
    } else {
      setResult(response as APIGetContactListResponse);
    }
  }, []);

  useEffect(() => {
    doLoadContacts().then(() => setLoading(false));
  }, [doLoadContacts]);

  const onReload = useCallback(() => {
    if (!loading) {
      setLoading(true);
      doLoadContacts().then(() => setLoading(false));
    }
  }, [doLoadContacts, loading]);

  const onChatCreated = useCallback((chat: ChatDTO) => {
    navigate(`/chats/${chat.chat_uuid}`, {state: {chat}});
  }, [navigate])

  if (loading && !result) {
    return <LoadingContacts />;
  } else if (error) {
    return <LoadError error={error}/>;
  } else {
    return (
      <ContactList
        contacts={result!.contacts}
        entity={result!.entity}
        hasMore={result!.has_more}
        onReload={onReload}
        onChatCreated={onChatCreated}
      />
    )
  }
}

export default ContactListScreen;
