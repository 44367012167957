import { Box, BoxProps, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React from "react";
import { EntityUserDTO, MeetingDTO, MessageDTO } from "../../../api/dto";
import typography from "../../../typography";
import UserAvatar from "../../UserAvatar";
import MeetingJoinButton from "../MeetingJoinButton";
import BaseChatEntry from "./BaseChatEntry";

type Props = {
  date: Date;
  text: string;
  message: MessageDTO;
  meeting: MeetingDTO;
  author: EntityUserDTO;
}

function MeetingChatEntry({date, text, author, meeting, message}: Props) {

  const avatar = (
    <UserAvatar
      firstName={author.first_name}
      lastName={author.last_name}
      entityUserXrefUuid={author.entity_user_xref_uuid}
    />
  );

  const positionProps: BoxProps = author.me ? {
    flexDirection: 'row-reverse',
    marginLeft: 'auto',
  } : {
    marginRight: 'auto',
  }

  const textPositionProps: BoxProps = author.me ? {
    textAlign: 'right'
  } : {};

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <BaseChatEntry
      avatar={avatar}
      date={date}
      position={author.me ? 'right' : 'left'}
      name={author.me ? 'You' : `${author.first_name} ${author.last_name}`}
    >
      <Box marginBottom={1} display={'flex'} flexDirection={'column'} {...positionProps}>
        <Box {...textPositionProps}>
          <Box paddingTop={1}>
            <Typography
              fontSize={typography.sm}
              color={blueGrey[700]}
              margin={0}
            >
              {text}
            </Typography>
          </Box>
          <Box marginTop={1}>
            <MeetingJoinButton meeting={meeting} message={message} />
          </Box>
        </Box>
      </Box>
    </BaseChatEntry>
  );
}

export default MeetingChatEntry;
