import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import React, { ChangeEvent, useCallback, useEffect, useReducer, useState } from "react";
import { usePrevious } from "../../../hooks";
import { downscaleImage, getImageDimensions, isGifFilename, isImageFilename } from "../../../imageUtils";
import { formatFileSize, getFileExtension } from "../../../utils";
import ExtensionBasedIcon from "../../ExtensionBasedIcon";
import {
  createUploaderResetAction, createUploaderUpdateAction,
  uploaderDialogDefaultState,
  uploaderDialogReducer,
  UploaderDialogReducer, UploaderDialogState,
  UploadPayloadType
} from "./state";


export interface UploadPayload {
  data: Blob | File;
  filename: string;
  sendAs: UploadPayloadType;
  caption: string;
}

export interface UploadResult {
  success: boolean;
  errorMessage?: string;
}

interface Props {
  handleSend: (payload: UploadPayload) => Promise<UploadResult>;
  handleDialogClose: () => void;
  uploaded?: File;
  getInitialCaption: () => string;
}

function UploadDialog({handleSend, uploaded, getInitialCaption, handleDialogClose}: Props) {
  // use reducer so we can do atomic updates of multiple state values
  const [state, dispatch] = useReducer<UploaderDialogReducer>(uploaderDialogReducer, uploaderDialogDefaultState);

  // track previous "uploaded" so we know if render was due to new upload or just rerender
  const prevUploaded = usePrevious(uploaded);

  const dispatchStateUpdate = useCallback((updates: Partial<UploaderDialogState>) => {
    // mutate existing state with updates
    dispatch(createUploaderUpdateAction(updates));
  }, []);

  const dispatchStateReset = useCallback((updates?: Partial<UploaderDialogState>) => {
    // rollback to default state and apply updates on top if provided
    dispatch(createUploaderResetAction(updates));
  }, []);

  const canSend = uploaded && !state.processing && !state.processingFailed && !state.loading;

  useEffect(() => {
    if (!uploaded) {
      dispatchStateReset();
      return;
    }

    if (uploaded.name === prevUploaded?.name) {
      // do nothing if triggered on same file
      return;
    }

    const newState: Partial<UploaderDialogState> = {};

    // Reset states each time new file uploaded
    const isImage = uploaded && isImageFilename(uploaded.name);
    const isGif = uploaded && isGifFilename(uploaded.name);
    newState.isImage = isImage;
    newState.isGif = isGif;
    newState.sendAs = isGif ? 'animation' : isImage ? 'image' : 'file'

    const initialCaption = getInitialCaption()
    newState.caption = initialCaption;
    newState.captionTrimmed = initialCaption.trim();

    if (isGif) {
      // Don't actually compress gif. But we use the same placeholders to display
      newState.compressed = uploaded;
      newState.compressedFilename = uploaded.name;

      getImageDimensions(uploaded).then(({width, height}) => {
        dispatchStateUpdate({
          originalDimensions: `(${width} x ${height})`,
          newDimensions: `(${width} x ${height})`,
        });
      });

    } else if (isImage) {
      newState.processing = true
      downscaleImage(uploaded).then(
        ({blob, newHeight, newWidth, originalHeight, originalWidth, newFilename}) => {
          dispatchStateUpdate({
            processing: false,
            compressed: blob,
            compressedFilename: newFilename,
            originalDimensions: `(${originalWidth} x ${originalHeight})`,
            newDimensions: `(${newWidth} x ${newHeight})`,
          });
        },
        (err) => {
          console.error(err);
          dispatchStateUpdate({
            processing: false,
            processingFailed: true,
          });
        },
      );
    }

    dispatchStateReset(newState);

  }, [dispatchStateReset, dispatchStateUpdate, getInitialCaption, prevUploaded?.name, uploaded]);


  const onTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatchStateUpdate({
      caption: event.target.value,
      captionTrimmed: event.target.value.trim(),
    });
  }, [dispatchStateUpdate]);

  const onSend = useCallback(async () => {
    if (!uploaded) {
      console.error('send clicked when not yet ready'); // should not happen
      return;
    }

    let data: File | Blob = uploaded;
    let filename = uploaded.name;
    if (state.sendAs === 'image') {
      if (!state.compressed) {
        console.error('send clicked when not yet ready'); // should not happen
        return;
      }
      // send compressed version
      data = state.compressed;
      filename = state.compressedFilename;
    }

    const payload: UploadPayload = {
      data,
      filename,
      sendAs: state.sendAs,
      caption: state.captionTrimmed,
    }

    dispatchStateUpdate({loading: true});

    handleSend(payload).then(({success, errorMessage}) => {
      dispatchStateUpdate({
        loading: false,
        error: success ? '' : errorMessage || 'Failed to send message'
      });
    });

  }, [dispatchStateUpdate, handleSend, state, uploaded]);

  return (
    <Dialog
      open={!!uploaded}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <DialogContent>
        <Box textAlign={'center'} marginBottom={2}>
          {state.isImage? (
            <ToggleButtonGroup
              size={"small"}
              color="primary"
              value={state.sendAs}
              exclusive
              onChange={(_, v) => (v && dispatchStateUpdate({sendAs: v}))}
              aria-label="Send as"
            >
              {state.isGif ? (
                <ToggleButton value="animation">
                  <Box paddingX={1}>
                    Send as image
                  </Box>
                </ToggleButton>
              ) : (
                <ToggleButton value="image">
                  <Box paddingX={1}>
                    Send as image (compressed)
                  </Box>
                </ToggleButton>
              )}

              <ToggleButton value="file">
                <Box paddingX={1}>
                  Send as file
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>
          ) : (
            <Typography fontSize={"1.1em"} fontWeight={400}>
              Send uploaded file
            </Typography>
          )}

        </Box>
        <Box sx={{minWidth: '150px'}}>
          <Box
            marginX={1}
            marginBottom={3}
          >
            {state.sendAs === 'file' ? (
              <FileTag
                filename={uploaded?.name || ""}
                fileSize={uploaded?.size || 0}
                imageDimensions={state.originalDimensions}
              ></FileTag>
            ) : (
              <ImagePreview
                filename={state.compressedFilename}
                imageBlob={state.compressed}
                processingFailed={state.processingFailed}
                imageDimensions={state.newDimensions}
              />
            )}
          </Box>

          <Box sx={{flexGrow: 1}}>
            <TextField
              placeholder="Caption"
              maxRows={4} size={'small'}
              value={state.caption}
              multiline
              fullWidth
              disabled={state.loading}
              onChange={onTextChange}
              inputProps={{maxLength: 5000}}
            />
          </Box>
        </Box>

        {state.error ? (
          <Box marginTop={2}>
            <Alert severity={'error'}>{state.error}</Alert>
          </Box>
        ) : null}

      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDialogClose}>Cancel</Button>
        <Button variant="contained" onClick={onSend} autoFocus disabled={!canSend}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type ImagePreviewProps = {
  filename: string;
  imageBlob?: Blob;
  processingFailed: boolean;
  imageDimensions: string;
}

function ImagePreview({filename, imageBlob, processingFailed, imageDimensions}: ImagePreviewProps) {

  const [src, setSrc] = useState('');
  const [formattedFileSize, setFormattedFileSize] = useState('');
  useEffect(() => {
    if (!imageBlob) {
      return;
    }

    const url = URL.createObjectURL(imageBlob);
    setSrc(url);
    setFormattedFileSize(formatFileSize(imageBlob.size))

    return () => URL.revokeObjectURL(url);
  }, [imageBlob]);

  if (!imageBlob) {
    return (
      <Box
        bgcolor={grey[100]}
        borderRadius={'0.25em'}
        height={'100px'}
        color={grey[400]}
        display={'flex'}
        alignItems="center"
        justifyContent="center"
      >
        {processingFailed ? (
          <Box color={red[400]}>
            Failed to load image
          </Box>
        ) : <CircularProgress color={"inherit"}/>}

      </Box>
    );
  } else {
    return (
      <>
        <Box
          bgcolor={grey[100]}
          borderRadius={'0.25em'}
          color={grey[400]}
          display={'flex'}
          alignItems="center"
          justifyContent="center"
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <img alt={filename} src={src} width={"100%"} height={"100%"} style={{objectFit: 'contain'}}/>
        </Box>
        <Box
          flexGrow={1}
          display={'flex'}
          flexDirection={'column'}
          sx={{overflow: 'hidden', wordBreak: 'break-word',}}
        >
          <Typography
            fontWeight={500}
            fontSize={'0.9rem'}
            sx={{
              overflow: 'hidden'
            }}
          >
            {filename}
          </Typography>

          <Typography
            fontWeight={400}
            fontSize={'0.75rem'}
            color={grey[500]}
            sx={{
              textWrap: "nowrap",
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}
          >
            {formattedFileSize} {imageDimensions}
          </Typography>
        </Box>
      </>
    );
  }

}

type FileTagProps = {
  filename: string;
  fileSize: number;
  imageDimensions: string;
}

function FileTag({fileSize, filename, imageDimensions}: FileTagProps) {
  const formattedFileSize = formatFileSize(fileSize);
  const extension = getFileExtension(filename);

  return (
    <Box
      padding={1}
      borderRadius={'0.25em'}
      bgcolor={grey[100]}
      display={'flex'}
      flexDirection={'row'}
      sx={{cursor: 'pointer', overflow: 'hidden'}}
    >
      <Box
        display={'flex'}
        flexShrink={0}
        justifyContent={'center'}
        alignItems={'center'}
        marginRight={1}
      >
        <ExtensionBasedIcon extension={extension}/>
      </Box>
      <Box
        flexGrow={1}
        display={'flex'}
        flexDirection={'column'}
        sx={{overflow: 'hidden', wordBreak: 'break-word',}}
      >
        <Typography
          fontWeight={500}
          fontSize={'0.9rem'}
          sx={{
            overflow: 'hidden'
          }}
        >
          {filename}
        </Typography>

        <Typography
          fontWeight={400}
          fontSize={'0.75rem'}
          color={grey[500]}
          sx={{
            textWrap: "nowrap",
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {formattedFileSize} {imageDimensions}
        </Typography>
      </Box>
    </Box>
  );
}

export default UploadDialog;
