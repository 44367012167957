import { signalAuthExpired } from "../controller";
import { navToAuthErrPage } from "../router";
import { getAuthProps } from "./auth";
import { asPromise, createCorrelationId, createErrorMap, fetchAndParse } from "./common";
import { ERROR_CODES } from "./errors";
import {
  APIAckMessageResponse,
  APIBlobResponse,
  APICreateChatResponse,
  APIGetChatDocumentDownloadLinkResponse,
  APIGetChatListResponse,
  APIGetChatMessagesResponse,
  APIGetChatResponse,
  APIGetContactInfoResponse,
  APIGetContactListResponse,
  APIMeetingJoinResponse,
  ApiQueryProps,
  APISearchChatResponse,
  APISendFileMessageResponse,
  APISendTextMessageResponse,
  ErrorResponse
} from "./types";


async function _call<T>(props: ApiQueryProps): Promise<T | ErrorResponse> {
  const {
    path,
    method,
    payload,
    formData,
    errorMap,
    signal,
    successStatus = [200],
    expectBlobResponse = false,
  } = props;

  const authProps = getAuthProps();
  if (!authProps) {
    signalAuthExpired();  // TODO: revisit this.
    await navToAuthErrPage();
    return asPromise<ErrorResponse>({isError: true, errorScenario: "NoAuth"});
  }

  return fetchAndParse<T>({
    url: authProps.endpoint + path,
    method,
    payload,
    formData,
    successStatus,
    errorMap,
    signal,
    expectBlobResponse,
    headers: authProps.authHeader
  });
}

// async function ping() {
//   return _call<APIPingResponse>({
//     path: '/chat-service/sdk/chats/ping',
//     method: 'GET',
//     errorMap: createErrorMap({
//       InvalidAuth: ERROR_CODES_IF_USER_CANNOT_ACCESS,
//       OrgCannotUseSDK: ERROR_CODES_ORG_CANNOT_USE_SDK,
//     }),
//   })
// }

async function createChat(title: string, participantEuxus: string[], firstMessage: string) {
  const cleanedTitle = title.trim().substring(0, 5000);
  const cleanedFirstMessage = firstMessage.trim().substring(0, 5000);

  return _call<APICreateChatResponse>({
    path: `/chat-service/sdk/chats/create`,
    method: 'POST',
    payload: {
      title: cleanedTitle,
      text: cleanedFirstMessage,
      entity_user_xref_uuids: participantEuxus,
      correlation_id: createCorrelationId(),
    },
    errorMap: createErrorMap({
      InvalidContact: [
        ERROR_CODES.USER_IS_NOT_CONTACT,
        ERROR_CODES.EUXU_NOT_FOUND,
      ],
      ChatNotVisible: [
        ERROR_CODES.CHAT_INACTIVE,
        ERROR_CODES.CHAT_MISSING,
        ERROR_CODES.CHAT_NOT_VISIBLE,
        ERROR_CODES.CHAT_MEMBER_NOT_VISIBLE,
      ],
      BadData: [
        ERROR_CODES.VALIDATION_ERROR,
        ERROR_CODES.EMPTY_TEXT,
        ERROR_CODES.DUPLICATE_EUXU,
        ERROR_CODES.TOO_MANY_PARTICIPANTS,
        ERROR_CODES.TOO_MANY_BOTS,
        ERROR_CODES.EUXU_IS_SELF,
      ],
    }),
  })
}

async function getChat(chatUuid: string) {
  return _call<APIGetChatResponse>({
    path: `/chat-service/sdk/chats?chat_uuid=${chatUuid}`,
    method: 'GET',
    errorMap: createErrorMap({
      ChatNotVisible: [
        ERROR_CODES.CHAT_MISSING,
        ERROR_CODES.CHAT_MEMBER_NOT_VISIBLE,
        ERROR_CODES.CHAT_NOT_VISIBLE,
      ]
    }),
  })
}

async function getChatMessages(chatUuid: string, fromSeq: number, toSeq: number) {
  return _call<APIGetChatMessagesResponse>({
    path: `/chat-service/sdk/chats/messages/range?chat_uuid=${chatUuid}&from_seq=${fromSeq}&to_seq=${toSeq}`,
    method: 'GET',
    errorMap: createErrorMap({
      ChatNotVisible: [
        ERROR_CODES.CHAT_MISSING,
        ERROR_CODES.CHAT_MEMBER_NOT_VISIBLE,
        ERROR_CODES.CHAT_NOT_VISIBLE,
      ],
      MessageNotVisible: [
        ERROR_CODES.MESSAGE_SEQ_NOT_ACCESSIBLE_TO_USER,
        ERROR_CODES.MESSAGE_SEQ_EXCEED_ALLOWED_RANGE,
        ERROR_CODES.MESSAGE_SEQ_EXCEED_AVAILABLE_RANGE,
      ]
    }),
  })
}

async function getChatList() {
  return _call<APIGetChatListResponse>({
    path: '/chat-service/sdk/chats/list',
    method: 'GET',
    errorMap: createErrorMap({}),
  })
}

async function searchChats(search: string) {
  const cleaned = encodeURIComponent(search.trim().substring(0, 5000));

  return _call<APISearchChatResponse>({
    path: `/chat-service/sdk/chats/search?search=${cleaned}`,
    method: 'GET',
    errorMap: createErrorMap({
      BadData: [
        ERROR_CODES.VALIDATION_ERROR,
        ERROR_CODES.EMPTY_TEXT,
      ],
    }),
  })
}

async function getChatParticipantAvatar(entityUserXrefUuid: string) {
  return _call<APIBlobResponse>({
    path: `/chat-service/sdk/chats/participants/avatar?entity_user_xref_uuid=${entityUserXrefUuid}`,
    method: 'GET',
    errorMap: createErrorMap({
      NoData: [
        ERROR_CODES.NO_COMMON_CHAT,
        ERROR_CODES.USER_HAS_NO_AVATAR,
      ],
    }),
    expectBlobResponse: true,
  })
}

async function getChatDocumentDownloadLink(chatUuid: string, seq: number) {
  return _call<APIGetChatDocumentDownloadLinkResponse>({
    path: `/chat-service/sdk/chats/document/link?chat_uuid=${chatUuid}&seq=${seq}`,
    method: 'GET',
    errorMap: createErrorMap({
      NoData: [
        ERROR_CODES.MESSAGE_HAS_NO_DOCUMENT,
        ERROR_CODES.MESSAGE_DELETED,
        ERROR_CODES.MESSAGE_SEQ_NOT_ACCESSIBLE_TO_USER,
        ERROR_CODES.CHAT_MEMBER_NOT_VISIBLE,
        ERROR_CODES.CHAT_MISSING,
        ERROR_CODES.CHAT_NOT_VISIBLE,
      ],
    }),
  })
}


async function getChatDocument(chatUuid: string, seq: number, signal?: AbortSignal) {
  return _call<APIBlobResponse>({
    path: `/chat-service/sdk/chats/document?chat_uuid=${chatUuid}&seq=${seq}&location=inline`,
    method: 'GET',
    errorMap: createErrorMap({
      NoData: [
        ERROR_CODES.MESSAGE_HAS_NO_DOCUMENT,
        ERROR_CODES.MESSAGE_DELETED,
        ERROR_CODES.MESSAGE_SEQ_NOT_ACCESSIBLE_TO_USER,
        ERROR_CODES.CHAT_MEMBER_NOT_VISIBLE,
        ERROR_CODES.CHAT_MISSING,
        ERROR_CODES.CHAT_NOT_VISIBLE,
      ],
    }),
    signal: signal,
    expectBlobResponse: true,
  })
}


async function sendTextMessage(chatUuid: string, text: string) {
  const cleaned = text.trim().substring(0, 5000);

  return _call<APISendTextMessageResponse>({
    path: `/chat-service/sdk/chats/messages/send-text`,
    method: 'POST',
    payload: {
      text: cleaned,
      chat_uuid: chatUuid,
      correlation_id: createCorrelationId(),
    },
    errorMap: createErrorMap({
      // Note: more errors need to be handled when we do support reply-to, mentions, etc
      ChatNotVisible: [
        ERROR_CODES.CHAT_INACTIVE,
        ERROR_CODES.CHAT_MISSING,
        ERROR_CODES.CHAT_NOT_VISIBLE,
        ERROR_CODES.CHAT_MEMBER_NOT_VISIBLE,
      ],
      CannotSendToThisChat: [
        ERROR_CODES.CHAT_RESTRICTED,
        ERROR_CODES.CHAT_MEMBER_NOT_ACTIVE,
      ],
    }),
  })
}

const fileUploadErrorMap = createErrorMap({
  // Note: more errors need to be handled when we do support reply-to, mentions, etc
  ChatNotVisible: [
    ERROR_CODES.CHAT_INACTIVE,
    ERROR_CODES.CHAT_MISSING,
    ERROR_CODES.CHAT_NOT_VISIBLE,
    ERROR_CODES.CHAT_MEMBER_NOT_VISIBLE,
  ],
  CannotSendToThisChat: [
    ERROR_CODES.CHAT_RESTRICTED,
    ERROR_CODES.CHAT_MEMBER_NOT_ACTIVE,
  ],
  TooBig: [
    ERROR_CODES.IMAGE_TOO_BIG,
    ERROR_CODES.IMAGE_DIMENSIONS_TOO_BIG,
    ERROR_CODES.DOCUMENT_TOO_BIG,
  ],
  NoData: [
    ERROR_CODES.DOCUMENT_EMPTY,
  ],
  BadData: [
    ERROR_CODES.NOT_IMAGE,
    ERROR_CODES.CORRUPTED_IMAGE,
    ERROR_CODES.MIMETYPE_MISMATCH,
  ],
  InvalidFileType: [
    ERROR_CODES.REJECTED_EXTENSION,
    ERROR_CODES.REJECTED_MIMETYPE,
    ERROR_CODES.REJECTED_IMAGE_MIMETYPE,
  ],
  PasswordProtected: [
    ERROR_CODES.PASSWORD_PROTECTED,
  ],
  Antivirus: [
    ERROR_CODES.REFUSED_BY_ANTIVIRUS,
  ],
});

async function sendImageMessage(chatUuid: string, caption: string, image: Blob | File, filename: string) {
  const cleaned = caption.trim().substring(0, 5000);
  const formData = new FormData();
  formData.append('image', image, filename);
  formData.append('chat_uuid', chatUuid);
  formData.append('correlation_id', createCorrelationId());
  if (cleaned) {
    formData.append('caption', caption);
  }


  return _call<APISendFileMessageResponse>({
    path: `/chat-service/sdk/chats/messages/send-image`,
    method: 'POST',
    formData,
    errorMap: fileUploadErrorMap,
  })
}

async function sendFileMessage(chatUuid: string, caption: string, file: Blob | File, filename: string) {
  const cleaned = caption.trim().substring(0, 5000);
  const formData = new FormData();
  formData.append('file', file, filename);
  formData.append('chat_uuid', chatUuid);
  formData.append('correlation_id', createCorrelationId());
  if (cleaned) {
    formData.append('caption', caption);
  }

  return _call<APISendFileMessageResponse>({
    path: `/chat-service/sdk/chats/messages/send-file`,
    method: 'POST',
    formData,
    errorMap: fileUploadErrorMap,
  });
}

async function sendAnimationMessage(chatUuid: string, caption: string, gif: Blob | File, filename: string) {
  const cleaned = caption.trim().substring(0, 5000);
  const formData = new FormData();
  formData.append('gif', gif, filename);
  formData.append('chat_uuid', chatUuid);
  formData.append('correlation_id', createCorrelationId());
  if (cleaned) {
    formData.append('caption', caption);
  }

  return _call<APISendFileMessageResponse>({
    path: `/chat-service/sdk/chats/messages/send-animation`,
    method: 'POST',
    formData,
    errorMap: fileUploadErrorMap,
  });
}


async function joinMeeting(chatUuid: string, seq: number) {
  return _call<APIMeetingJoinResponse>({
    path: `/chat-service/sdk/chats/meetings/join`,
    method: 'POST',
    payload: {
      chat_uuid: chatUuid,
      seq: seq,
    },
    errorMap: createErrorMap({
      ChatNotVisible: [
        ERROR_CODES.CHAT_INACTIVE,
        ERROR_CODES.CHAT_MISSING,
        ERROR_CODES.CHAT_NOT_VISIBLE,
        ERROR_CODES.CHAT_MEMBER_NOT_VISIBLE,
      ],
      MeetingNotJoinable: [
        ERROR_CODES.MESSAGE_DELETED,
        ERROR_CODES.MEETING_EXPIRED,
        ERROR_CODES.NOT_MEETING_MESSAGE,
        ERROR_CODES.CHAT_MEMBER_NOT_ACTIVE,
        ERROR_CODES.MEETINGS_DISABLED_FOR_ORG,
      ]
    }),
  })
}

async function ackMessage(chatUuid: string, msgSeq: number) {
  return _call<APIAckMessageResponse>({
    path: `/chat-service/sdk/chats/messages/ack`,
    method: 'POST',
    payload: {
      chat_uuid: chatUuid,
      msg_seq: msgSeq,
    },
    errorMap: createErrorMap({}),
  })
}

async function getContactList() {
  return _call<APIGetContactListResponse>({
    path: '/entity-service/sdk/contacts/list',
    method: 'GET',
    errorMap: createErrorMap({}),
  })
}

async function getContactInfo(entityUserXrefUuid: string) {
  return _call<APIGetContactInfoResponse>({
    path: `/entity-service/sdk/contacts/info?entity_user_xref_uuid=${entityUserXrefUuid}`,
    method: 'GET',
    errorMap: createErrorMap({}),
  })
}

async function getContactAvatar(entityUserXrefUuid: string) {
  return _call<APIBlobResponse>({
    path: `/entity-service/sdk/contacts/avatar?entity_user_xref_uuid=${entityUserXrefUuid}`,
    method: 'GET',
    errorMap: createErrorMap({
      NoData: [
        ERROR_CODES.USER_IS_NOT_CONTACT,
        ERROR_CODES.USER_HAS_NO_AVATAR,
      ],
    }),
    expectBlobResponse: true,
  })
}


export const API = {
  ackMessage,
  createChat,
  searchChats,
  getChat,
  getChatList,
  getChatMessages,
  getChatDocument,
  getChatDocumentDownloadLink,
  getChatParticipantAvatar,
  sendTextMessage,
  sendImageMessage,
  sendFileMessage,
  sendAnimationMessage,
  joinMeeting,
  getContactList,
  getContactInfo,
  getContactAvatar,
}
