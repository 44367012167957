const typography = {
  xs: "0.65rem", // 12px
  s: "0.75rem", // 12px
  sm: "0.875rem", // 14px
  m: "0.906rem", // 14.5px
  ml: "0.9375rem", // 15px
  l: "1rem", // 16px
  xl: "1.25rem", // 20px
  xxl: "1.375rem", // 22px
  xxxl: "1.625rem", // 26px
  xxxxl: "2.5rem", // 40px
};

export default typography;
