import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EventIcon from '@mui/icons-material/Event';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { Button, Card, CardActions, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useCallback, useMemo, useState } from "react";
import { API } from "../../api";
import { ContactDTO } from "../../api/dto";
import { APIGetContactInfoResponse, ErrorResponse } from "../../api/types";
import { emitBookMeetingUrl, emitClickedOnContact } from "../../controller";
import { useOnMount } from "../../hooks";
import { getSdkOptions } from "../../utils";
import UserAvatar from "../UserAvatar";

interface Props {
  contact: ContactDTO;
  onCreateChat: (contact: ContactDTO) => void;
  width?: number;
}

function ContactListItem({contact, onCreateChat, width}: Props) {
  const {
    canStartChat,
    canBookMeeting,
    contactEmailClickable,
    contactPhoneClickable,
    contactsTappable,
    emitBookMeeting,
  } = useMemo(() => getSdkOptions(), []);

  const [calendarUrl, setCalendarUrl] = useState<string>();

  useOnMount(() => {
    if (canBookMeeting && contact.is_staff) {
      API.getContactInfo(contact.entity_user_xref_uuid).then(response => {
        if ((response as ErrorResponse).isError) {
          console.error(`Failed to load contact info for ${contact.entity_user_xref_uuid}`);
          // Don't emit error to user. If this fails, it might fail for each contact which would flood users.
          // Worst case, they just don't see Book Meeting button.
        } else {
          const contactInfo = (response as APIGetContactInfoResponse);
          if (contactInfo?.calendar?.available && contactInfo.calendar.public_url) {
            setCalendarUrl(contactInfo.calendar.public_url);
          }
        }
      })
    }
  });

  const onBookMeeting = useCallback(() => {
    if (calendarUrl) {
      if (emitBookMeeting) {
        return emitBookMeetingUrl(calendarUrl, contact.entity_user_xref_uuid);
      } else {
        window.open(calendarUrl,'qwil-scheduler');
      }
    }

  }, [calendarUrl, contact.entity_user_xref_uuid, emitBookMeeting]);

  const avatar = useMemo(() => (
    <UserAvatar
      firstName={contact.first_name}
      lastName={contact.last_name}
      entityUserXrefUuid={contact.entity_user_xref_uuid}
      source={'contact'}
    />
  ), [contact]);

  const emailAddresses = contact.emails.map(obj => obj.email);
  const phoneNumbers = contact.phone_numbers.map(obj => obj.phone_number);
  const onContactClicked = useCallback(() => {
    emitClickedOnContact(contact);
  }, [contact]);

  const extraProps = contactsTappable ? {
    onClick: onContactClicked,
    sx: { cursor: 'pointer'},
  } : {};

  const cardStyles = width ? { width: `${width}px` } : {};

  return (
    <Card variant='outlined' sx={{
      margin: 2,
      // boxShadow: 1,
      ...cardStyles
    }}>
      <CardHeader
        avatar={avatar}
        title={`${contact.first_name} ${contact.last_name}`}
        titleTypographyProps={{fontWeight: 400, fontSize: '1.1em'}}
        subheader={contact.job_title}
        {...extraProps}
      />
      <CardContent sx={{paddingY: "3px !important"}}>
        {emailAddresses.map(email => (
          <ContactDetail
            clickable={contactEmailClickable}
            type='email'
            text={email}
            key={`${email}-${contact.entity_user_xref_uuid}`}
          />
        ))}
        {phoneNumbers.map(phone => (
          <ContactDetail
            clickable={contactPhoneClickable}
            type='phone'
            text={phone}
            key={`${phone}-${contact.entity_user_xref_uuid}`}
          />
        ))}
      </CardContent>


      <CardActions>
        {canStartChat ? (
          <Button
            fullWidth
            // sx={{width: '100%', maxWidth: '800px'}}
            variant="outlined"
            startIcon={<ChatOutlinedIcon/>}
            onClick={() => {
              onCreateChat(contact)
            }}
          >
            Start a chat
          </Button>
        ) : null}
        {calendarUrl ? (
          <Button
            fullWidth
            // sx={{width: '100%', maxWidth: '800px'}}
            variant="outlined"
            startIcon={<EventIcon/>}
            onClick={onBookMeeting}
          >
            Book a meeting
          </Button>
        ) : null}
      </CardActions>
    </Card>
  );
}

interface ContactDetailProps {
  type: 'email' | 'phone';
  text: string;
  clickable: boolean;
}

function ContactDetail({type, text, clickable}: ContactDetailProps) {
  const Icon = type === 'email' ? EmailOutlinedIcon : LocalPhoneOutlinedIcon;
  const linkPrefix = type === 'email' ? 'mailto:' : 'tel:';
  return (
    <Typography color={grey[800]} fontSize={'0.9em'} marginBottom={1}>
      <Icon sx={{color: grey[400], fontSize: '1.3rem', verticalAlign: 'text-top', marginRight: '0.5rem'}}/>
      {clickable ? (
        <Link href={`${linkPrefix}${text}`}>{text}</Link>
      ) : text}
    </Typography>
  )
}

export default ContactListItem;
