import { RenderingCancelledException, RenderTask } from "pdfjs-dist";
import { PageCache } from "./types";

type CanvasGetter = (pageNum: number) => HTMLCanvasElement | null;
type PageCacheMap = {
  [key: number]: PageCache;
}

/**
 * Scheduler to sequentially render PDF pages, because doing it all concurrently could kill the browser
 * especially for large PDF documents.
 */
export default class RenderScheduler {
  pageMap: PageCacheMap;
  totalPages: number;
  getCanvas: CanvasGetter;
  task?: Task;
  deferred?: ReturnType<typeof setTimeout>;

  constructor(pages: PageCache[], canvasGetter: CanvasGetter) {
    this.totalPages = pages.length;
    this.pageMap = Object.fromEntries(pages.map(page => [page.num, page]));
    this.getCanvas = canvasGetter;
  }

  abort() {
    if (this.task) {
      this.task.abort();
    }
  }

  renderPages(startPage = 1){
    let delay = false;
    if (this.deferred) {
      clearTimeout(this.deferred)
    }

    if (this.task) {
      if (this.task.inProgress) {
        delay = true; // task cancellation takes time, so we delay render if that might be required
      }
      this.task.abort();
    }

    this._maybeDefer(() => {
      this.task = new Task(this.pageMap, this.getCanvas, this.totalPages, startPage);
    }, delay);
  }

  _maybeDefer(callback: () => void, delay: boolean) {
    if (delay) {
      this.deferred = setTimeout(callback, 100);
    } else {
      callback();
    }
  }

}

class Task {
  inProgress = false;
  renderTask?: RenderTask;
  getCanvas: CanvasGetter;
  pageMap: PageCacheMap;
  stack: number[];

  constructor(pageMap: PageCacheMap, canvasGetter: CanvasGetter, totalPages: number, startPage: number) {
    this.pageMap = pageMap;
    this.getCanvas = canvasGetter;
    this.stack = this._buildStack(totalPages, startPage);
    this._startRendering();
  }

  abort() {
    this.stack.length = 0;
    if (this.renderTask) {
      this.renderTask.cancel();
    }
    this.inProgress = false;
  }

  _startRendering() {
    (async () => {
      console.debug('Rendering PDF');
      let nextPage = this.stack.pop();
      while (nextPage !== undefined) {
        this.inProgress = true;
        const canvas = this.getCanvas(nextPage);
        const page = this.pageMap[nextPage];

        if (!canvas) {
          console.error(`Could not find canvas for page ${nextPage}. Cancelling render.`)
          break
        }
        
        // console.debug(`Rendering page ${nextPage}`);
        this.renderTask = this._renderPage(page, canvas);
        try {
          await (this.renderTask as RenderTask).promise;
        } catch (e) {
          if (e instanceof RenderingCancelledException) {
            console.debug('Rendering ABORTED')
            break;
          } else {
            console.error('Rendering FAILED', e);
          }
        }
        nextPage = this.stack.pop();
      }
      this.inProgress = false;
    })();
  }

  _renderPage(page: PageCache, canvas: HTMLCanvasElement) {
    const canvasContext = canvas.getContext('2d')!;
    const scale = canvas.clientWidth / page.actualWidth;
    const viewport = page.page.getViewport({scale});

    // Support HiDPI-screens.
    const outputScale = (window.devicePixelRatio || 1);
    canvas.width = viewport.width * outputScale;
    canvas.height = viewport.height * outputScale;
    const transform = outputScale !== 1
      ? [outputScale, 0, 0, outputScale, 0, 0]
      : undefined;

    page.page.cleanup();
    return page.page.render({transform, viewport, canvasContext});
  }

  _buildStack(totalPages: number, startPage: number) {
    if (startPage < 1 || startPage > totalPages) {
      throw new Error(`Invalid startPage ${startPage} (total = ${totalPages})`);
    }

    // we start rendering on page user is currently on, then build outwards in both directions
    // to account for users potentially scrolling forward or backwards from current page.
    const ordered = [];
    let cursor1 = startPage; // this will move backwards
    let cursor2 = startPage + 1; // this will move forwards
    while (cursor1 > 0 || cursor2 <= totalPages) {
      if (cursor1 > 0) {
        ordered.push(cursor1);
        cursor1--;
      }
      if (cursor2 <= totalPages) {
        ordered.push(cursor2);
        cursor2++;
      }
    }

    // We return it reversed, so we can use it as a stack and pop() from the back
    return ordered.reverse();
  }

}
