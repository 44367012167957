import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, IconButton } from "@mui/material";
import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import UploadDialog, { UploadPayload, UploadResult } from "../UploadDialog";


interface Props {
  disabled: boolean;
  handleSend: (payload: UploadPayload) => Promise<UploadResult>;
  getInitialCaption: () => string;
}

function UploadButton({disabled, handleSend, getInitialCaption}: Props) {
  const [uploaded, setUploaded] = useState<File>();
  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const onFileSelected = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return;
    }

    const files = Array.from(e.target.files || []);
    if (files.length !== 1) {
      // Should not happen?
      console.error(`Expected only one uploaded file. Found ${files.length}`);
      return;
    }

    e.target.value = '';  // clear selected so we can reuse the same element on next click
    setUploaded(files[0]);
  }, []);

  const handleDialogClose = useCallback(() => setUploaded(undefined), []);
  const handleSendUploaded = useCallback(async (payload: UploadPayload) => {
    const response = await handleSend(payload);
    if (response.success) {
      setUploaded(undefined);
    }
    return response;
  }, [handleSend]);

  return (
    <Box>
      <UploadDialog
        getInitialCaption={getInitialCaption}
        handleSend={handleSendUploaded}
        handleDialogClose={handleDialogClose}
        uploaded={uploaded}
      />
      <input type={'file'} ref={inputRef} style={{ display: 'none' }} onChange={onFileSelected} />
      <IconButton aria-label="Upload file" disabled={disabled} size={'small'} onClick={onClick}>
        <AttachFileIcon/>
      </IconButton>
    </Box>
  );
}

export default UploadButton;
