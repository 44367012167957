import CachedIcon from "@mui/icons-material/Cached";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, IconButton, InputAdornment, InputBase, Paper, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { SyntheticEvent, useState } from "react";
import { ChatDTO } from "../../api/dto";
import { ChatSearchResult } from "../../api/types";
import ChatListContainer from "./ChatListContainer";
import ChatSearchResultContainer from "./ChatSearchResultContainer";


interface ChatListProps {
  chats: ChatDTO[];
  hasMore: boolean;
  onChatSelected: (chat: ChatDTO) => void;
  onReload: () => void;

  searchResults?: ChatSearchResult[];
  searchResultsHasMore: boolean;
  searchLoading: boolean;
  searchError?: string;
  onSearch: (search: string) => void;
  onFinishSearch: () => void;
}

function ChatList(
  {
    chats,
    hasMore,
    onChatSelected,
    onReload,
    searchResults,
    searchResultsHasMore,
    searchLoading,
    onSearch,
    searchError,
    onFinishSearch,
  }: ChatListProps) {

  const theme = useTheme();
  const narrowScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [searchTerm, setSearchTerm] = useState('');

  const searchMode = searchResults !== undefined || searchLoading || !!searchError;

  const handleClearSearch = () => {
    setSearchTerm('');
  }

  const handleSearch = (e: SyntheticEvent) => {
    e.preventDefault();
    const cleaned = searchTerm.trim();
    if (cleaned) {
      onSearch(cleaned);
    }
  }

  const handleFinishSearch = () => {
    setSearchTerm('');
    onFinishSearch();
  }

  return (
    <Box sx={{height: '100%', width: '100%', minWidth: 250, bgcolor: grey['50'], overflowY: "auto"}}
         padding={0}>

      <Box
        paddingTop={3}
        paddingBottom={1}
        display={'flex'}
        flexDirection={'row-reverse'}
        width={'100%'}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          marginRight={4}
          marginLeft={4}
          width={narrowScreen ? '100%' : '400px'}
        >

          <Box flexGrow={1}>
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
              onSubmit={handleSearch}
            >
              <InputBase
                placeholder={'Search messages'}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ml: 1, flex: 1}}
                disabled={searchLoading}
                endAdornment={
                  searchTerm ? (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={handleClearSearch}>
                        <ClearIcon/>
                      </IconButton>
                    </InputAdornment>
                  ) : null
                }
              />
              <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
              <IconButton color="primary" sx={{p: '10px'}} aria-label="Search" onClick={handleSearch} disabled={searchLoading}>
                <SearchIcon/>
              </IconButton>
            </Paper>
          </Box>

          <Box flexShrink={1}>
            <IconButton
              color="primary"
              sx={{p: '10px', marginLeft: 1}}
              aria-label="Reload"
              onClick={onReload}
              disabled={searchMode}
            >
              <CachedIcon/>
            </IconButton>
          </Box>
        </Box>
      </Box>

      <ChatListContainer
        hidden={searchMode}
        chats={chats}
        hasMore={hasMore}
        onChatSelected={onChatSelected}
      />

      <ChatSearchResultContainer
        hidden={!searchMode}
        loading={searchLoading}
        results={searchResults}
        error={searchError}
        hasMore={searchResultsHasMore}
        onBack={handleFinishSearch}
      />

    </Box>
  )
}

export default ChatList
