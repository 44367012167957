import { Box, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import MiddleOfPage from "../../components/layout/MiddleOfPage";
import { resetAvatarCache } from "../../components/UserAvatar/cache";

function ErrorAuth() {
  useEffect(() => {

    resetAvatarCache();

  }, []);

  return (
    <MiddleOfPage>
      <Box textAlign={'center'}>
        <CircularProgress/>
        <Typography sx={{ color: grey[600]}} marginTop={2}>
          Credentials invalid or expired. Reauthenticating...
        </Typography>
      </Box>


    </MiddleOfPage>
  )
}

export default ErrorAuth
