import { grey } from "@mui/material/colors";
// @ts-ignore
import FileIcon, { defaultStyles } from "@qwilapp/react-file-icon";
import React from "react";

type Props = {
  extension: string;
}

function ExtensionBasedIcon({extension}: Props) {
  const knownExtension = !!defaultStyles[extension];
  const styles = defaultStyles[extension] || {};

  return (
    <FileIcon
      extension={knownExtension ? extension : ""}
      labelColor={grey[600]}
      color={grey[300]}
      glyphColor={grey[600]}
      {...styles}
      labelUppercase={true}
      size={35}
    />
  );
}

export default ExtensionBasedIcon;
