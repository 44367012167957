import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grow,
  IconButton,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { ChangeEvent, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { API } from "../../api";
import { ChatDTO, ContactDTO } from "../../api/dto";
import { APICreateChatResponse, ErrorResponse } from "../../api/types";
import { emitAppErrorMessage } from "../../controller";
import { getSdkOptions } from "../../utils";

interface Props {
  user?: ContactDTO,
  open: boolean;
  handleClose: () => void;
  onClosed: () => void;
  onChatCreated: (chat: ChatDTO) => void;
}

function ChatCreateDialog({user, open, handleClose, onClosed, onChatCreated}: Props) {
  const {startChatTitle, startChatSubmitButtonText, startChatTitleLabel, startChatMessageLabel} = useMemo(() => getSdkOptions(), []);
  const theme = useTheme();
  const showAsFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('Chat creation failed');
  const [deadEnd, setDeadEnd] = useState(false);

  const [message, setMessage] = useState('');
  const [trimmedMessage, setTrimmedMessage] = useState('');

  const [subject, setSubject] = useState('');
  const [trimmedTitle, setTrimmedTitle] = useState('');

  const canSubmit = !deadEnd && !loading && Boolean(trimmedMessage) && Boolean(trimmedTitle);

  const onMessageChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
    setTrimmedMessage(event.target.value.trim());
  }, []);

  const onSubjectChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
    setTrimmedTitle(event.target.value.trim());
  }, []);

  useEffect(() => {
    // Reset form values when dialog toggled
    setError('');
    setDeadEnd(false);
    setMessage('');
    setTrimmedMessage('');
    setSubject('');
    setTrimmedTitle('');
  }, [user])


  const onSubmit = () => {
    setError('');
    setLoading(true);
    API.createChat(trimmedTitle, [user!.entity_user_xref_uuid], trimmedMessage).then(async response => {
      setLoading(false);
      if ((response as ErrorResponse).isError) {
        const scenario = (response as ErrorResponse).errorScenario;
        switch (scenario) {
          case 'InvalidAuth':
            // API handler should have already triggered nav, so we do nothing
            break;
          case 'InvalidContact':
            emitAppErrorMessage('This use is no longer in your contacts');
            setError('Chat creation failed. This user is no longer in your contacts.');
            setDeadEnd(true);
            break;
          default:
            emitAppErrorMessage('Failed to create chat');
            setError('Chat creation failed.');
            break;
        }
      } else {
        onChatCreated((response as APICreateChatResponse).chat);
        handleClose();
      }
    });
  }

  const content = user ? (
    <Box display={'flex'} flexDirection={'column'} marginX={2} paddingBottom={2}>
      <Typography textAlign={'center'}>
        <QuestionAnswerOutlinedIcon sx={{fontSize: 40}}/>
      </Typography>
      <Typography textAlign={'center'} fontSize={'1.2em'} fontWeight={'bold'}>
        {startChatTitle}
      </Typography>
      <Typography textAlign={'center'} fontSize={'0.9em'}>
        with {user?.first_name} {user?.last_name}
      </Typography>

      <Box marginX={2} marginBottom={3}>
        <Box marginTop={3}>
          <TextField
            label={startChatTitleLabel}
            variant={'standard'}
            // size={'small'}
            value={subject}
            fullWidth
            disabled={loading}
            onChange={onSubjectChange}
            inputProps={{maxLength: 40}}
          />
        </Box>

        <Box marginTop={3}>
          <TextField
            label={startChatMessageLabel}
            variant={'outlined'}
            maxRows={4}
            // size={'small'}
            value={message}
            multiline
            fullWidth
            disabled={loading}
            onChange={onMessageChange}
            inputProps={{maxLength: 5000}}
          />
        </Box>

        <Box marginTop={5}>
          {error ? (
            <Box marginBottom={1} width={'100%'}>
              <Alert severity="error" >
                {error}
              </Alert>
            </Box>
          ) : null}

          <Button variant={'contained'} fullWidth disabled={!canSubmit} onClick={onSubmit}>
            {loading ? <CircularProgress color={'inherit'} size={'1.5rem'}/> : startChatSubmitButtonText}
          </Button>
        </Box>
        <Box marginTop={1} textAlign={'center'}>
          <Button variant={'text'} onClick={handleClose} disabled={loading}>Cancel</Button>
        </Box>
      </Box>
    </Box>
  ) : null;

  return (
    <Dialog
      open={open}
      fullScreen={showAsFullScreen}
      onClose={() => null}
      TransitionComponent={showAsFullScreen ? Transition : undefined}
      TransitionProps={{onExited: onClosed}}
    >
      <Box sx={{height: "100%", minWidth: '400px'}} display={'flex'} flexDirection={'column'}>
        {/* Header */}
        <Box display={'flex'} flexDirection={'row-reverse'} marginTop={1} marginRight={1}>
            <IconButton aria-label="Cancel" size={'small'} onClick={handleClose} disabled={loading}>
              <CloseIcon/>
            </IconButton>
        </Box>

        <Box>
          {content}
        </Box>
      </Box>
    </Dialog>
  )
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  // eslint-disable-next-line react/jsx-no-undef
  return <Grow ref={ref} {...props}/>;
});

export default ChatCreateDialog;
