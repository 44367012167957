import { RequestCallback, RequestErrorCallback, RequestSuccessCallback } from "qwil-api-transport";
import { authenticate } from "../api/auth";
import { navTo, navToAuthErrPage } from "../router";
import { extractSdkOptions } from "../sdkOptions";
import { commands } from "./constants";
import { CommandRequest, LoginRequest, NavigateCommandPayload } from "./types";




export function handleLoginRequest(request: LoginRequest, onComplete: RequestCallback) {
  const token = request.token || '';
  const endpoint = request.endpoint || '';
  const path = request.options?.path;  // will use default path if not provided
  const options = extractSdkOptions(request.options || {})

  authenticate(token, endpoint, options)
    .then(async (result) => {
      const {user, errorScenario} = result;
      let isAuthError = false;

      if (result.user) {
        (onComplete as RequestSuccessCallback)({
          result: {
            // return subset of user details to requester
            user: {
              firstName: user!.first_name,
              lastName: user!.last_name,
              entityUserXrefUuid: user!.entity_user_xref_uuid,
              isSelf: true,
            },
          }
        });
        await navTo(path);
      } else {
        switch (errorScenario) {
          case 'NoAuth':
            (onComplete as RequestErrorCallback)({
              error: {
                name: 'AuthFailed',
                message: 'Token not provided',
              }
            });
            isAuthError = true;
            break;
          case 'InvalidEndpoint':
            (onComplete as RequestErrorCallback)({
              error: {
                name: 'AuthFailed',
                message: 'Invalid endpoint',
              }
            });
            isAuthError = true;
            break;
          case 'InvalidAuth':
            (onComplete as RequestErrorCallback)({
              error: {
                name: 'AuthFailed',
                message: 'Token invalid or expired',
              }
            });
            isAuthError = true;
            break;
          case 'OrgCannotUseSDK':
            (onComplete as RequestErrorCallback)({
              error: {
                name: 'AuthFailed',
                message: 'Qwil SDK not enable for this organisation',
              }
            });
            isAuthError = true;
            break;
          default:
            (onComplete as RequestErrorCallback)({
              error: {
                name: 'AuthFailed',
                message: 'Could not authenticate credentials. API call failed.',
              }
            });
        }

        if (isAuthError) {
          await navToAuthErrPage();
        }
      }
    })
}

export function handleCommand({command, payload}: CommandRequest) {
  switch (command) {
    case commands.NAVIGATE:
      const {path} = (payload as NavigateCommandPayload);
      if (!path) {
        console.error(`"path" not provided. Cannot navigate.`);
      } else {
        navTo(path).then(() => console.debug(`Navigated to "${path}"`));
      }
      break;
    default:
      console.error(`Unsupported command "${command}"`);
  }
}
