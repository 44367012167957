import { MessageDTO } from "../../../api/dto";
import { ActionType, ChatState, ChatStateAction, ReplaceAction, UpdateAction } from "./types";


export function chatStateReducer(prevState: ChatState, action: ChatStateAction): ChatState {
  switch (action.type) {
    case ActionType.REPLACE:
      return handleReplaceState(prevState, action);
    case ActionType.UPDATE:
      return handleUpdateState(prevState, action);
    default:
      console.error('Unknown chat state action', action);
      return prevState;  // no change
  }
}

function handleReplaceState(prevState: ChatState, action: ReplaceAction): ChatState {
  const data = action.data;
  return {
    chat: data.chat || prevState.chat,
    messages: data.messages || prevState.messages,
  }
}

function handleUpdateState(prevState: ChatState, action: UpdateAction): ChatState {
  let dirty = false;
  let newState = {...prevState};
  const data = action.data;

  // maybe update Chat state
  if (data.chat && (!prevState.chat || data.chat.updated_seq > prevState.chat.updated_seq)) {
    newState.chat = data.chat;
    dirty = true;
  }

  // if messages provided, we insert/replace as appropriate
  if (data.messages) {

    // For now, if there are messages, we assume it is will update state
    newState.messages = updateMessageList(prevState.messages, data.messages);
    if (newState.messages !== prevState.messages) {
      // if not same object, then we state has changed
      dirty = true;
    }
  }

  return dirty ? newState : prevState;
}

function updateMessageList(prev: MessageDTO[], incoming: MessageDTO[]) {
  // IMPORTANT: The following are assumed, and should be kept so:
  // 1. state.messages is sorted by seq. It may contain gaps.
  // 2. action.data.messages is sorted by seq. It may contain gaps.

  // if prev state has no messages yet, we can just replace with new values
  if (prev.length === 0) {
    return [...incoming];
  }

  const prevOldestSeq = prev[0].seq;
  const prevNewestSeq = prev.at(-1)!.seq;
  const incomingOldestSeq = incoming[0].seq;
  const incomingNewestSeq = incoming.at(-1)!.seq;
  if (incomingOldestSeq > prevNewestSeq) {
    // all incoming are newer, since we assume both arrays are sorted, so we can just append.
    return [...prev, ...incoming];
  } else if (incomingNewestSeq < prevOldestSeq) {
    // all incoming are older, since we assume both arrays are sorted, so we can just prepend.
    return [...incoming, ...prev];
  } else {
    return mergeMessages(prev, incoming);
  }
}

function mergeMessages(prev: MessageDTO[], incoming: MessageDTO[]) {
  // TODO: use msg.updated_seq to determine of message is duplicated or updated.
  // For now, we assume that if incoming message will always replace prev if seq matches,
  // so state always changes. Once we have msg.seq, we can track if incoming is a subset of
  // prev and no newer updated_seq, and we can just return prev state.

  const messages: MessageDTO[] = [];

  // for simplicity, we just iterate both arrays and populate new ones. We can optimise this some day.
  let p = 0, x = 0;
  while (p < prev.length && x < incoming.length) {
    let pSeq = prev[p].seq;
    let xSeq = incoming[x].seq;
    if (pSeq < xSeq) {
      messages.push(prev[p]);
      p++;
    } else if (xSeq < pSeq) {
      messages.push(incoming[x]);
      x++;
    } else {
      // Found message with same seq.
      // TODO: choose based on updated_seq
      messages.push(incoming[x]);
      p++;
      x++;
    }
  }

  // Append remaining. Only one of the following loops will take effect due to conditions above.
  for (; p < prev.length; p++) {
    messages.push(prev[p]);
  }
  for (; x < incoming.length; x++) {
    messages.push(incoming[x]);
  }

  return messages;
}

