import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ChatSearchResult } from "../../../api/types";
import DetachedChat from "../../DetachedChat";

interface Props {
  open: boolean;
  onClose: () => void;
  result?: ChatSearchResult;
}

function PreviewDialog({open, onClose, result}: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const showAsFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navToChat = useCallback(() => {
    if (!result) {
      return;
    }
    navigate(`/chats/${result.chat.chat_uuid}`);
  }, [navigate, result]);

  const titleParams = showAsFullScreen ? {
    paddingLeft: '40px',
    marginY: '5px',
  } : {
    paddingRight: '30px',

  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={showAsFullScreen}
      sx={{minWidth: showAsFullScreen ? '250px': '550px'}}
    >
      {showAsFullScreen ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 3,
            top: 3,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ArrowBackIosIcon/>
        </IconButton>
      ) : (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 3,
            top: 3,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      )}

      <DialogTitle sx={{m: 0, p: 1, fontSize: '1em'}}>
        <Box {...titleParams}>
          {result ? `${result.chat.title}` : null}
        </Box>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        {result ? <DetachedChat message={result.message} chat={result.chat} onBack={onClose}/> : null}
      </DialogContent>
      <DialogActions>
        <Button fullWidth={true} variant={'contained'} onClick={navToChat}>View full conversation</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewDialog;
