export enum MessageContentKind {
  UNKNOWN = -2,
  DELETED = -1,
  SYSTEM = 0,
  TEXT = 1,
  ACTION = 2,
  IMAGE = 3,
  FILE = 4,
  MEETING = 5,
  EXTERNAL_MEETING = 6,
  SIGNATURE_SIGNED = 7,
  GUEST_SIGNATURE_SIGNED = 8,
  ACTION_WITH_REQUEST_ID = 9,
}

export const ContentKindThatShouldNotStartWithSenderName = new Set([
  MessageContentKind.UNKNOWN,
  MessageContentKind.DELETED,
  MessageContentKind.SYSTEM,
]);

export enum MessageType {
  UNKNOWN = -1,
  TEXT = 1,
  FILE = 2,
  IMAGE = 3,
  ANIMATION = 4,
  RENAME_CHAT = 5,
  INVITE_TO_CHAT = 6,
  HIDE_FROM_CHAT = 7,
  KICK_FROM_CHAT = 8,
  LEAVE_CHAT = 9,
  MEETING_CREATE = 10,
  BUTLER_SOMEONE_JOINED = 12,
  BUTLER_EXPIRED_INVITATION = 13,
  BUTLER_REFUSED_INVITATION = 14,
  BUTLER_SCHEDULE_MEETING_FOR_CREATOR = 15,
  DELETED_MESSAGE = 16,
  CONVERT_CHAT_TYPE = 17,
  ASSOCIATE_TEAM_CHAT = 18,
  DISSOCIATE_TEAM_CHAT = 18,
  RESTRICT_CHAT = 19,
  MESSAGE = 20,
  CREATE_SIGNATURE_REQUEST = 21,
  CREATE_SIGNATURE_REQUEST_DELETED = 22,
  SIGN_SIGNATURE_REQUEST = 23,
  REFUSE_SIGNATURE_REQUEST = 24,
  CANCEL_MEETING = 25,
  CLOSE_CHAT = 26,
  DEACTIVATE_MEMBER = 27,
  BUTLER_BOUNCED = 28,
  REMOVE_FROM_CHAT = 29,
  REOPEN_CHAT = 30,
  CREATE_SIGNATURE_REQUEST_SIGNED = 31,
  CREATE_SIGNATURE_REQUEST_REFUSED = 32,
  CREATE_CHAT = 33,
  BUTLER_SIGNATURE_REMINDER = 34,
  CREATE_BIOMETRICS_REQUEST = 35,
  CREATE_BIOMETRICS_REQUEST_DELETED = 36,
  CREATE_BIOMETRICS_REQUEST_REFUSED = 37,
  REFUSE_BIOMETRICS_REQUEST = 38,
  CREATE_BIOMETRICS_REQUEST_SIGNED = 39,
  SIGN_BIOMETRICS_REQUEST = 40,
  BUTLER_SIGNATURE_REMINDER_CREATOR = 41,
  BUTLER_BIOMETRICS_REMINDER = 42,
  BUTLER_BIOMETRICS_REMINDER_CREATOR = 43,
  CREATE_SIGNATURE_GUEST_REQUEST = 44,
  CREATE_SIGNATURE_GUEST_REQUEST_SIGNED = 45,
  CREATE_SIGNATURE_GUEST_REQUEST_REFUSED = 46,
  CREATE_SIGNATURE_GUEST_REQUEST_DELETED = 47,
  SIGN_SIGNATURE_GUEST_REQUEST = 48,
  REFUSE_SIGNATURE_GUEST_REQUEST = 49,
  BUTLER_GUEST_SIGNATURE_REMINDER_CREATOR = 50,
  CHIME = 51,
  BLOCKS = 52,
  EXTERNAL_MEETING_CREATE = 53,
  EXTERNAL_MEETING_CANCEL = 54,
}

