import { signalAuthExpired } from "../controller";
import { navToAuthErrPage } from "../router";
import { v4 as uuidv4 } from 'uuid';
import { ERROR_CODES, ERROR_CODES_IF_NO_AUTH } from "./errors";
import { ErrorMapping, ErrorResponse, ErrorScenario, FetchProps, ScenarioMapping, ServerErrorResponse } from "./types";

export function asPromise<T>(obj: T) {
  return new Promise<T>((resolve => resolve(obj)));
}

export async function fetchAndParse<T>(props: FetchProps): Promise<T | ErrorResponse> {
  const {
    url,
    method,
    signal,
    headers = {},
    payload,
    formData,
    successStatus = [200],
    errorMap = {},
    redirectIfNotAuthorised = true,
    expectBlobResponse = false,
  } = props;

  if (payload && formData) {
    throw new Error('Cannot provide both "payload" and "formData"');
  }
  return fetch(url, {
    method,
    signal,
    headers: {
      ...(payload ? { "Content-Type": "application/json;charset=utf-8" } : {}),
      ...headers,
    },
    ...(payload ? { body: JSON.stringify(payload) } : {}),
    ...(formData ? { body: formData } : {}),
  })
    .then(async (response) => {
      if (response.status > 499) {
        return {isError: true, errorScenario: "ServerError", response} as ErrorResponse;
      }

      if (successStatus.includes(response.status)) {
        if (expectBlobResponse) {
          return { 'blob': (await response.blob()) } as T;
        } else {
          return (await response.json()) as T;
        }
      }

      // if not successful, error response is likely to be JSON even if blob expected
      const payload = expectBlobResponse ? (await response.json()) : (await response.json());

      const errorCode = (payload as ServerErrorResponse).error_code;
      // log out error codes for debugging, but exclude expected
      if (errorCode && errorCode !== ERROR_CODES.USER_HAS_NO_AVATAR) {
        console.debug(`API returned error code ${errorCode}`);
      }

      if (redirectIfNotAuthorised && errorCode && ERROR_CODES_IF_NO_AUTH.includes(errorCode)) {
        signalAuthExpired();
        await navToAuthErrPage();
        return {
          isError: true,
          errorScenario: 'InvalidAuth',
          response,
        } as ErrorResponse;
      }

      if (errorCode && errorCode in errorMap) {
        return {
          isError: true,
          errorScenario: errorMap[errorCode],
          response,
        } as ErrorResponse;
      }

      console.error(
        `Unknown API response. status=${response.status}, payload=${payload}`
      );

      return {isError: true, errorScenario: "UnknownError", response} as ErrorResponse;
    })
    .catch((error) => {
      // This catches issues like invalid URL, non-JSON response, etc.
      console.error(error.message);
      return {isError: true, errorScenario: "UnknownError"} as ErrorResponse;
    });
}

export function createErrorMap(mapping: ScenarioMapping) {
  let errorMap: ErrorMapping = {};
  for (const [scenario, errorCodes] of Object.entries(mapping)) {
    errorCodes.forEach((code) => {
      if (code in errorMap) {
        throw new Error(`Error code ${code} mapped to multiple scenarios`);
      }
      errorMap[code] = scenario as ErrorScenario;
    });
  }
  return errorMap;
}


export function createCorrelationId() {
  return uuidv4();
}
