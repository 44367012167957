/**
 * LAYOUT: Positions children in the middle of the container.
 */
import { Grid } from "@mui/material";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  style?: object;
};

function MiddleOfPage(props: Props) {
  const { children, style } = props;
  const parentStyle = {
    minHeight: "100vh",
    ...style,
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={parentStyle}
    >
      <Grid item xs={3}>
        {children}
      </Grid>
    </Grid>
  );
}

export default MiddleOfPage;
