import React, { useCallback, useState } from "react";
import { MessageDTO } from "../../api/dto";
import FilePreview from "./FilePreview";
import MessageDownloader from "./MessageDownloader";

export function useFilePreview() {
  const [messageBeingPreviewed, setMessageBeingPreviewed] = useState<MessageDTO>();
  const [documentPreviewOpen, setDocumentPreviewOpen] = useState(false);

  const handleDocumentPreviewClose = useCallback(() => {
    setDocumentPreviewOpen(false);
  }, []);

  const onDocumentPreviewClosed = useCallback(() => {
    // this is called after the transition animation ends
    setMessageBeingPreviewed(undefined);
  }, []);

  const filePreviewElement = (
    <FilePreview
      message={messageBeingPreviewed}
      open={documentPreviewOpen}
      handleClose={handleDocumentPreviewClose}
      onClosed={onDocumentPreviewClosed}
    />
  );

  return {filePreviewElement, setMessageBeingPreviewed, setDocumentPreviewOpen};
}

export function useMessageDownloader() {
  const [messageToDownload, setMessageToDownload] = useState<MessageDTO>();
  const onDownloaderClose = useCallback(() => setMessageToDownload(undefined), []);

  const messageDownloaderElement = (
    <MessageDownloader message={messageToDownload} onClose={onDownloaderClose}/>
  );

  return {messageDownloaderElement, setMessageToDownload};
}
