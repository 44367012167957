const spacing = {
  none: "0",
  xxs: "2.5px",
  xs: "5px",
  s: "10px",
  m: "20px",
  ml: "30px",
  l: "40px",
  xl: "60px",
  textBox: "2px 8px",
  formSide: "28px",
};

export default spacing;
