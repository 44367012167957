import { Alert, Box, Button, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Modal from '@mui/material/Modal';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { API } from "../../../api";
import { MessageDTO } from "../../../api/dto";
import { APIBlobResponse, ErrorResponse } from "../../../api/types";
import { downloadBlob } from "../../../utils";

interface Props {
  message?: MessageDTO;
  onClose: () => void;
}

function MessageDownloader({message, onClose}: Props) {
  // Set "message" to a valid document message to trigger download.
  // Unset "message" to close dialog.

  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  const abortControllerRef = useRef<AbortController>();

  const handleError = useCallback((errorText: string) => {
    setError(errorText);
  }, []);

  const handleAckError = useCallback(() => {
    onClose();
    // Error state is reset using onTransitionExited to avoid flashing content while closing
  }, [onClose])

  useEffect(() => {
    const canDownload = !!(message && message?.content.document);
    setOpen(canDownload);

    if (!canDownload) {
      return;
    }

    const filename = message.content.document!.filename;
    const controller = new AbortController();
    abortControllerRef.current = controller;

    API.getChatDocument(message?.chat_uuid, message.seq, controller.signal)
      .then(response => {
        // First, check if this ended because user cancelled the download
        if (controller.signal.aborted) {
          console.info(`Download of ${filename} aborted by user`);
          setOpen(false);
          onClose();
          return;
        }

        // Next, check if there was an error
        if ((response as ErrorResponse).isError) {
          const scenario = (response as ErrorResponse).errorScenario;
          switch (scenario) {
            case 'NoData':
              handleError('This message is no longer available for download');
              break;
            default:
              handleError(`Failed to download "${filename}"`);
          }
        } else {
          downloadBlob((response as APIBlobResponse).blob, filename);
          setOpen(false);
          onClose();
        }
      })
      .catch(() => {
        handleError(`Error while attempting to download "${filename}"`);
      });

  }, [handleError, message, onClose]);

  const handleCancel = () => {
    console.debug('Cancelling download');
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {}}  // do not allow dismissing modal
      disableAutoFocus={true}
      onTransitionExited={() => {
        // reset error each time after closing the modal
        if (!open) {
          setError('');
          abortControllerRef.current = undefined;
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 300,
          width: '70%',
          bgcolor: 'background.paper',
          borderRadius: '0.5em',
          border: `1px solid ${grey[600]}`,
          boxShadow: 24,
          p: 4,
        }}
      >
        { !error ? (
          <>
            <Typography textAlign={'center'} fontWeight={500}>
              Downloading "{message?.content?.document?.filename}"
            </Typography>

            <Box textAlign={'center'} marginTop={3}>
              <CircularProgress/>
            </Box>

            <Box marginTop={2} textAlign={'center'}>
              <Button onClick={handleCancel}>Cancel download</Button>
            </Box>
          </>
        ) : (
          <>
            <Alert severity="error">{ error }</Alert>

            <Box marginTop={2} textAlign={'center'}>
              <Button onClick={handleAckError}>Close</Button>
            </Box>
          </>
        )}

      </Box>
    </Modal>
  );
}


export default MessageDownloader;
