import createPanZoom from "panzoom";

export interface Zoomable {
  isDisposed: boolean;
  dispose: () => void;
  recenter: () => void;
  getScale: () => number;
}

export interface ZoomableOptions {
  wheelZoom?: boolean;
  startCentered?: boolean;
  maxZoom?: number;
  minZoom?: number;

  // panzoom uses same value for bounds threshold and default margin when autocenter.
  // We separate this by implementing our own logic for autocenter.
  // The % values below are margin sizes used to form bounding box related to parent dims.
  // In the case of centering, we attempt to fit the element within said bounding box.
  // In the case of bounds, we ensure that at least one edge of the element overlaps with the bounding box.
  centeringBoxMargin?: number; // %
  boundsBoxMargin?: number; // %
}

/**
 * wrapper for panzoom lib so we can add functionality and potentially change lib (there are so many)
 */
export function createZoomable(element: HTMLElement, options: ZoomableOptions) {
  const {
    wheelZoom = true,
    startCentered = true,
    boundsBoxMargin = 0.2,
    centeringBoxMargin = 0.1,
    maxZoom = 10, // 10x
    minZoom = 0.05, // 5%
  } = options;

  if (boundsBoxMargin > 0.9 || boundsBoxMargin < 0.1) {
    // bad things happen when these values are outside this range.
    throw new Error ('Invalid boundsBoxPercent');
  }

  if (centeringBoxMargin > 0.3 || centeringBoxMargin < 0) {
    // bad things happen when these values are outside this range.
    throw new Error ('Invalid centeringBoxMargin');
  }

  // cache original client dims
  const oriWidth = element.clientWidth;
  const oriHeight = element.clientHeight;

  const panzoom = createPanZoom(element, {
    maxZoom,
    minZoom,

    beforeWheel: wheelZoom ? undefined : () => true,
    bounds: true, // Keep element within the bounds of the parent
    boundsPadding: boundsBoxMargin,

    smoothScroll: false, // disable scroll kinetics
  });

  const recenter = () => {
    // we don't use "autocenter" in panzoomOptions because that is hard coded to use the
    // margins from boundsPadding. It also cannot be called later on and only on init.
    // So we roll our own...

    const parent = element.parentElement!.getBoundingClientRect();
    const marginsPercent = centeringBoxMargin;

    // calculate bounding box to fit element in
    const left = parent.width * marginsPercent;
    const top = parent.height * marginsPercent;
    const right = parent.width - (1 - marginsPercent);
    const bottom = parent.height - (1 - marginsPercent);
    const width = right - left;
    const height = bottom - top;

    // calculate scale such that element is within bounding box
    const dh = width / oriWidth;
    const dw = height / oriHeight;
    const scale = Math.min(dw, dh, 1);

    // calculate translation to fit scaled element in middle of parent
    const scaledWidth = oriWidth * scale;
    const scaledHeight = oriHeight * scale;
    const cx = parent.width / 2; // center point
    const cy = parent.height / 2 // center point
    const tx = cx - (scaledWidth / 2); // shift by half elem width left of center
    const ty = cy - (scaledHeight / 2); // shift by half elem height above center

    panzoom.zoomAbs(cx, cy, scale);
    panzoom.moveTo(tx, ty);
  };

  if (startCentered) {
    recenter();
  }

  return {
    isDisposed: false,
    recenter,
    getScale: () => panzoom.getTransform().scale,
    dispose: function () {
      this.isDisposed = true;
      panzoom.dispose();
    },
  } as Zoomable;
}
