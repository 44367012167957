import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import typography from "../../../typography";

type Props = {
  text: string;
}

function SystemMessageChatEntry({text}: Props) {
  return (
    <Box width={"100%"} lineHeight={1} display={'flex'} justifyContent={'space-around'} marginY={1}>
      <Box width={"80%"} textAlign={'center'}>
        <Typography
          component="span"
          fontSize={typography.s}
          color={grey[600]}
          letterSpacing={'0.03rem'}
          margin={0}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default SystemMessageChatEntry;

