import { Box, Card, List } from "@mui/material";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography/Typography";
import React from "react";
import { ChatDTO } from "../../api/dto";
import ChatListItem from "./ChatListItem";

interface Props {
  hidden: boolean;
  chats: ChatDTO[];
  hasMore: boolean;
  onChatSelected: (chat: ChatDTO) => void;
}

function ChatListContainer({ hidden, chats, hasMore, onChatSelected}: Props) {
  return (
    <Card variant='outlined' sx={{margin: 2, display: hidden ? 'none' : 'block'}}>
      <List dense disablePadding>
        {chats.map(chat => (
          <ChatListItem
            chat={chat}
            key={`c:${chat.chat_uuid}:${chat.last_seq}`}
            onChatSelected={onChatSelected}
          />
        ))}
      </List>
      {hasMore ? (
        <Box
          margin={2}
          paddingX={1}
          paddingTop={2}
          borderTop={`1px dashed ${grey[400]}`}
          color={grey[500]}
        >
          <Typography fontSize={"0.9rem"}>
            Not all chats are show here as you have reached the size limit.
            To see older chats, please log in to the Qwil app.
          </Typography>
        </Box>
      ) : null}
    </Card>
  );
}

export default ChatListContainer;
