import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { useOnElementSizeChange } from "../../../hooks";
import { createZoomable, Zoomable } from "./zoomable";

interface Props {
  imageBlob: Blob;
  filename: string;
}

function ImageViewer({imageBlob, filename}: Props) {
  const [url, setUrl] = useState<string>();
  const parentRef = useRef<HTMLElement>(null);
  const zoomableRef = useRef<Zoomable>();

  useEffect(() => {
    if (!imageBlob) {
      return;
    }

    const url = window.URL.createObjectURL(imageBlob);
    setUrl(url);

    return () => {
      window.URL.revokeObjectURL(url);
      if (zoomableRef.current) {
        zoomableRef.current.dispose();
      }
    }
  }, [imageBlob]);

  useOnElementSizeChange(parentRef, () => {
    const zoomable = zoomableRef.current;
    if (zoomable && !zoomable.isDisposed && parentRef.current) {
      zoomable.recenter();
    }
  })

  const onImageLoaded = () => {
    const parent = parentRef.current;
    if (!parent) {
      console.error('load triggered before element ref set');
      return;
    }

    const img = parent.querySelector('img');
    if (!img) {
      console.error('could not locate image element');
      return;
    }

    // enable panzoom
    zoomableRef.current = createZoomable(img, {
      maxZoom: 10,
      minZoom: 0.05,
    });
  }

  return (
    <Box
      component={'div'}
      ref={parentRef}
      sx={{ bgcolor: grey[300], height: '100%', overflow: 'hidden'}}
    >
      {url ? <img src={url} onLoad={onImageLoaded} alt={filename}/> : null}
    </Box>
  );
}

export default ImageViewer;
