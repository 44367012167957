import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../api";
import { ChatDTO } from "../../api/dto";
import { APIGetChatListResponse, APISearchChatResponse, ErrorResponse } from "../../api/types";
import ChatList from "../../components/ChatList";
import MiddleOfPage from "../../components/layout/MiddleOfPage";
import { emitAppErrorMessage } from "../../controller";


function LoadingChats() {
  return (
    <MiddleOfPage>
      <Box textAlign={'center'}>
        <CircularProgress />
        <Typography color={grey[500]} marginTop={2} fontSize={'0.9rem'} letterSpacing={1.1}>
          Loading chats ...
        </Typography>
      </Box>
    </MiddleOfPage>
  )
}

function LoadError({ error }: { error: string }) {
  return (
    <>
      <Box margin={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    </>
  )
}

function ChatListScreen() {
  const navigate = useNavigate();

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<APIGetChatListResponse>();

  const [searchResult, setSearchResult] = useState<APISearchChatResponse>();
  const [searchError, setSearchError] = useState<string>();
  const [searchLoading, setSearchLoading] = useState(false);

  const doLoadChat = useCallback(async () => {
    return API.getChatList()
      .then(response => {
        if ((response as ErrorResponse).isError) {
          emitAppErrorMessage('Failed to load list of chats');
          setError("Failed to load list of chats");
        } else {
          setResult(response as APIGetChatListResponse);
        }
      })
      .catch(() => {
        emitAppErrorMessage('Failed to load list of chats');
        setError("Failed to load list of chats");
      });
  }, []);

  const doSearch = useCallback(async (searchTerm: string) => {
    const cleaned = searchTerm.trim();
    if (!cleaned) {
      return;
    }

    setSearchLoading(true);
    setSearchError(undefined);

    return API.searchChats(cleaned)
      .then(response => {
        if ((response as ErrorResponse).isError) {
          emitAppErrorMessage('Search failed');
          setSearchError("Search failed");
        } else {
          setSearchResult(response as APISearchChatResponse);
        }
        setSearchLoading(false);
      })
      .catch(() => {
        emitAppErrorMessage('Search failed');
        setSearchError("Search failed");
        setSearchLoading(false);
      });
  }, []);

  const doFinishSearch = useCallback(() => {
    // N.B. This should not be called while search still in progress since we don't yet handle
    // cancellation of search progress
    setSearchResult(undefined);
    setSearchError(undefined);
  }, []);

  useEffect(() => {
    doLoadChat().then(() => setLoading(false));
  }, [doLoadChat]);


  const onChatSelected = useCallback((chat: ChatDTO) => {
    navigate(`/chats/${chat.chat_uuid}`, { state: { chat }});
  }, [navigate])

  const onReload = useCallback(() => {
    if (!loading) {
      setLoading(true);
      doLoadChat().then(() => setLoading(false));
    }
  }, [doLoadChat, loading]);

  if (loading && !result) {
    return <LoadingChats/>;
  } else if (error) {
    return <LoadError error={error} />;
  } else {
    return (
      <ChatList
        chats={result!.chats}
        hasMore={result!.has_more}
        onChatSelected={onChatSelected}
        onReload={onReload}
        searchResults={searchResult? searchResult.results : undefined}
        searchResultsHasMore={searchResult? searchResult.has_more : false}
        searchLoading={searchLoading}
        searchError={searchError}
        onSearch={doSearch}
        onFinishSearch={doFinishSearch}
      />
    )
  }
}

export default ChatListScreen;
