import { Box, BoxProps } from "@mui/material";
import React, { useContext } from "react";
import { EntityUserDTO } from "../../../api/dto";
import { ThemeContext } from "../../../theme";
import Linkify from "../../Linkify";
import UserAvatar from "../../UserAvatar";
import BaseChatEntry from "./BaseChatEntry";


type Props = {
  text: string;
  date: Date;
  author: EntityUserDTO;
}

function TextChatEntry({text, author, date}: Props) {
  const avatar = (
    <UserAvatar
      firstName={author.first_name}
      lastName={author.last_name}
      entityUserXrefUuid={author.entity_user_xref_uuid}
    />
  );

  return (
    <BaseChatEntry
      avatar={avatar}
      date={date}
      position={author.me ? 'right' : 'left'}
      name={author.me ? 'You' : `${author.first_name} ${author.last_name}`}
    >
      <TextBox text={text} author={author} />
    </BaseChatEntry>
  );
}


type TextBoxProps = {
  text: string;
  author: EntityUserDTO;
}

export function TextBox({ text, author }: TextBoxProps) {
  const sdkTheme = useContext(ThemeContext);

  const extraBoxProps: BoxProps = author.me ? {
    bgcolor: sdkTheme.bubbleBgColour,
    color: sdkTheme.bubbleTextColour,
  } : {
    bgcolor: sdkTheme.bubbleBgColour2,
    color: sdkTheme.bubbleTextColour2,
  };

  const linkColor = author.me ? sdkTheme.bubbleLinkColour : sdkTheme.bubbleLinkColour2;

  return (
    <Box
      paddingX={1}
      paddingY={1}
      borderRadius={2}
      sx={{wordBreak: 'break-word'}}
      {...extraBoxProps}
    >
      <Linkify text={text} color={linkColor} />
    </Box>
  );
}

export default TextChatEntry;
