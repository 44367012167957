import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import useMediaQuery from '@mui/material/useMediaQuery';
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getSdkOptions, reverseRecord } from "../../utils";

const navTargets = {
  contacts: '/contacts',
  chats: '/chats',
}
type TargetName = keyof typeof navTargets;

// inverted navTarget so we can map path to target name
const targetMap = reverseRecord(navTargets);


function TopNavLayout() {
  const { hideTopNav, topNavLabelContacts, topNavLabelChats, topNavHeading } = useMemo(() => getSdkOptions(), []);

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const narrowScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selected, setSelected] = useState<TargetName>('chats');

  const handleChange = (event: SyntheticEvent, newValue: TargetName) => {
    const target = navTargets[newValue];
    if (target) {
      navigate(target);
    }
  };

  useEffect(() => {
    setSelected(targetMap[location.pathname]);
  }, [location]);

  const content = (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        minWidth: 250,
        bgcolor: 'background.paper',
        overflowY: "auto",
        padding: 0,
      }}
    >
      <Outlet/>
    </Box>
  );

  if (hideTopNav) {
    return content;
  }

  return (
    <Box sx={{height: "100%", minWidth: '250px'}} display={'flex'} flexDirection={'column'}>
      {topNavHeading.trim() ? (
        <Box
          sx={{
            flexShrink: 1,
            textAlign: 'center',
            marginTop: 1,
          }}
        >
          <Typography fontWeight={'500'}>
            Your Adviser
          </Typography>
        </Box>
      ) : null}

      <Box
        display={'flex'}
        sx={{
          flexShrink: 1
        }}
      >

        <Box
          sx={{
            borderBottom: 1,
            borderColor: grey[300],
            width: '100%'
          }}
        >
          <Tabs onChange={handleChange} value={selected} variant={narrowScreen ? 'fullWidth' : 'standard'} centered>
            {/* N.B. Make sure these matches entries in navTargets */}
            <Tab value='contacts' label={topNavLabelContacts}/>
            <Tab value='chats' label={topNavLabelChats} />
          </Tabs>
        </Box>
      </Box>

      {content}
    </Box>
  );
}

export default TopNavLayout;
