import { Box, BoxProps, Typography } from "@mui/material";
import { blueGrey, green, grey } from "@mui/material/colors";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import React from "react";
import { DocumentDTO, EntityUserDTO } from "../../../api/dto";
import typography from "../../../typography";
import UserAvatar from "../../UserAvatar";
import BaseChatEntry from "./BaseChatEntry";

type Props = {
  date: Date;
  text: string;
  author: EntityUserDTO;
  document: DocumentDTO;
  requestId: string;
  onDocumentClick: () => void;
}

function SignatureSignedEntry(props: Props) {
  const {
    date,
    text,
    author,
    document,
    requestId,
    onDocumentClick,
  } = props;

  const avatar = (
    <UserAvatar
      firstName={author.first_name}
      lastName={author.last_name}
      entityUserXrefUuid={author.entity_user_xref_uuid}
    />
  );

  const positionProps: BoxProps = author.me ? {
    flexDirection: 'row-reverse',
    marginLeft: 'auto',
  } : {
    marginRight: 'auto',
  }

  const textPositionProps: BoxProps = author.me ? {
    textAlign: 'right'
  } : {};

  return (
    <BaseChatEntry
      avatar={avatar}
      date={date}
      position={author.me ? 'right' : 'left'}
      name={author.me ? 'You' : `${author.first_name} ${author.last_name}`}
    >
      <Box {...textPositionProps}>
        <Typography
          component="span"
          fontSize={typography.sm}
          color={blueGrey[700]}
          margin={0}

        >
        {text}
        </Typography>
      </Box>
      <Box marginBottom={0} display={'flex'} {...positionProps}>
        <Box
          border={1}
          padding={1}
          width={'fit-content'}
          borderColor={grey[300]}
          borderRadius={'0.25em'}
          display={'flex'}
          flexDirection={'row'}
          sx={{cursor: 'pointer', overflow: 'hidden'}}
          onClick={onDocumentClick}
        >
          <Box
            display={'flex'}
            flexShrink={0}
            justifyContent={'center'}
            alignItems={'center'}
            marginRight={1}
            borderRadius={'0.25em'}
            bgcolor={green[400]}
            color={'white'}
            width={38}
            height={38}
          >
            <DriveFileRenameOutlineOutlinedIcon />
          </Box>
          <Box
            flexGrow={1}
            display={'flex'}
            flexDirection={'column'}
            sx={{overflow: 'hidden', wordBreak: 'break-word',}}
          >
            <Typography
              fontWeight={500}
              fontSize={'0.9rem'}
              sx={{
                // textWrap: "nowrap",
                // textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              Signed document

            </Typography>
            <Typography
              fontWeight={400}
              fontSize={'0.75rem'}
              color={grey[500]}
              sx={{
                textWrap: "nowrap",
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {document.filename}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box {...textPositionProps} marginBottom={1}>
        <Typography
          component="span"
          fontSize={typography.xs}
          color={grey[400]}
          margin={0}
        >
          Request ID: {requestId}
        </Typography>
      </Box>
    </BaseChatEntry>
  )
}

export default SignatureSignedEntry;
