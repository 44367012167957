import { Box, Skeleton } from "@mui/material";
import React from "react";

function SkeletonChatEntry() {
  return (
    <Box margin={1}  height={70}>

      <Box
        margin={0}
        display='flex'
        flexShrink={0}
        marginRight='auto'
      >
        <Box width={40}>
          <Skeleton animation={'wave'} variant={'circular'} width={40} height={40} />
        </Box>
        <Box flexShrink={1} marginX={1}>
          <Box width={'80%'}>
            <Skeleton animation={'wave'} variant={'rounded'} height={50} width={300}/>
          </Box>
        </Box>

      </Box>
    </Box>
  );
}

export default SkeletonChatEntry;
