import { ChatDTO, MessageDTO } from "../../../api/dto";
import { ActionType, ReplaceAction, UpdateAction } from "./types";

export function createUpdateAction(data: {chat?: ChatDTO, messages?: MessageDTO[]}): UpdateAction {
  return { type: ActionType.UPDATE, data };
}

export function createReplaceAction(data: {chat?: ChatDTO, messages?: MessageDTO[]}): ReplaceAction {
  return { type: ActionType.REPLACE, data };
}
