import { Link } from "@mui/material";
import LinkifyIt, { Match } from 'linkify-it';
import { Fragment } from "react";

const linkify = new LinkifyIt();

type LinkifyProps = {
  text: string;
  color?: string;
}

// Be extra strict. More so than our main app which would even accept "//" or "ftp:"
const allowedSchema = new Set(['http:', 'https:', 'mailto:']);

function Linkify({text, color}: LinkifyProps) {
  const matches = matchLinks(text);
  if (matches.length === 0) {
    return <>{text}</>;
  }

  const segments = splitIntoSegments(text, matches);
  return (
    <Fragment>
      {segments.map((s, index) => renderSegment(s, index, color))}
    </Fragment>
  )
}

function matchLinks(text: string) {
  if (!linkify.test(text)) {
    return [];
  }

  const matches = linkify.match(text) || [];
  return matches.filter((match) => allowedSchema.has(match.schema));
}

type Segment = {
  content: string;
  isUrl: boolean;
}

function splitIntoSegments(text: string, matches: Match[]) {
  if (matches.length < 1) {
    throw new Error('This should never be called with empty matches');
  }

  let lastIndex = 0;
  const segments: Segment[] = [];

  matches.forEach(match => {
    if (match.index !== lastIndex) {
      segments.push({isUrl: false, content: text.substring(lastIndex, match.index)});
    }
    segments.push({isUrl: true, content: match.url});
    lastIndex = match.lastIndex;
  });

  if (lastIndex < text.length) {
    segments.push({isUrl: false, content: text.substring(lastIndex)});
  }

  return segments;
}

function renderSegment(segment: Segment, index: number, color?: string) {
  const extraProps = color ? {sx: {color}} : {};

  if (segment.isUrl) {
    return <Link
      href={segment.content}
      target='_blank'
      rel="noreferrer"
      key={index}  // not required. Only to suppress react warnings about key requirements
      {...extraProps}
    >
      {segment.content}
    </Link>;
  } else {
    return segment.content;
  }
}

export default Linkify;
