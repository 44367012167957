import { Reducer } from "react";
import { ChatDTO, MessageDTO } from "../../../api/dto";


export type ChatState = {
  chat?: ChatDTO;
  messages: MessageDTO[]; // N.B. we expect this to always be sorted, but it may contain gaps.
}

export enum ActionType {
  REPLACE = "REPLACE",
  UPDATE = "UPDATE"
}

export type ReplaceAction = {
  type: ActionType.REPLACE,
  data: {
    chat?: ChatDTO;
    messages?: MessageDTO[];
  }
}

export type UpdateAction = {
  type: ActionType.UPDATE,
  data: {
    chat?: ChatDTO;
    messages?: MessageDTO[];
  }
}

export type ChatStateAction = UpdateAction | ReplaceAction;

export type ChatStateReducer = Reducer<ChatState, ChatStateAction>;
