import { Avatar } from "@mui/material";
import GraphemeSplitter from "grapheme-splitter";
import { useEffect, useMemo, useState } from "react";
import { getAvatarImage } from "./cache";


type Props = {
  firstName: string;
  lastName: string;
  entityUserXrefUuid: string;
  color?: string;
  source?: 'chat' | 'contact';
}

const splitter = new GraphemeSplitter();

function stringToColor(string: string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function UserAvatar({firstName, lastName, entityUserXrefUuid, color, source }: Props) {
  const [loading, setLoading] = useState(true);

  const bgcolor = color || stringToColor(`${firstName} ${lastName}`);
  const initials = useMemo(() => {
    const letter1 = splitter.splitGraphemes(firstName.trim())[0] || "";
    const letter2 = splitter.splitGraphemes(lastName.trim())[0] || "";
    return `${letter1}${letter2}`.toUpperCase();
  }, [firstName, lastName])

  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    (async () => {
      const blob = await getAvatarImage(entityUserXrefUuid, source || 'chat');
      if (blob) {
        setImgUrl(URL.createObjectURL(blob));
      }
      setLoading(false);
    })();

  }, [entityUserXrefUuid, source]);

  useEffect(() => {
    return () => {
      if (imgUrl) {
        URL.revokeObjectURL(imgUrl)
      }
    }
  }, [imgUrl]);

  // To avoid flash of colour, we don't set bgcolor until we know that there is no image.
  const avatarProps = loading ? {} : imgUrl ? {
    src: imgUrl
  } : {
    sx: {bgcolor}
  }

  return (
    <Avatar
      alt={`${firstName} ${lastName}`}
      {...avatarProps}
    >
      {initials}
    </Avatar>
  );
}


export default UserAvatar;
