import { Box, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useMemo, useState } from "react";
import { ChatDTO, ContactDTO, EntityDTO } from "../../api/dto";
import { getSdkOptions } from "../../utils";
import ChatCreateDialog from "../ChatCreateDialog";
import ContactListItem from "./ContactListItem";

interface Props {
  contacts: ContactDTO[];
  entity: EntityDTO;
  hasMore: boolean;
  onReload: () => void;
  onChatCreated: (chat: ChatDTO) => void;
}

function ContactList({contacts, entity, hasMore, onReload, onChatCreated}: Props) {
  const theme = useTheme();
  const fixedWidth = useMediaQuery(theme.breakpoints.up('sm'));
  const itemWidth = fixedWidth ? theme.breakpoints.values.sm - 32 : undefined;

  const {showOrgNameOnContactsPage} = useMemo(() => getSdkOptions(), []);

  const [chatCreateTarget, setChatCreateTarget] = useState<ContactDTO>();
  const [chatCreateDialogOpen, setChatCreateDialogOpen] = useState(false);

  const handleChatCreateDialogClose = useCallback(() => {
    setChatCreateDialogOpen(false);
  }, []);

  const onChatCreateDialogClosed = useCallback(() => {
    setChatCreateTarget(undefined);
  }, []);

  const onCreateChatClicked = useCallback((contact: ContactDTO) => {
    setChatCreateTarget(contact);
    setChatCreateDialogOpen(true);
  }, []);

  const containerParams = fixedWidth ? {alignItems: 'center'}: {};
  return (
    <Box sx={{height: "100%", minWidth: '250px'}} display={'flex'} flexDirection={'column'}>
      <ChatCreateDialog
        user={chatCreateTarget}
        open={chatCreateDialogOpen}
        handleClose={handleChatCreateDialogClose}
        onClosed={onChatCreateDialogClosed}
        onChatCreated={onChatCreated}
      />

      <Box
        sx={{
          height: '100%',
          width: '100%',
          minWidth: 250,
          bgcolor: grey['50'],
          overflowY: "auto",
        }}
        padding={0}
      >

        {showOrgNameOnContactsPage ? (
          <Box
            paddingTop={3}
            paddingBottom={3}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box width={'80%'}>
              <Typography
                fontFamily={'serif'}
                fontWeight={800}
                fontSize={'1.3em'}
                letterSpacing={'0.1em'}
                lineHeight={'1em'}
                textAlign={'center'}
              >
                {entity.name}
              </Typography>
            </Box>
          </Box>
        ) : null}

        <Box display={'flex'} flexDirection={'column'} {...containerParams}>
          {contacts.map(contact => (
            <ContactListItem
              contact={contact}
              key={`e:${contact.entity_user_xref_uuid}`}
              onCreateChat={onCreateChatClicked}
              width={itemWidth}
            />
          ))}
        </Box>
        {hasMore ? (
          <Box
            margin={2}
            paddingX={1}
            paddingTop={2}
            borderTop={`1px dashed ${grey[400]}`}
            color={grey[500]}
          >
            <Typography fontSize={"0.9rem"}>
              Not all contacts are show here as you have reached the size limit.
              To see more contacts, please log in to the Qwil app.
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default ContactList;
