import { createTheme } from "@mui/material";
import { createContext } from 'react';

const theme = createTheme({
  palette: {
    // match primary colour in main Qwil app
    primary: {
      main: "#4A5E7D",
      dark: "#394D67",
      light: "#526988",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    }
  }
});


// Also used ad-hoc context-based theming for user customisations
export const SDK_THEME_DEFAULTS = {
  bubbleBgColour: '#1a237e',
  bubbleTextColour: '#ffffff',
  bubbleLinkColour: '#b7b7f4',
  bubbleBgColour2: '#eeeeee',
  bubbleTextColour2: '#000000de',
  bubbleLinkColour2: '#0000ee',
}

// export type SdkTheme = typeof SDK_THEME_DEFAULTS;
export const ThemeContext = createContext(SDK_THEME_DEFAULTS);

export default theme;
