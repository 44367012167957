import React, { useLayoutEffect } from "react";
import { createBrowserRouter, Outlet, useLocation } from "react-router-dom";
import TopNavLayout from "./components/TopNavLayout";
import { emitPathChange } from "./controller";
import ChatListScreen from "./screens/ChatListScreen";
import ChatScreen from "./screens/ChatScreen";
import ContactListScreen from "./screens/ContactListScreen";
import Error404 from "./screens/Error404";
import ErrorAuth from "./screens/ErrorAuth";
import Landing from "./screens/Landing";

const DEFAULT_PATH = '/chats';

function RouteWrapper() {
  const location = useLocation();
  useLayoutEffect(() => {
    emitPathChange(location.pathname);
  }, [location]);

  return <Outlet />
}

const router = createBrowserRouter([
  {
    element: <RouteWrapper />,
    children: [
      {
        path: "/",
        element: <Landing/>,
      },
      {
        path: "/no-auth",
        element: <ErrorAuth/>,
      },
      {
        element: <TopNavLayout />,
        children: [
          {
            path: "/contacts",
            element: <ContactListScreen/>,
          },
          {
            path: "/chats",
            element: <ChatListScreen/>,
          },
        ]
      },
      {
        path: "/chats/:chatUuid",
        element: <ChatScreen/>,
      },
      {
        path: "*",
        element: <Error404/>,
      },
    ]
  }
]);


export function getRouterObj() {
  return router;
}

// Convenience functions to trigger navigation from outside a component.
// If in component, prefer useNavigation or Link instead.

export async function navTo(path?: string) {
  return router.navigate(path || DEFAULT_PATH);
}

export async function navToAuthErrPage() {
  return router.navigate('/no-auth');
}
